import { LOGIN_TYPES } from "./types"
import features from "@src/config/features"
import jwt from "jsonwebtoken"
import modules from "@src/config/modules"
import useJwt from "@src/auth/jwt/useJwt"

// ** UseJWT import to get config

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = localStorage.getItem("userData")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const initialState = {
  email: null,
  error: null,
  userData: initialUser(),
  loading: false,
  azureLoading: false
}

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_TYPES.REQUEST:
      return {
        ...state,
        email: action.payload.email,
        loading: true
      }

    case LOGIN_TYPES.SUCCESS:
      state.userData = action.payload
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem("userData", JSON.stringify(action.payload))
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      )
      localStorage.setItem(
        config.companyName,
        action.payload?.company_name ||
          // "systems"
          document.location.pathname.split("/")[1]
      )

      const companyLogo =
        jwt.decode(action.payload.accessToken)?.company_logo || ""
      localStorage.setItem(config.companyLogo, companyLogo)

      localStorage.setItem(
        config.accessControl,
        JSON.stringify(action.payload.roleAccess)
      )
      localStorage.setItem(
        config.currentPlan,
        action.payload.roleAccess?.plan || "free"
      )

      state.loading = false
      state.error = null
      return {
        ...state
      }

    case LOGIN_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case LOGIN_TYPES.LOGOUT:
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      localStorage.removeItem("userData")
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.clear()
      state.loading = false
      state.error = null
      state.email = null
      return {
        ...state
      }

    case LOGIN_TYPES.AZURE_SSO_REQUEST:
      return {
        ...state,
        azureLoading: true
      }

    case LOGIN_TYPES.AZURE_SSO_SUCCESS:
      state.userData = action.payload
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem("userData", JSON.stringify(action.payload))
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      )
      localStorage.setItem(
        config.companyName,
        action.payload?.company_name ||
          // "systems"
          document.location.pathname.split("/")[1]
      )

      companyLogo = jwt.decode(action.payload.accessToken)?.company_logo || ""
      localStorage.setItem(config.companyLogo, companyLogo)

      localStorage.setItem(
        config.accessControl,
        JSON.stringify(action.payload.roleAccess)
      )
      localStorage.setItem(
        config.currentPlan,
        action.payload.roleAccess?.plan || "free"
      )

      state.azureLoading = false
      state.error = null
      return {
        ...state
      }

    case LOGIN_TYPES.AZURE_SSO_FAILURE:
      return {
        ...state,
        azureLoading: false,
        error: action.payload
      }

    case LOGIN_TYPES.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }

    default:
      return state
  }
}
