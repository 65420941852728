import { ONBOARDING_TYPES } from "./types"
import useJwt from "@src/auth/jwt/useJwt"

const config = useJwt.jwtConfig

const initialState = {
  onBoardings: {},
  error: null,
  deleted: false,
  updated: false,
  added: false,
  createdUpdatedLoading: false,
  interviewers: [],
  evaluationFormLoading: false,
  formAddedOrUpdated: false,
  assignedInterviews: {},
  assignedInterview: {},
  offerLetterUrl: "",
  uploadFileLoading: false,
  acceptRejectLoading: false,
  AcceptedRejected: false,
  sendOfferLetterLoading: false,
  sendOfferLetterSuccess: false,
  offerletterResponseLoading: false,
  offerletterResponseSuccess: false,
  credsError: ""
}

const onBoardingReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ONBOARDING_TYPES.GET_ALL_ONBOARDINGS_REQUEST:
    //   return {
    //     ...state,
    //     updated: false,
    //     deleted: false,
    //     added: false
    //   }
    // case ONBOARDING_TYPES.GET_ALL_ONBOARDINGS_SUCCESS:
    //   return {
    //     ...state,
    //     onBoardings: action.payload
    //   }
    // case ONBOARDING_TYPES.GET_ALL_ONBOARDINGS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload
    //   }
    // case ONBOARDING_TYPES.CREATE_ONBOARDING_REQUEST:
    //   return {
    //     ...state,
    //     added: false,
    //     createdUpdatedLoading: true
    //   }
    // case ONBOARDING_TYPES.CREATE_ONBOARDING_SUCCESS:
    //   if (localStorage.getItem(config.currentPlan) !== "advance") {
    //     localStorage.setItem(
    //       "currCreateOnBoardingSetLimit",
    //       action.payload?.current
    //         ? action.payload?.current
    //         : localStorage.getItem("currCreateOnBoardingSetLimit") > 0
    //         ? localStorage.getItem("currCreateOnBoardingSetLimit") - 1
    //         : 0
    //     )
    //   }

    //   return {
    //     ...state,
    //     added: true,
    //     createdUpdatedLoading: false
    //   }
    // case ONBOARDING_TYPES.CREATE_ONBOARDING_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     createdUpdatedLoading: false
    //   }
    // case ONBOARDING_TYPES.UPDATE_ONBOARDING_REQUEST:
    //   return {
    //     ...state,
    //     createdUpdatedLoading: true,
    //     updated: false
    //   }
    // case ONBOARDING_TYPES.UPDATE_ONBOARDING_SUCCESS:
    //   return {
    //     ...state,
    //     updated: true,
    //     createdUpdatedLoading: false
    //   }
    // case ONBOARDING_TYPES.UPDATE_ONBOARDING_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     createdUpdatedLoading: false
    //   }

    // case ONBOARDING_TYPES.DELETE_ONBOARDING_REQUEST:
    //   return {
    //     ...state,
    //     deleted: false
    //   }

    // case ONBOARDING_TYPES.DELETE_ONBOARDING_SUCCESS:
    //   if (localStorage.getItem(config.currentPlan) !== "advance") {
    //     localStorage.setItem(
    //       "currCreateOnBoardingSetLimit",
    //       action.payload?.current
    //         ? action.payload?.current
    //         : localStorage.getItem("currCreateOnBoardingSetLimit") <
    //           localStorage.getItem("createOnBoardingSetLimit")
    //         ? localStorage.getItem("currCreateOnBoardingSetLimit") + 1
    //         : 0
    //     )
    //   }

    //   return {
    //     ...state,
    //     deleted: true
    //   }

    // case ONBOARDING_TYPES.DELETE_ONBOARDING_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     deleted: false
    //   }

    case ONBOARDING_TYPES.SCHEDULE_ONBOARDING_REQUEST:
      return {
        ...state,
        added: false,
        createdUpdatedLoading: true,
        credsError: ""
      }

    case ONBOARDING_TYPES.SCHEDULE_ONBOARDING_SUCCESS:
      return {
        ...state,
        added: true,
        createdUpdatedLoading: false,
        credsError: ""
      }

    case ONBOARDING_TYPES.SCHEDULE_ONBOARDING_FAIL:
      return {
        ...state,
        added: false,
        error: action.payload,
        createdUpdatedLoading: false,
        credsError: action.payload
      }

    case ONBOARDING_TYPES.RESET_ONBOARDING:
      return {
        ...state,
        onBoardings: {},
        error: null,
        deleted: false,
        updated: false,
        added: false,
        formAddedOrUpdated: false,
        createdUpdatedLoading: false,
        credsError: ""
      }

    case ONBOARDING_TYPES.SUBMIT_EVALUATION_FORM_REQUEST:
      return {
        ...state,
        evaluationFormLoading: true
      }

    case ONBOARDING_TYPES.SUBMIT_EVALUATION_FORM_SUCCESS:
      return {
        ...state,
        evaluationFormLoading: false,
        formAddedOrUpdated: true
      }

    case ONBOARDING_TYPES.SUBMIT_EVALUATION_FORM_FAIL:
      return {
        ...state,
        evaluationFormLoading: false,
        formAddedOrUpdated: false,
        error: action.payload
      }

    case ONBOARDING_TYPES.GET_INTERVIEWER_REQUEST:
      return {
        ...state,
        interviewers: []
        // evaluationFormLoading: true,
      }

    case ONBOARDING_TYPES.GET_INTERVIEWER_SUCCESS:
      return {
        ...state,
        // evaluationFormLoading: false,
        interviewers: action.payload
      }

    case ONBOARDING_TYPES.GET_INTERVIEWER_FAIL:
      return {
        ...state,
        interviewers: [],
        // evaluationFormLoading: false,
        error: action.payload
      }

    case ONBOARDING_TYPES.GET_ALL_ASSIGNED_INTERVIEW_REQUEST:
      return {
        ...state,
        assignedInterviews: {}
      }

    case ONBOARDING_TYPES.GET_ALL_ASSIGNED_INTERVIEW_SUCCESS:
      return {
        ...state,
        assignedInterviews: action.payload
      }

    case ONBOARDING_TYPES.GET_ALL_ASSIGNED_INTERVIEW_FAIL:
      return {
        ...state,
        assignedInterviews: {},
        error: action.payload
      }

    case ONBOARDING_TYPES.GET_SPECIFIC_ASSIGNED_INTERVIEW_REQUEST:
      return {
        ...state,
        assignedInterview: {}
      }

    case ONBOARDING_TYPES.GET_SPECIFIC_ASSIGNED_INTERVIEW_SUCCESS:
      return {
        ...state,
        assignedInterview: action.payload
      }

    case ONBOARDING_TYPES.GET_SPECIFIC_ASSIGNED_INTERVIEW_FAIL:
      return {
        ...state,
        assignedInterview: {},
        error: action.payload
      }

    case ONBOARDING_TYPES.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        offerLetterUrl: "",
        uploadFileLoading: true
      }

    case ONBOARDING_TYPES.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        offerLetterUrl: action.payload,
        uploadFileLoading: false
      }

    case ONBOARDING_TYPES.UPLOAD_FILE_FAIL:
      return {
        ...state,
        offerLetterUrl: "",
        error: action.payload,
        uploadFileLoading: false
      }

    case ONBOARDING_TYPES.REJECT_APPLICANT_REQUEST:
      return {
        ...state,
        acceptRejectLoading: true,
        AcceptedRejected: false
      }

    case ONBOARDING_TYPES.REJECT_APPLICANT_SUCCESS:
      return {
        ...state,
        acceptRejectLoading: false,
        AcceptedRejected: true
      }

    case ONBOARDING_TYPES.REJECT_APPLICANT_FAIL:
      return {
        ...state,
        acceptRejectLoading: false,
        AcceptedRejected: false,
        error: action.payload
      }

    case ONBOARDING_TYPES.RESET_ACCEPT_REJECT_STATE:
      return {
        ...state,
        acceptRejectLoading: false,
        AcceptedRejected: false,
        sendOfferLetterSuccess: false
      }

    case ONBOARDING_TYPES.SEND_OFFER_LETTER_REQUEST:
      return {
        ...state,
        sendOfferLetterLoading: true,
        sendOfferLetterSuccess: false
      }

    case ONBOARDING_TYPES.SEND_OFFER_LETTER_SUCCESS:
      return {
        ...state,
        sendOfferLetterLoading: false,
        sendOfferLetterSuccess: true,
        offerLetterUrl: ""
      }

    case ONBOARDING_TYPES.SEND_OFFER_LETTER_FAIL:
      return {
        ...state,
        sendOfferLetterLoading: false,
        sendOfferLetterSuccess: false,
        error: action.payload
      }

    case ONBOARDING_TYPES.CANCEL_INTERVIEW_REQUEST:
      return {
        ...state,
        acceptRejectLoading: true,
        AcceptedRejected: false
      }

    case ONBOARDING_TYPES.CANCEL_INTERVIEW_SUCCESS:
      return {
        ...state,
        acceptRejectLoading: false,
        AcceptedRejected: true
      }

    case ONBOARDING_TYPES.CANCEL_INTERVIEW_FAIL:
      return {
        ...state,
        acceptRejectLoading: false,
        AcceptedRejected: false,
        error: action.payload
      }

    case ONBOARDING_TYPES.OFFER_LETTER_RESPONSE_REQUEST:
      return {
        ...state,
        offerletterResponseLoading: true,
        offerletterResponseSuccess: false
      }

    case ONBOARDING_TYPES.OFFER_LETTER_RESPONSE_SUCCESS:
      return {
        ...state,
        offerletterResponseLoading: false,
        offerletterResponseSuccess: true
      }

    case ONBOARDING_TYPES.OFFER_LETTER_RESPONSE_FAIL:
      return {
        ...state,
        offerletterResponseLoading: false,
        offerletterResponseSuccess: false,
        error: action.payload
      }

    default:
      return state
  }
}

export default onBoardingReducer
