import { createActionTypes } from "@src/utility"

export const AUTH_TYPES = createActionTypes("AUTH", [
  "VERIFY_EMAIL_REQUEST",
  "VERIFY_EMAIL_SUCCESS",
  "VERIFY_EMAIL_FAILURE",
  "CLEAR_VERIFY_EMAIL", //
  "RESEND_OTP_REQUEST",
  "RESEND_OTP_SUCCESS",
  "RESEND_OTP_FAILURE",
  "CLEAR_RESEND_OTP", //
  "FORGOT_PASSWORD_REQUEST",
  "FORGOT_PASSWORD_SUCCESS",
  "FORGOT_PASSWORD_FAILURE",
  "CLEAR_FORGOT_PASSWORD",
  "RESET_PASSWORD_TOKEN_VERIFY_REQUEST",
  "RESET_PASSWORD_TOKEN_VERIFY_SUCCESS",
  "RESET_PASSWORD_TOKEN_VERIFY_FAILURE",
  "CLEAR_RESET_PASSWORD_TOKEN_VERIFY", //
  "RESET_PASSWORD_REQUEST",
  "RESET_PASSWORD_SUCCESS",
  "RESET_PASSWORD_FAILURE",
  "CLEAR_RESET_PASSWORD" //
])
