import * as CompanyProfileActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  companyProfile,
  createCompanyLocation,
  deleteCompanyLocation,
  deleteLinkedInIntegration,
  deleteTeamsIntegration,
  deleteZoomIntegration,
  getAPILimits,
  getCompanyLocations,
  getLinkedInIntegration,
  getTeamsIntegration,
  getZoomIntegration,
  integrateLinkedIn,
  integrateTeams,
  integrateZoom,
  updateCompanyLocation,
  updateLinkedInIntegration,
  updateProfile,
  updateTeamsIntegration,
  updateZoomIntegration,
  uploadCompanyLogo
} from "@src/services/apis"

import { COMPANY_PROFILE_TYPES } from "./types"

function* getCompanyProfileRequest(action) {
  try {
    const response = yield call(companyProfile, action.payload)
    yield put(CompanyProfileActions.getCompanyProfileSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.getCompanyProfileFailure(err))
  }
}

function* postCompanyProfileRequest(action) {
  try {
    yield put(CompanyProfileActions.postCompanyProfileSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.postCompanyProfileFailure(err))
  }
}

function* updateCompanyProfileRequest(action) {
  try {
    const response = yield call(updateProfile, action.payload)

    yield put(CompanyProfileActions.updateCompanyProfileSuccess(action.payload))
  } catch (err) {
    yield put(CompanyProfileActions.updateCompanyProfileFailure(err))
  }
}

function* getCompanyLocationsRequest(action) {
  try {
    const response = yield call(getCompanyLocations, action.payload)
    yield put(CompanyProfileActions.getCompanyLocationsSuccess(response))
  } catch (err) {
    yield put(CompanyProfileActions.getCompanyLocationsFailure(err))
  }
}

function* postCompanyLocationsRequest(action) {
  try {
    const response = yield call(createCompanyLocation, action.payload)
    yield put(CompanyProfileActions.postCompanyLocationsSuccess(response))
  } catch (err) {
    yield put(CompanyProfileActions.postCompanyLocationsFailure(err))
  }
}

function* updateCompanyLocationsRequest(action) {
  try {
    const response = yield call(updateCompanyLocation, action.payload)
    yield put(CompanyProfileActions.updateCompanyLocationsSuccess(response))
  } catch (err) {
    yield put(CompanyProfileActions.updateCompanyLocationsFailure(err))
  }
}

function* deleteCompanyLocationsRequest(action) {
  try {
    const response = yield call(deleteCompanyLocation, action.payload)
    yield put(CompanyProfileActions.deleteCompanyLocationsSuccess(response))
  } catch (err) {
    yield put(CompanyProfileActions.deleteCompanyLocationsFailure(err))
  }
}

function* uploadCompanyLogoRequest(action) {
  try {
    const response = yield call(uploadCompanyLogo, action.payload)
    yield put(CompanyProfileActions.uploadCompanyLogoSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.uploadCompanyLogoFailure(err))
  }
}

function* getAPILimitsRequest(action) {
  try {
    const response = yield call(getAPILimits, action.payload)
    yield put(CompanyProfileActions.apiLimitSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.apiLimitFailure(err))
  }
}

function* zoomIntegrationRequest(action) {
  try {
    const response = yield call(integrateZoom, action.payload)
    yield put(CompanyProfileActions.zoomIntegrationSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.zoomIntegrationFailed(err))
  }
}

function* getZoomIntegrationRequest(action) {
  try {
    const response = yield call(getZoomIntegration, action.payload)
    yield put(CompanyProfileActions.getZoomIntegrationSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.getZoomIntegrationFailed(err))
  }
}

function* deleteZoomIntegrationRequest(action) {
  try {
    const response = yield call(deleteZoomIntegration, action.payload)
    yield put(CompanyProfileActions.deleteZoomIntegrationSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.deleteZoomIntegrationFailed(err))
  }
}

function* updateZoomIntegrationRequest(action) {
  try {
    const response = yield call(updateZoomIntegration, action.payload)
    yield put(CompanyProfileActions.updateZoomIntegrationSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.updateZoomIntegrationFailed(err))
  }
}

function* integrateLinkedInRequest(action) {
  try {
    const response = yield call(integrateLinkedIn, action.payload)
    yield put(CompanyProfileActions.linkedInIntegrationSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.linkedInIntegrationFailed(err))
  }
}

function* getLinkedInIntegrationRequest(action) {
  try {
    const response = yield call(getLinkedInIntegration, action.payload)
    yield put(
      CompanyProfileActions.getLinkedInIntegrationSuccess(response.data)
    )
  } catch (err) {
    yield put(CompanyProfileActions.getLinkedInIntegrationFailed(err))
  }
}

// update
function* updateLinkedInIntegrationRequest(action) {
  try {
    const response = yield call(updateLinkedInIntegration, action.payload)
    yield put(
      CompanyProfileActions.updateLinkedInIntegrationSuccess(response.data)
    )
  } catch (err) {
    yield put(CompanyProfileActions.updateLinkedInIntegrationFailed(err))
  }
}

// delete
function* deleteLinkedInIntegrationRequest(action) {
  try {
    const response = yield call(deleteLinkedInIntegration, action.payload)
    yield put(
      CompanyProfileActions.deleteLinkedInIntegrationSuccess(response.data)
    )
  } catch (err) {
    yield put(CompanyProfileActions.deleteLinkedInIntegrationFailed(err))
  }
}

function* teamsIntegrationRequest(action) {
  try {
    const response = yield call(integrateTeams, action.payload)
    yield put(CompanyProfileActions.teamsIntegrationSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.teamsIntegrationFailed(err))
  }
}

function* getTeamsIntegrationRequest(action) {
  try {
    const response = yield call(getTeamsIntegration, action.payload)
    yield put(CompanyProfileActions.getTeamsIntegrationSuccess(response.data))
  } catch (err) {
    yield put(CompanyProfileActions.getTeamsIntegrationFailed(err))
  }
}

function* deleteTeamsIntegrationRequest(action) {
  try {
    const response = yield call(deleteTeamsIntegration, action.payload)
    yield put(
      CompanyProfileActions.deleteTeamsIntegrationSuccess(response.data)
    )
  } catch (err) {
    yield put(CompanyProfileActions.deleteTeamsIntegrationFailed(err))
  }
}

function* updateTeamsIntegrationRequest(action) {
  try {
    const response = yield call(updateTeamsIntegration, action.payload)
    yield put(
      CompanyProfileActions.updateTeamsIntegrationSuccess(response.data)
    )
  } catch (err) {
    yield put(CompanyProfileActions.updateTeamsIntegrationFailed(err))
  }
}

function* CompanyProfileSaga() {
  yield all([
    takeEvery(
      COMPANY_PROFILE_TYPES.GET_COMPANY_PROFILE_REQUEST,
      getCompanyProfileRequest
    ),

    takeEvery(
      COMPANY_PROFILE_TYPES.POST_COMPANY_PROFILE_REQUEST,
      postCompanyProfileRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.UPDATE_COMPANY_PROFILE_REQUEST,
      updateCompanyProfileRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.GET_COMPANY_LOCATIONS_REQUEST,
      getCompanyLocationsRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.POST_COMPANY_LOCATIONS_REQUEST,
      postCompanyLocationsRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.UPDATE_COMPANY_LOCATIONS_REQUEST,
      updateCompanyLocationsRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.DELETE_COMPANY_LOCATIONS_REQUEST,
      deleteCompanyLocationsRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.UPLOAD_COMPANY_LOGO_REQUEST,
      uploadCompanyLogoRequest
    ),
    takeEvery(COMPANY_PROFILE_TYPES.API_LIMIT_REQUEST, getAPILimitsRequest),
    takeEvery(
      COMPANY_PROFILE_TYPES.ZOOM_INTEGRATION_REQUEST,
      zoomIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.GET_ZOOM_INTEGRATION_REQUEST,
      getZoomIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.DELETE_ZOOM_INTEGRATION_REQUEST,
      deleteZoomIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.UPDATE_ZOOM_INTEGRATION_REQUEST,
      updateZoomIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.LINKEDIN_INTEGRATION_REQUEST,
      integrateLinkedInRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.GET_LINKEDIN_INTEGRATION_REQUEST,
      getLinkedInIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.UPDATE_LINKEDIN_INTEGRATION_REQUEST,
      updateLinkedInIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.DELETE_LINKEDIN_INTEGRATION_REQUEST,
      deleteLinkedInIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.TEAMS_INTEGRATION_REQUEST,
      teamsIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.GET_TEAMS_INTEGRATION_REQUEST,
      getTeamsIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.DELETE_TEAMS_INTEGRATION_REQUEST,
      deleteTeamsIntegrationRequest
    ),
    takeEvery(
      COMPANY_PROFILE_TYPES.UPDATE_TEAMS_INTEGRATION_REQUEST,
      updateTeamsIntegrationRequest
    )
  ])
}

export default CompanyProfileSaga
