import { SEEKER_PROFILE_TYPES } from "./types"

const initialState = {
  loading: false,
  data: {},
  experiences: [],
  educations: [],
  skills: [],
  languages: [],
  visit_logs: [],
  questions: [],
  error: null,
  success: null,
  deleted: false,
  successforpatch: null,
  successforpost: null,
  response: null,
  responseforpatch: null,
  responseforpost: null,
  all_skills: [],
  loading_patch_profile: false,
  response_patch_profile: null,
  patched_profile: false,
  assessment_attempted: false,
  assessment_remaining_tries: 0,
  profile_assessment_result: null
}

export default function seekerProfileReducer(state = initialState, action) {
  switch (action.type) {
    case SEEKER_PROFILE_TYPES.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case SEEKER_PROFILE_TYPES.GET_PROFILE_SUCCESS:
      const {
        personal_info,
        work_and_experience,
        seeker_questions,
        _id,
        user_account_id,
        profile_assessment_given,
        profile_assessment_tries_count,
        profile_assessment_result
      } = action.payload

      const {
        education_details,
        experience_details,
        profile_visit_logs,
        seeker_skills,
        seeker_languages
      } = work_and_experience
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        deleted: false,
        response: action.payload,
        data: {
          personal_info,
          _id,
          user_account_id
        },
        educations: education_details,
        experiences: experience_details,
        skills: seeker_skills,
        languages: seeker_languages,
        questions: seeker_questions,
        visit_logs: profile_visit_logs,
        assessment_attempted: profile_assessment_given,
        assessment_remaining_tries: profile_assessment_tries_count,
        profile_assessment_result
      }
    case SEEKER_PROFILE_TYPES.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: null,
        educations: null,
        experiences: null,
        skills: null,
        languages: null,
        questions: null,
        visit_logs: null,
        success: null,
        response: null
      }

    case SEEKER_PROFILE_TYPES.PATCH_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpatch: null
      }
    case SEEKER_PROFILE_TYPES.PATCH_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpatch: true,
        responseforpatch: action.payload
      }
    case SEEKER_PROFILE_TYPES.PATCH_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpatch: null,
        responseforpatch: null
      }

    case SEEKER_PROFILE_TYPES.POST_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpost: null,
        responseforpost: null
      }

    case SEEKER_PROFILE_TYPES.POST_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpost: true,
        responseforpost: action.payload
      }

    case SEEKER_PROFILE_TYPES.POST_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpost: null,
        responseforpost: null
      }

    case SEEKER_PROFILE_TYPES.PATCH_EDUCATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpatch: null
      }

    case SEEKER_PROFILE_TYPES.PATCH_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpatch: true,
        responseforpatch: action.payload
      }

    case SEEKER_PROFILE_TYPES.PATCH_EDUCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpatch: null,
        responseforpatch: null
      }

    case SEEKER_PROFILE_TYPES.POST_EDUCATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpost: null,
        responseforpost: null
      }

    case SEEKER_PROFILE_TYPES.POST_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpost: true,
        responseforpost: action.payload
      }

    case SEEKER_PROFILE_TYPES.POST_EDUCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpost: null,
        responseforpost: null
      }

    // DELETE
    // "DELETE_EDUCATION_REQUEST",
    // "DELETE_EDUCATION_SUCCESS",
    // "DELETE_EDUCATION_FAILURE",

    case SEEKER_PROFILE_TYPES.DELETE_EDUCATION_REQUEST:
      return {
        ...state,
        deleted: false,
        error: null
      }

    case SEEKER_PROFILE_TYPES.DELETE_EDUCATION_SUCCESS:
      return {
        ...state,
        deleted: true
      }

    case SEEKER_PROFILE_TYPES.DELETE_EDUCATION_FAILURE:
      return {
        ...state,
        deleted: false
      }

    case SEEKER_PROFILE_TYPES.DELETE_EXPERIENCE_REQUEST:
      return {
        ...state,
        deleted: false,
        error: null
      }

    case SEEKER_PROFILE_TYPES.DELETE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        deleted: true
      }

    case SEEKER_PROFILE_TYPES.DELETE_EXPERIENCE_FAILURE:
      return {
        ...state,
        deleted: false
      }

    case SEEKER_PROFILE_TYPES.DELETE_PROFILE_LANGUAGES_REQUEST:
      return {
        ...state,
        deleted: false,
        error: null
      }

    case SEEKER_PROFILE_TYPES.DELETE_PROFILE_LANGUAGES_SUCCESS:
      return {
        ...state,
        deleted: true
      }

    case SEEKER_PROFILE_TYPES.DELETE_PROFILE_LANGUAGES_FAILURE:
      return {
        ...state,
        deleted: false
      }

    case SEEKER_PROFILE_TYPES.DELETE_PROFILE_SKILLS_REQUEST:
      return {
        ...state,
        deleted: false,
        error: null
      }

    case SEEKER_PROFILE_TYPES.CLEAR_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        response: null,
        deleted: false,
        successforpatch: null,
        responseforpatch: null,
        successforpost: null,
        responseforpost: null
      }

    case SEEKER_PROFILE_TYPES.GET_SKILLS_SUCCESS:
      return {
        ...state,
        error: null,
        all_skills: action.payload.docs.map((skill) => {
          return {
            value: skill._id,
            label: skill.skill_set_name
          }
        })
      }

    case SEEKER_PROFILE_TYPES.GET_SKILLS_FAILURE:
      return {
        ...state,
        error: action.payload
      }

    case SEEKER_PROFILE_TYPES.PATCH_SKILLS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpatch: null
      }

    case SEEKER_PROFILE_TYPES.PATCH_SKILLS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpatch: true,
        responseforpatch: action.payload
      }

    case SEEKER_PROFILE_TYPES.PATCH_SKILLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpatch: null,
        responseforpatch: null
      }

    case SEEKER_PROFILE_TYPES.PATCH_LANGUAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpatch: null
      }

    case SEEKER_PROFILE_TYPES.PATCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpatch: true,
        responseforpatch: action.payload
      }

    case SEEKER_PROFILE_TYPES.PATCH_LANGUAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpatch: null,
        responseforpatch: null
      }

    // post skills
    case SEEKER_PROFILE_TYPES.POST_SKILLS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpost: null,
        responseforpost: null
      }

    case SEEKER_PROFILE_TYPES.POST_SKILLS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpost: true,
        responseforpost: action.payload
      }

    case SEEKER_PROFILE_TYPES.POST_SKILLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpost: null,
        responseforpost: null
      }

    case SEEKER_PROFILE_TYPES.POST_LANGUAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        successforpost: null,
        responseforpost: null
      }

    case SEEKER_PROFILE_TYPES.POST_LANGUAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        successforpost: true,
        responseforpost: action.payload
      }

    case SEEKER_PROFILE_TYPES.POST_LANGUAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successforpost: null,
        responseforpost: null
      }

    // delete skills
    case SEEKER_PROFILE_TYPES.DELETE_SKILLS_REQUEST:
      return {
        ...state,
        loading: true,
        deleted: false,
        error: null
      }

    case SEEKER_PROFILE_TYPES.DELETE_SKILLS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        error: null
      }

    case SEEKER_PROFILE_TYPES.DELETE_SKILLS_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.payload
      }

    case SEEKER_PROFILE_TYPES.PATCH_PROFILE_REQUEST:
      return {
        ...state,
        loading_patch_profile: true,
        error: null,
        patched_profile: null
      }

    case SEEKER_PROFILE_TYPES.PATCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading_patch_profile: false,
        error: null,
        patched_profile: true,
        response_patch_profile: action.payload
      }

    case SEEKER_PROFILE_TYPES.PATCH_PROFILE_FAILURE:
      return {
        ...state,
        loading_patch_profile: false,
        error: action.payload,
        patched_profile: null,
        response_patch_profile: null
      }

    default:
      return state
  }
}
