export default {
  // company module
  getSpecificCompany: "getSpecificCompany",
  updateSpecificCompany: "updateSpecificCompany",
  uploadCompanyLogo: "uploadCompanyLogo",
  getOAuthCredentials: "getOAuthCredentials",
  createOAuthCredentials: "createOAuthCredentials",
  changeSecretKeyStatus: "changeSecretKeyStatus",
  getAllCompanyLocation: "getAllCompanyLocation",
  createCompanyLocation: "createCompanyLocation",
  getSpecificCompanyLocation: "getSpecificCompanyLocation",
  updateSpecificCompanyLocation: "updateSpecificCompanyLocation",
  deleteSpecificCompanyLocation: "deleteSpecificCompanyLocation",
  // user module
  getAllUsers: "getAllUsers",
  createUser: "createUser",
  getUser: "getUser",
  updateUser: "updateUser",
  deleteUser: "deleteUser",
  uploadProfileImage: "uploadProfileImage",
  getAllEmployees: "getAllEmployees",
  // seeker module
  readAllSeekerProfiles: "readAllSeekerProfiles",
  createSeekerProfile: "createSeekerProfile",
  readSeekerProfile: "readSeekerProfile",
  updateSeekerProfile: "updateSeekerProfile",
  deleteSeekerProfile: "deleteSeekerProfile",
  getAllSeekerSkills: "getAllSeekerSkills",
  createSeekerSkill: "createSeekerSkill",
  updateSpecificSeekerSkill: "updateSpecificSeekerSkill",
  deleteSpecificSeekerSkill: "deleteSpecificSeekerSkill",
  readAllSeekerEducationDetails: "readAllSeekerEducationDetails",
  createSeekerEducationDetails: "createSeekerEducationDetails",
  readSeekerEducationDetails: "readSeekerEducationDetails",
  updateSeekerEducationDetails: "updateSeekerEducationDetails",
  deleteSeekerEducationDetails: "deleteSeekerEducationDetails",
  readAllSeekerExperienceDetails: "readAllSeekerExperienceDetails",
  createSeekerExperienceDetails: "createSeekerExperienceDetails",
  readSeekerExperienceDetails: "readSeekerExperienceDetails",
  updateSeekerExperienceDetails: "updateSeekerExperienceDetails",
  deleteSeekerExperienceDetails: "deleteSeekerExperienceDetails",
  readAllSeekerLanguage: "readAllSeekerLanguage",
  createSeekerLanguage: "createSeekerLanguage",
  readSeekerLanguage: "readSeekerLanguage",
  updateSeekerLanguage: "updateSeekerLanguage",
  deleteSeekerLanguage: "deleteSeekerLanguage",
  readAllSeekerQuestion: "readAllSeekerQuestion",
  createSeekerQuestion: "createSeekerQuestion",
  readSeekerQuestion: "readSeekerQuestion",
  updateSeekerQuestion: "updateSeekerQuestion",
  deleteSeekerQuestion: "deleteSeekerQuestion",
  createAllInOneProfile: "createAllInOneProfile",
  uploadCV: "uploadCV",
  categorizeCVWithAI: "categorizeCVWithAI",
  readAllSkillSet: "readAllSkillSet",
  createSkillSet: "createSkillSet",
  readSkillSet: "readSkillSet",
  updateSkillSet: "updateSkillSet",
  deleteSkillSet: "deleteSkillSet",
  // job module
  getAllJobPosts: "getAllJobPosts",
  createJobPost: "createJobPost",
  getSpecificJobPost: "getSpecificJobPost",
  updateSpecificJobPost: "updateSpecificJobPost",
  deleteSpecificJobPost: "deleteSpecificJobPost",
  changeJobStatus: "changeJobStatus",
  getAllJobCategory: "getAllJobCategory",
  createJobCategory: "createJobCategory",
  getSpecificJobCategory: "getSpecificJobCategory",
  updateSpecificJobCategory: "updateSpecificJobCategory",
  deleteSpecificJobCategory: "deleteSpecificJobCategory",
  getAllApplicants: "getAllApplicants",
  applyOnJob: "applyOnJob",
  getSpecificApplicant: "getSpecificApplicant",
  updateSpecificApplicant: "updateSpecificApplicant",
  deleteSpecificApplicant: "deleteSpecificApplicant",
  changeApplicantStatus: "changeApplicantStatus",
  // New Features
  getSpecificJobPostApplicants: "getSpecificJobPostApplicants",
  getAllAppliedApplicants: "getAllAppliedApplicants",
  getSpecificApplicantJobs: "getSpecificApplicantJobs",
  scheduleApplicantInterview: "scheduleApplicantInterview",
  getApplicantsCount: "getApplicantsCount",
  generateJobDescViaAi: "generateJobDescViaAi",
  generateOnlineSkillTest: "generateOnlineSkillTest",
  // access module
  getListOfRoleGroups: "getListOfRoleGroups",
  getAllRoleGroups: "getAllRoleGroups",
  getSpecificRoleGroup: "getSpecificRoleGroup",
  getSpecificModule: "getSpecificModule",
  updateSpecificModule: "updateSpecificModule",
  updateSpecificFeature: "updateSpecificFeature",
  // forms module
  getAllEvaluationForms: "getAllEvaluationForms",
  createEvaluationForm: "createEvaluationForm",
  getEvaluationForm: "getEvaluationForm",
  updateEvaluationForm: "updateEvaluationForm",
  deleteEvaluationForm: "deleteEvaluationForm",
  getAllAssessmentQuestions: "getAllAssessmentQuestions",
  createAssessmentQuestion: "createAssessmentQuestion",
  getAssessmentQuestion: "getAssessmentQuestion",
  updateAssessmentQuestion: "updateAssessmentQuestion",
  deleteAssessmentQuestion: "deleteAssessmentQuestion",
  generateAssessmentQuestionsViaAi: "generateAssessmentQuestionsViaAi",
  // integration module
  getAllMeeting: "getAllMeeting",
  getMeeting: "getMeeting",
  deleteMeeting: "deleteMeeting",
  getZoomCredentials: "getZoomCredentials",
  addZoomCredentials: "addZoomCredentials",
  updateZoomCredentials: "updateZoomCredentials",
  deleteZoomCredentials: "deleteZoomCredentials",
  createZoomMeeting: "createZoomMeeting",
  createTeamMeeting: "createTeamMeeting",
  getLinkedinCredentials: "getLinkedinCredentials",
  addLinkedinCredentials: "addLinkedinCredentials",
  updateLinkedinCredentials: "updateLinkedinCredentials",
  deleteLinkedinCredentials: "deleteLinkedinCredentials",
  createLinkedinPost: "createLinkedinPost",
  // onboarding module
  getAllScheduledInterviews: "getAllScheduledInterviews",
  getScheduledInterview: "getScheduledInterview",
  fillInterviewEvaluationForm: "fillInterviewEvaluationForm",
  changeInterviewStatus: "changeInterviewStatus",
  uploadOfferLetter: "uploadOfferLetter",
  sendOfferLetter: "sendOfferLetter",
  // dashboard module
  getStatisticsGraph: "getStatisticsGraph",
  getApplicantStatusGraph: "getApplicantStatusGraph",
  // "plansAndPricing"
  getListOfPlans: "getListOfPlans",
  changeCompanyPlanSubscription: "changeCompanyPlanSubscription"
}
