import * as meetingsActions from "./actions"

import {
  AuthError,
  InteractionRequiredAuthError,
  ServerError
} from "@azure/msal-browser"
import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createTeamsMeeting,
  createZoomMeeting,
  deleteMeeting,
  getAllMeetings,
  teamsClientConnect
} from "@src/services/apis"

import { MEETINGS_TYPES } from "./types"
import { toast } from "react-hot-toast"

function* getAllMeetingsRequest(action) {
  try {
    const response = yield call(getAllMeetings, action.payload)
    yield put(meetingsActions.getAllMeetingsSuccess(response.data))
  } catch (err) {
    yield put(meetingsActions.getAllMeetingsFailure(err))
  }
}

function* createZoomMeetingRequest(action) {
  try {
    const response = yield call(createZoomMeeting, action.payload)
    if (response?.response?.status === 409) {
      yield put(
        meetingsActions.createZoomMeetingFailure(
          response?.response?.data?.message
        )
      )
    } else if (response?.status === 200) {
      yield put(meetingsActions.createZoomMeetingSuccess(response.data))
    }
  } catch (err) {
    yield put(meetingsActions.createZoomMeetingFailure(err))
  }
}

// function* updateMeetingRequest(action) {
//   try {
//     const response = yield call(updateMeeting, action.payload)
//     yield put(meetingsActions.updateMeetingSuccess(response.data))
//   } catch (err) {
//     yield put(meetingsActions.updateMeetingFailure(err))
//   }
// }

function* deleteMeetingRequest(action) {
  try {
    const response = yield call(deleteMeeting, action.payload)
    yield put(meetingsActions.deleteMeetingSuccess(action.payload))
    toast.success("Meeting Cancelled Successfully!")
  } catch (err) {
    yield put(meetingsActions.deleteMeetingFailure(err))
  }
}

function* teamsClientConnectRequest(action) {
  try {
    const response = yield call(teamsClientConnect)
    if (
      response instanceof InteractionRequiredAuthError ||
      response instanceof AuthError ||
      response instanceof ServerError
    ) {
      toast.error("Please login to your Microsoft Teams account first!")
      yield put(meetingsActions.teamsClientConnectFailure(response.message))
    } else {
      const body = {
        credentials: {
          access_token: response.accessToken,
          id_token: response.idToken
        },
        startDateTime: action.payload.startTime,
        endDateTime: action.payload.endTime,
        subject: action.payload.topic,
        description: action.payload.agenda,
        type: action.payload.type,
        invitees: action.payload.invitees
      }
      const teamsResponse = yield call(createTeamsMeeting, body)

      //       {
      //     "statusCode": 500,
      //     "statusMessage": "Error",
      //     "message": "Request failed with status code 403"
      // }
      if (teamsResponse?.response?.data?.statusCode === 500) {
        yield put(
          meetingsActions.createTeamsMeetingFailure(
            "Use onmicrosoft.com email to schedule interview"
            // teamsResponse?.response?.data?.message
          )
        )
      } else {
        yield put(meetingsActions.createTeamsMeetingSuccess(teamsResponse.data))
      }
    }
  } catch (err) {
    yield put(meetingsActions.createTeamsMeetingFailure(err))
  }
}

export default function* meetingsSaga() {
  yield all([
    takeEvery(MEETINGS_TYPES.GET_ALL_MEETINGS_REQUEST, getAllMeetingsRequest),
    takeEvery(
      MEETINGS_TYPES.CREATE_ZOOM_MEETING_REQUEST,
      createZoomMeetingRequest
    ),
    // takeEvery(MEETINGS_TYPES.UPDATE_MEETING_REQUEST, updateMeetingRequest),
    takeEvery(MEETINGS_TYPES.DELETE_MEETING_REQUEST, deleteMeetingRequest),
    takeEvery(
      MEETINGS_TYPES.TEAMS_CLIENT_CONNECT_REQUEST,
      teamsClientConnectRequest
    )
  ])
}
