import { SKILL_TYPES } from "./types"

export const getAllSkillsRequest = (data) => ({
  type: SKILL_TYPES.GET_ALL_SKILLS_REQUEST,
  payload: data
})

export const getAllSkillsSuccess = (data) => ({
  type: SKILL_TYPES.GET_ALL_SKILLS_SUCCESS,
  payload: data
})

export const getAllSkillsFailure = (data) => ({
  type: SKILL_TYPES.GET_ALL_SKILLS_FAIL,
  payload: data
})

export const createSkillRequest = (data) => ({
  type: SKILL_TYPES.CREATE_SKILL_REQUEST,
  payload: data
})

export const createSkillSuccess = (data) => ({
  type: SKILL_TYPES.CREATE_SKILL_SUCCESS,
  payload: data
})

export const createSkillFailure = (data) => ({
  type: SKILL_TYPES.CREATE_SKILL_FAIL,
  payload: data
})

export const updateSkillRequest = (data) => ({
  type: SKILL_TYPES.UPDATE_SKILL_REQUEST,
  payload: data
})

export const updateSkillSuccess = (data) => ({
  type: SKILL_TYPES.UPDATE_SKILL_SUCCESS,
  payload: data
})

export const updateSkillFailure = (data) => ({
  type: SKILL_TYPES.UPDATE_SKILL_FAIL,
  payload: data
})

export const deleteSkillRequest = (data) => ({
  type: SKILL_TYPES.DELETE_SKILL_REQUEST,
  payload: data
})

export const deleteSkillSuccess = (data) => ({
  type: SKILL_TYPES.DELETE_SKILL_SUCCESS,
  payload: data
})

export const deleteSkillFailure = (data) => ({
  type: SKILL_TYPES.DELETE_SKILL_FAIL,
  payload: data
})
