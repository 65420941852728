import { ASSESSMENT_TYPES } from "./types"

export const getAllAssessmentRequest = (data) => ({
  type: ASSESSMENT_TYPES.GET_ALL_ASSESSMENT_REQUEST,
  payload: data
})

export const getAllAssessmentSuccess = (data) => ({
  type: ASSESSMENT_TYPES.GET_ALL_ASSESSMENT_SUCCESS,
  payload: data
})

export const getAllAssessmentFailure = (data) => ({
  type: ASSESSMENT_TYPES.GET_ALL_ASSESSMENT_FAIL,
  payload: data
})

export const getProfileAssessmentRequest = (data) => ({
  type: ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_REQUEST,
  payload: data
})

export const getProfileAssessmentSuccess = (data) => ({
  type: ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_SUCCESS,
  payload: data
})

export const getProfileAssessmentFailure = (data) => ({
  type: ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_FAILURE,
  payload: data
})

export const postProfileAssessmentRequest = (data) => ({
  type: ASSESSMENT_TYPES.POST_PROFILE_ASSESSMENT_REQUEST,
  payload: data
})

export const postProfileAssessmentSuccess = (data) => ({
  type: ASSESSMENT_TYPES.POST_PROFILE_ASSESSMENT_SUCCESS,
  payload: data
})

export const postProfileAssessmentFailure = (data) => ({
  type: ASSESSMENT_TYPES.POST_PROFILE_ASSESSMENT_FAILURE,
  payload: data
})

export const getProfileAssessmentSubmissionRequest = (data) => ({
  type: ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_SUBMISSION_REQUEST,
  payload: data
})

export const getProfileAssessmentSubmissionSuccess = (data) => ({
  type: ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_SUBMISSION_SUCCESS,
  payload: data
})

export const getProfileAssessmentSubmissionFailure = (data) => ({
  type: ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_SUBMISSION_FAILURE,
  payload: data
})

export const viewInterviewTestRequest = (data) => ({
  type: ASSESSMENT_TYPES.VIEW_INTERVIEW_TEST_REQUEST,
  payload: data
})

export const viewInterviewTestSuccess = (data) => ({
  type: ASSESSMENT_TYPES.VIEW_INTERVIEW_TEST_SUCCESS,
  payload: data
})

export const viewInterviewTestFailure = (data) => ({
  type: ASSESSMENT_TYPES.VIEW_INTERVIEW_TEST_FAILURE,
  payload: data
})

export const isInterviewTestAvailableRequest = (data) => ({
  type: ASSESSMENT_TYPES.IS_INTERVIEW_TEST_AVAILABLE_REQUEST,
  payload: data
})

export const isInterviewTestAvailableSuccess = (data) => ({
  type: ASSESSMENT_TYPES.IS_INTERVIEW_TEST_AVAILABLE_SUCCESS,
  payload: data
})

export const isInterviewTestAvailableFailure = (data) => ({
  type: ASSESSMENT_TYPES.IS_INTERVIEW_TEST_AVAILABLE_FAILURE,
  payload: data
})

export const submitInterviewTestRequest = (data) => ({
  type: ASSESSMENT_TYPES.SUBMIT_INTERVIEW_TEST_REQUEST,
  payload: data
})

export const submitInterviewTestSuccess = (data) => ({
  type: ASSESSMENT_TYPES.SUBMIT_INTERVIEW_TEST_SUCCESS,
  payload: data
})

export const submitInterviewTestFailure = (data) => ({
  type: ASSESSMENT_TYPES.SUBMIT_INTERVIEW_TEST_FAILURE,
  payload: data
})

export const createTechnicalTestRequest = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_TECHNICAL_TEST_REQUEST,
  payload: data
})

export const createTechnicalTestSuccess = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_TECHNICAL_TEST_SUCCESS,
  payload: data
})

export const createTechnicalTestFailure = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_TECHNICAL_TEST_FAILURE,
  payload: data
})

export const createAssessmentRequest = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_ASSESSMENT_REQUEST,
  payload: data
})

export const createAssessmentSuccess = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_ASSESSMENT_SUCCESS,
  payload: data
})

export const createAssessmentFailure = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_ASSESSMENT_FAIL,
  payload: data
})

export const createAssessmentWithAIRequest = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_ASSESSMENT_WITH_AI_REQUEST,
  payload: data
})

export const createAssessmentWithAISuccess = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_ASSESSMENT_WITH_AI_SUCCESS,
  payload: data
})

export const createAssessmentWithAIFailure = (data) => ({
  type: ASSESSMENT_TYPES.CREATE_ASSESSMENT_WITH_AI_FAIL,
  payload: data
})

export const updateAssessmentRequest = (data) => ({
  type: ASSESSMENT_TYPES.UPDATE_ASSESSMENT_REQUEST,
  payload: data
})

export const updateAssessmentSuccess = (data) => ({
  type: ASSESSMENT_TYPES.UPDATE_ASSESSMENT_SUCCESS,
  payload: data
})

export const updateAssessmentFailure = (data) => ({
  type: ASSESSMENT_TYPES.UPDATE_ASSESSMENT_FAIL,
  payload: data
})

export const deleteAssessmentRequest = (data) => ({
  type: ASSESSMENT_TYPES.DELETE_ASSESSMENT_REQUEST,
  payload: data
})

export const deleteAssessmentSuccess = (data) => ({
  type: ASSESSMENT_TYPES.DELETE_ASSESSMENT_SUCCESS,
  payload: data
})

export const deleteAssessmentFailure = (data) => ({
  type: ASSESSMENT_TYPES.DELETE_ASSESSMENT_FAIL,
  payload: data
})

export const resetStatus = () => ({
  type: ASSESSMENT_TYPES.RESET_STATUS
})
