import * as loginActions from "./actions"

import { LogintoAzure, login } from "@src/services/apis"
import { all, call, put, takeEvery } from "redux-saga/effects"

import { LOGIN_TYPES } from "./types"

function* loginRequest(action) {
  try {
    const response = yield call(login, action.payload)

    if (response?.response?.data?.status === 409) {
      yield put(
        loginActions.loginFailure({
          status: 409,
          message: response.response.data.message
        })
      )
    } else if (response?.response?.data?.status === 401) {
      yield put(
        loginActions.loginFailure({
          status: 401,
          message: response.response.data.message
        })
      )
    } else if (response?.status === 200) {
      yield put(loginActions.loginSuccess(response.data.data))
    } else if (response?.status === 204) {
      yield put(
        loginActions.loginFailure({
          status: 204,
          message: "Invalid Credentials"
        })
      )
    } else if (response?.status === 500) {
      yield put(
        loginActions.loginFailure({
          status: 500,
          message: "Internal Server Error"
        })
      )
    } else if (response?.status === 404) {
      yield put(
        loginActions.loginFailure({
          status: 404,
          message: "Not Found"
        })
      )
    } else {
      yield put(
        loginActions.loginFailure({
          message: "Something went wrong"
        })
      )
    }
  } catch (err) {
    yield put(loginActions.loginFailure(err))
  }
}

function* loginWithAzure(action) {
  try {
    const response = yield call(LogintoAzure)
    if (response?.errorCode === "user_cancelled") {
      yield put(loginActions.azureSsoFailure(response))
    } else if (response?.errorCode === "interaction_in_progress") {
      yield put(loginActions.azureSsoFailure(response))
    } else {
      // yield put(loginActions.azureSsoSuccess(response))
      console.log("response", response)

      // const backendResponse = yield call(postAzureLogin, response)

      // if (backendResponse?.accessToken && backendResponse?.azure_data) {
      //   localStorage.setItem("authUser", JSON.stringify(backendResponse))
      //   history("/admin-panel")
      // } else if (backendResponse?.message) {
      // toastr.error(backendResponse.message, "Error")
      // }
    }
  } catch (error) {
    yield put(loginActions.azureSsoFailure(error))
  }
}

function* loginSaga() {
  yield all([
    takeEvery(LOGIN_TYPES.REQUEST, loginRequest),
    takeEvery(LOGIN_TYPES.AZURE_SSO_REQUEST, loginWithAzure)
  ])
}

export default loginSaga
