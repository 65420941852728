import { createActionTypes } from "@src/utility"

export const SKILL_TYPES = createActionTypes("SKILL", [
  "GET_ALL_SKILLS_REQUEST",
  "GET_ALL_SKILLS_SUCCESS",
  "GET_ALL_SKILLS_FAIL",
  "CREATE_SKILL_REQUEST",
  "CREATE_SKILL_SUCCESS",
  "CREATE_SKILL_FAIL",
  "UPDATE_SKILL_REQUEST",
  "UPDATE_SKILL_SUCCESS",
  "UPDATE_SKILL_FAIL",
  "DELETE_SKILL_REQUEST",
  "DELETE_SKILL_SUCCESS",
  "DELETE_SKILL_FAIL"
])
