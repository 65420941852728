import { JOB_CATEGORY_TYPES } from "./types"
import useJwt from "@src/auth/jwt/useJwt"

const config = useJwt.jwtConfig

const initialState = {
  jobCategories: {},
  error: null,
  created: false,
  deleted: false,
  updated: false,
  createdUpdatedLoading: false,
  deletedLoading: false
}

const jobCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_CATEGORY_TYPES.GET_ALL_JOB_CATEGORIES_REQUEST:
      return {
        ...state,
        error: null,
        created: false,
        deleted: false,
        updated: false
      }

    case JOB_CATEGORY_TYPES.GET_ALL_JOB_CATEGORIES_SUCCESS:
      return {
        ...state,
        jobCategories: action.payload,
        createdUpdatedLoading: false,
        deletedLoading: false
      }
    case JOB_CATEGORY_TYPES.GET_ALL_JOB_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case JOB_CATEGORY_TYPES.CREATE_JOB_CATEGORY_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        error: null,
        created: false
      }

    case JOB_CATEGORY_TYPES.CREATE_JOB_CATEGORY_SUCCESS:
      if (localStorage.getItem(config.currentPlan) !== "advance") {
        localStorage.setItem(
          "currCreateJobCategoryLimit",
          action.payload?.current
            ? action.payload?.current
            : localStorage.getItem("currCreateJobCategoryLimit") > 0
            ? localStorage.getItem("currCreateJobCategoryLimit") - 1
            : 0
        )
      }

      return {
        ...state,
        createdUpdatedLoading: false,
        created: true
      }
    case JOB_CATEGORY_TYPES.CREATE_JOB_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false,
        created: false
      }

    case JOB_CATEGORY_TYPES.UPDATE_JOB_CATEGORY_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        error: null,
        updated: false
      }

    case JOB_CATEGORY_TYPES.UPDATE_JOB_CATEGORY_SUCCESS:
      return {
        ...state,
        updated: true,
        createdUpdatedLoading: false
      }

    case JOB_CATEGORY_TYPES.UPDATE_JOB_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false,
        updated: false
      }

    case JOB_CATEGORY_TYPES.DELETE_JOB_CATEGORY_REQUEST:
      return {
        ...state,
        deletedLoading: true,
        error: null,
        deleted: false
      }

    case JOB_CATEGORY_TYPES.DELETE_JOB_CATEGORY_SUCCESS:
      if (localStorage.getItem(config.currentPlan) !== "advance") {
        localStorage.setItem(
          "currCreateJobCategoryLimit",
          action.payload?.current
            ? action.payload?.current
            : localStorage.getItem("currCreateJobCategoryLimit") <
              localStorage.getItem("createJobCategoryLimit")
            ? localStorage.getItem("currCreateJobCategoryLimit") + 1
            : 0
        )
      }

      return {
        ...state,
        deleted: true,
        deletedLoading: false
      }

    case JOB_CATEGORY_TYPES.DELETE_JOB_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        deletedLoading: false,
        deleted: false
      }

    default:
      return state
  }
}

export default jobCategoryReducer
