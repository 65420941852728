import { all, fork } from "redux-saga/effects"

import AuthWatcherSaga from "./../common/auth/saga"
import CompanyProfileSaga from "./../common/companyProfile/saga"
import JobsWatcherSaga from "../common/jobs/saga"
import ProfileSaga from "../common/workExperience/saga"
import ResumeForWatcherSaga from "../common/resumeform/saga"
import assessmentSaga from "../common/assessment/saga"
import dashboardSaga from "../common/dashboard/saga"
import evaluationSaga from "../common/evaluationForm/saga"
import groupManagementSaga from "../common/groupManagement/saga"
import jobCategorySaga from "../common/jobcategory/saga"
import layoutSaga from "../common/layout/saga"
import loginSaga from "../common/login/saga"
import meetingSaga from "../common/meeting/saga"
import navbarSaga from "../common/navbar/saga"
import oAuthKeysSaga from "../common/oAuthKeys/saga"
import onBoardingSaga from "../common/onBoarding/saga"
import planSaga from "../common/plan/saga"
import registerSaga from "../common/register/saga"
import securitySaga from "../common/security/saga"
import seekerProfileSaga from "./../common/seekerProfile/saga"
import skillSaga from "../common/skills/saga"
import usersSaga from "../common/users/saga"

export default function* coreSaga() {
  yield all([
    fork(layoutSaga),
    fork(navbarSaga),
    fork(loginSaga),
    fork(registerSaga),
    fork(AuthWatcherSaga),
    fork(JobsWatcherSaga),
    fork(ResumeForWatcherSaga),
    fork(seekerProfileSaga),
    fork(jobCategorySaga),
    fork(ProfileSaga),
    fork(CompanyProfileSaga),
    fork(skillSaga),
    fork(oAuthKeysSaga),
    fork(securitySaga),
    fork(groupManagementSaga),
    fork(usersSaga),
    fork(planSaga),
    fork(meetingSaga),
    fork(dashboardSaga),
    fork(evaluationSaga),
    fork(onBoardingSaga),
    fork(assessmentSaga)
  ])
}
