import * as evaluationActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createEvaluation,
  deleteEvaluation,
  getAllEvaluation,
  getEvaluation,
  updateEvaluation
} from "@src/services/apis"

import { EVALUATION_TYPES } from "./types"

function* createEvaluationRequest(action) {
  try {
    const response = yield call(createEvaluation, action.payload)
    if (response.status === 201) {
      yield put(evaluationActions.createEvaluationSuccess(response.message))
    } else {
      yield put(evaluationActions.createEvaluationFailure(response.message))
    }
  } catch (err) {
    yield put(evaluationActions.createEvaluationFailure(err))
  }
}

function* getEvaluationRequest(action) {
  try {
    const response = yield call(getEvaluation, action.payload)
    if (response.status === 200) {
      yield put(evaluationActions.getEvaluationSuccess(response.data))
    } else {
      yield put(evaluationActions.getEvaluationFailure(response.message))
    }
  } catch (err) {
    yield put(evaluationActions.getEvaluationFailure(err))
  }
}

function* updateEvaluationRequest(action) {
  try {
    const response = yield call(updateEvaluation, action.payload)
    if (response.status === 200) {
      yield put(evaluationActions.updateEvaluationSuccess(response.message))
    } else {
      yield put(evaluationActions.updateEvaluationFailure(response.message))
    }
  } catch (err) {
    yield put(evaluationActions.updateEvaluationFailure(err))
  }
}

function* deleteEvaluationRequest(action) {
  try {
    const response = yield call(deleteEvaluation, action.payload)
    if (response.status === 200) {
      yield put(evaluationActions.deleteEvaluationSuccess(response.message))
    } else {
      yield put(evaluationActions.deleteEvaluationFailure(response.message))
    }
  } catch (err) {
    yield put(evaluationActions.deleteEvaluationFailure(err))
  }
}

function* getAllEvaluationRequest(action) {
  try {
    const response = yield call(getAllEvaluation, action.payload)
    if (response.status === 200) {
      yield put(evaluationActions.getAllEvaluationSuccess(response.data))
    } else {
      yield put(evaluationActions.getAllEvaluationFailure(response.message))
    }
  } catch (err) {
    yield put(evaluationActions.getAllEvaluationFailure(err))
  }
}

export default function* evaluationSaga() {
  yield all([
    takeEvery(
      EVALUATION_TYPES.CREATE_EVALUATION_REQUEST,
      createEvaluationRequest
    ),
    takeEvery(EVALUATION_TYPES.GET_EVALUATION_REQUEST, getEvaluationRequest),
    takeEvery(
      EVALUATION_TYPES.UPDATE_EVALUATION_REQUEST,
      updateEvaluationRequest
    ),
    takeEvery(
      EVALUATION_TYPES.DELETE_EVALUATION_REQUEST,
      deleteEvaluationRequest
    ),
    takeEvery(
      EVALUATION_TYPES.GET_ALL_EVALUATION_REQUEST,
      getAllEvaluationRequest
    )
  ])
}
