import { SECURITY_TYPES } from "./types"

const initialState = {
  loading: false,
  error: "",
  success: false,
  data: null
}

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECURITY_TYPES.CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true, success: false, error: "" }
    case SECURITY_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: ""
        // message: action.payload.message,
        // data: action.payload.data
      }
    case SECURITY_TYPES.CHANGE_PASSWORD_FAIL:
      return { ...state, loading: false, error: action.payload }

    case SECURITY_TYPES.CLEAR_CHANGE_PASSWORD_RESPONSE:
      return { ...state, loading: false, error: "", success: false }
    default:
      return state
  }
}

export default securityReducer
