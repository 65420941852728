import { OAUTH_KEYS_TYPES } from "./types"

export const getAllOAuthKeysRequest = (data) => ({
  type: OAUTH_KEYS_TYPES.GET_ALL_OAUTH_KEYS_REQUEST,
  payload: data
})

export const getAllOAuthKeysSuccess = (data) => ({
  type: OAUTH_KEYS_TYPES.GET_ALL_OAUTH_KEYS_SUCCESS,
  payload: data
})

export const getAllOAuthKeysFailure = (data) => ({
  type: OAUTH_KEYS_TYPES.GET_ALL_OAUTH_KEYS_FAIL,
  payload: data
})

export const createOAuthKeysRequest = (data) => ({
  type: OAUTH_KEYS_TYPES.CREATE_OAUTH_KEYS_REQUEST,
  payload: data
})

export const createOAuthKeysSuccess = (data) => ({
  type: OAUTH_KEYS_TYPES.CREATE_OAUTH_KEYS_SUCCESS,
  payload: data
})

export const createOAuthKeysFailure = (data) => ({
  type: OAUTH_KEYS_TYPES.CREATE_OAUTH_KEYS_FAIL,
  payload: data
})

export const changeOAuthKeyStatusRequest = (data) => ({
  type: OAUTH_KEYS_TYPES.CHANGE_OAUTH_KEY_STATUS_REQUEST,
  payload: data
})

export const changeOAuthKeyStatusSuccess = (data) => ({
  type: OAUTH_KEYS_TYPES.CHANGE_OAUTH_KEY_STATUS_SUCCESS,
  payload: data
})

export const changeOAuthKeyStatusFailure = (data) => ({
  type: OAUTH_KEYS_TYPES.CHANGE_OAUTH_KEY_STATUS_FAIL,
  payload: data
})

export const getAuthorizedUrisRequest = (data) => ({
  type: OAUTH_KEYS_TYPES.GET_AUTHORIZE_URIS_REQUEST,
  payload: data
})

export const getAuthorizedUrisSuccess = (data) => ({
  type: OAUTH_KEYS_TYPES.GET_AUTHORIZE_URIS_SUCCESS,
  payload: data
})

export const getAuthorizedUrisFailure = (data) => ({
  type: OAUTH_KEYS_TYPES.GET_AUTHORIZE_URIS_FAIL,
  payload: data
})

export const postAuthorizedUrisRequest = (data) => ({
  type: OAUTH_KEYS_TYPES.POST_AUTHORIZE_URIS_REQUEST,
  payload: data
})

export const postAuthorizedUrisSuccess = (data) => ({
  type: OAUTH_KEYS_TYPES.POST_AUTHORIZE_URIS_SUCCESS,
  payload: data
})

export const postAuthorizedUrisFailure = (data) => ({
  type: OAUTH_KEYS_TYPES.POST_AUTHORIZE_URIS_FAIL,
  payload: data
})

export const updateAuthorizedUrisRequest = (data) => ({
  type: OAUTH_KEYS_TYPES.UPDATE_AUTHORIZE_URI_REQUEST,
  payload: data
})

export const updateAuthorizedUrisSuccess = (data) => ({
  type: OAUTH_KEYS_TYPES.UPDATE_AUTHORIZE_URI_SUCCESS,
  payload: data
})

export const updateAuthorizedUrisFailure = (data) => ({
  type: OAUTH_KEYS_TYPES.UPDATE_AUTHORIZE_URI_FAIL,
  payload: data
})

export const deleteAuthorizedUrisRequest = (data) => ({
  type: OAUTH_KEYS_TYPES.DELETE_AUTHORIZE_URI_REQUEST,
  payload: data
})

export const deleteAuthorizedUrisSuccess = (data) => ({
  type: OAUTH_KEYS_TYPES.DELETE_AUTHORIZE_URI_SUCCESS,
  payload: data
})

export const deleteAuthorizedUrisFailure = (data) => ({
  type: OAUTH_KEYS_TYPES.DELETE_AUTHORIZE_URI_FAIL,
  payload: data
})
