import { MEETINGS_TYPES } from "./types"
import { toast } from "react-hot-toast"
import { typeOptions } from "@src/utility/Options" // Import typeOptions

const initialState = {
  meetings: {},
  error: null,
  created: false,
  deleted: false,
  updated: false,
  createdUpdatedLoading: false,
  createdTeamsMeeting: false,
  credsError: false,
  deletedLoading: false,
  selectedMeeting: {},
  selectedCalendars: [
    "Internal",
    "Business",
    "Performance Evaluation",
    "Onboarding",
    "Technical"
  ],
  filteredMeetings: [] // Add filteredMeetings to the state
}

const meetingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEETINGS_TYPES.GET_ALL_MEETINGS_REQUEST:
      return {
        ...state,
        error: null,
        credsError: false,
        created: false,
        createdTeamsMeeting: false,
        deleted: false,
        updated: false
      }
    case MEETINGS_TYPES.GET_ALL_MEETINGS_SUCCESS:
      const filteredPayload = action.payload.filter((meeting) =>
        state.selectedCalendars.find((calendar) => {
          const typeOption = typeOptions.find(
            (option) => option.label === calendar
          )
          return typeOption && typeOption.value === meeting.type
        })
      )
      return {
        ...state,
        meetings: action.payload,
        filteredMeetings: filteredPayload,
        selectedMeeting: {},
        createdUpdatedLoading: false,
        deletedLoading: false
      }
    case MEETINGS_TYPES.GET_ALL_MEETINGS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case MEETINGS_TYPES.CREATE_ZOOM_MEETING_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        error: null,
        created: false,
        credsError: false
      }

    case MEETINGS_TYPES.CREATE_ZOOM_MEETING_SUCCESS:
      return {
        ...state,
        createdUpdatedLoading: false,
        created: true,
        credsError: false
      }
    case MEETINGS_TYPES.CREATE_ZOOM_MEETING_FAIL:
      if (typeof action.payload === "string") {
        toast.error(action.payload)
      }

      return {
        ...state,
        credsError: true,
        error: action.payload,
        createdUpdatedLoading: false,
        created: false
      }

    case MEETINGS_TYPES.UPDATE_MEETING_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        error: null,
        updated: false
      }

    case MEETINGS_TYPES.UPDATE_MEETING_SUCCESS:
      return {
        ...state,
        updated: true,
        createdUpdatedLoading: false
      }

    case MEETINGS_TYPES.UPDATE_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false,
        updated: false
      }

    case MEETINGS_TYPES.DELETE_MEETING_REQUEST:
      return {
        ...state,
        deletedLoading: true,
        error: null,
        deleted: false
      }

    case MEETINGS_TYPES.DELETE_MEETING_SUCCESS:
      const filteredMeetings = state.filteredMeetings.filter(
        (meeting) => meeting._id !== action.payload
      )

      const meetings = state.meetings.filter(
        (meeting) => meeting._id !== action.payload
      )
      return {
        ...state,
        deleted: true,
        deletedLoading: false,
        meetings: meetings,
        filteredMeetings
      }

    case MEETINGS_TYPES.DELETE_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
        deletedLoading: false,
        deleted: false
      }

    case MEETINGS_TYPES.SELECT_EVENT:
      const selectedMeeting = state.meetings.find(
        (meeting) => meeting.meeting_id === action.payload
      )

      return {
        ...state,
        selectedMeeting: selectedMeeting ? selectedMeeting : {}
      }

    case MEETINGS_TYPES.REMOVE_EVENT:
      return {
        ...state
      }

    case MEETINGS_TYPES.FILTER_MEETINGS:
      const filteredMeeting = state.meetings.filter((meeting) =>
        action.payload.find((payloadLabel) => {
          const typeOption = typeOptions.find(
            (option) => option.label === payloadLabel
          )
          return typeOption && typeOption.value === meeting.type
        })
      )
      return {
        ...state,
        selectedCalendars: action.payload,
        filteredMeetings: filteredMeeting
      }

    case MEETINGS_TYPES.RESET_ERROR:
      return {
        ...state,
        error: null,
        created: false,
        deleted: false,
        updated: false,
        createdUpdatedLoading: false,
        credsError: false,
        deletedLoading: false,
        createdTeamsMeeting: false
      }

    case MEETINGS_TYPES.TEAMS_CLIENT_CONNECT_REQUEST:
      return {
        ...state,
        error: null,
        createdUpdatedLoading: true
      }

    case MEETINGS_TYPES.TEAMS_CLIENT_CONNECT_SUCCESS:
      return {
        ...state
      }

    case MEETINGS_TYPES.TEAMS_CLIENT_CONNECT_FAIL:
      return {
        ...state,
        createdUpdatedLoading: false,
        error: action.payload
      }

    case MEETINGS_TYPES.CREATE_TEAMS_MEETING_REQUEST:
      return {
        ...state,
        error: null,
        createdUpdatedLoading: true,
        createdTeamsMeeting: false
      }

    case MEETINGS_TYPES.CREATE_TEAMS_MEETING_SUCCESS:
      toast.success("Meeting Created Successfully!")

      return {
        ...state,
        createdUpdatedLoading: false,
        createdTeamsMeeting: true
      }

    case MEETINGS_TYPES.CREATE_TEAMS_MEETING_FAIL:
      toast.error("Something went wrong!")
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false,
        createdTeamsMeeting: false
      }

    default:
      return state
  }
}

export default meetingsReducer
