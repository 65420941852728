import { ONBOARDING_TYPES } from "./types"

export const getAllOnBoardingsRequest = (data) => ({
  type: ONBOARDING_TYPES.GET_ALL_ONBOARDINGS_REQUEST,
  payload: data
})

export const getAllOnBoardingsSuccess = (data) => ({
  type: ONBOARDING_TYPES.GET_ALL_ONBOARDINGS_SUCCESS,
  payload: data
})

export const getAllOnBoardingsFailure = (data) => ({
  type: ONBOARDING_TYPES.GET_ALL_ONBOARDINGS_FAIL,
  payload: data
})

export const createOnBoardingRequest = (data) => ({
  type: ONBOARDING_TYPES.CREATE_ONBOARDING_REQUEST,
  payload: data
})

export const createOnBoardingSuccess = (data) => ({
  type: ONBOARDING_TYPES.CREATE_ONBOARDING_SUCCESS,
  payload: data
})

export const createOnBoardingFailure = (data) => ({
  type: ONBOARDING_TYPES.CREATE_ONBOARDING_FAIL,
  payload: data
})

export const updateOnBoardingRequest = (data) => ({
  type: ONBOARDING_TYPES.UPDATE_ONBOARDING_REQUEST,
  payload: data
})

export const updateOnBoardingSuccess = (data) => ({
  type: ONBOARDING_TYPES.UPDATE_ONBOARDING_SUCCESS,
  payload: data
})

export const updateOnBoardingFailure = (data) => ({
  type: ONBOARDING_TYPES.UPDATE_ONBOARDING_FAIL,
  payload: data
})

export const deleteOnBoardingRequest = (data) => ({
  type: ONBOARDING_TYPES.DELETE_ONBOARDING_REQUEST,
  payload: data
})

export const deleteOnBoardingSuccess = (data) => ({
  type: ONBOARDING_TYPES.DELETE_ONBOARDING_SUCCESS,
  payload: data
})

export const deleteOnBoardingFailure = (data) => ({
  type: ONBOARDING_TYPES.DELETE_ONBOARDING_FAIL,
  payload: data
})

export const scheduleOnBoardingRequest = (data) => ({
  type: ONBOARDING_TYPES.SCHEDULE_ONBOARDING_REQUEST,
  payload: data
})

export const scheduleOnBoardingSuccess = (data) => ({
  type: ONBOARDING_TYPES.SCHEDULE_ONBOARDING_SUCCESS,
  payload: data
})

export const scheduleOnBoardingFailure = (data) => ({
  type: ONBOARDING_TYPES.SCHEDULE_ONBOARDING_FAIL,
  payload: data
})

export const resetOnBoarding = () => ({
  type: ONBOARDING_TYPES.RESET_ONBOARDING
})

export const submitEvaluationFormRequest = (data) => ({
  type: ONBOARDING_TYPES.SUBMIT_EVALUATION_FORM_REQUEST,
  payload: data
})

export const submitEvaluationFormSuccess = (data) => ({
  type: ONBOARDING_TYPES.SUBMIT_EVALUATION_FORM_SUCCESS,
  payload: data
})

export const submitEvaluationFormFailure = (data) => ({
  type: ONBOARDING_TYPES.SUBMIT_EVALUATION_FORM_FAIL,
  payload: data
})

export const getInterviewerRequest = (data) => ({
  type: ONBOARDING_TYPES.GET_INTERVIEWER_REQUEST,
  payload: data
})

export const getInterviewerSuccess = (data) => ({
  type: ONBOARDING_TYPES.GET_INTERVIEWER_SUCCESS,
  payload: data
})

export const getInterviewerFailure = (data) => ({
  type: ONBOARDING_TYPES.GET_INTERVIEWER_FAIL,
  payload: data
})

export const getAllAssignedInterviewRequest = (data) => ({
  type: ONBOARDING_TYPES.GET_ALL_ASSIGNED_INTERVIEW_REQUEST,
  payload: data
})

export const getAllAssignedInterviewSuccess = (data) => ({
  type: ONBOARDING_TYPES.GET_ALL_ASSIGNED_INTERVIEW_SUCCESS,
  payload: data
})

export const getAllAssignedInterviewFailure = (data) => ({
  type: ONBOARDING_TYPES.GET_ALL_ASSIGNED_INTERVIEW_FAIL,
  payload: data
})

export const getSpecificAssignedInterviewRequest = (data) => ({
  type: ONBOARDING_TYPES.GET_SPECIFIC_ASSIGNED_INTERVIEW_REQUEST,
  payload: data
})

export const getSpecificAssignedInterviewSuccess = (data) => ({
  type: ONBOARDING_TYPES.GET_SPECIFIC_ASSIGNED_INTERVIEW_SUCCESS,
  payload: data
})

export const getSpecificAssignedInterviewFailure = (data) => ({
  type: ONBOARDING_TYPES.GET_SPECIFIC_ASSIGNED_INTERVIEW_FAIL,
  payload: data
})

export const cancelInterviewRequest = (data) => ({
  type: ONBOARDING_TYPES.CANCEL_INTERVIEW_REQUEST,
  payload: data
})

export const cancelInterviewSuccess = (data) => ({
  type: ONBOARDING_TYPES.CANCEL_INTERVIEW_SUCCESS,
  payload: data
})

export const cancelInterviewFailure = (data) => ({
  type: ONBOARDING_TYPES.CANCEL_INTERVIEW_FAIL,
  payload: data
})

export const rejectApplicantRequest = (data) => ({
  type: ONBOARDING_TYPES.REJECT_APPLICANT_REQUEST,
  payload: data
})

export const rejectApplicantSuccess = (data) => ({
  type: ONBOARDING_TYPES.REJECT_APPLICANT_SUCCESS,
  payload: data
})

export const rejectApplicantFailure = (data) => ({
  type: ONBOARDING_TYPES.REJECT_APPLICANT_FAIL,
  payload: data
})

export const uploadFileRequest = (data) => ({
  type: ONBOARDING_TYPES.UPLOAD_FILE_REQUEST,
  payload: data
})

export const uploadFileSuccess = (data) => ({
  type: ONBOARDING_TYPES.UPLOAD_FILE_SUCCESS,
  payload: data
})

export const uploadFileFailure = (data) => ({
  type: ONBOARDING_TYPES.UPLOAD_FILE_FAIL,
  payload: data
})

export const sendOfferLetterRequest = (data) => ({
  type: ONBOARDING_TYPES.SEND_OFFER_LETTER_REQUEST,
  payload: data
})

export const sendOfferLetterSuccess = (data) => ({
  type: ONBOARDING_TYPES.SEND_OFFER_LETTER_SUCCESS,
  payload: data
})

export const sendOfferLetterFailure = (data) => ({
  type: ONBOARDING_TYPES.SEND_OFFER_LETTER_FAIL,
  payload: data
})

export const resetAcceptRejectState = () => ({
  type: ONBOARDING_TYPES.RESET_ACCEPT_REJECT_STATE
})

export const offerLetterResponseRequest = (data) => ({
  type: ONBOARDING_TYPES.OFFER_LETTER_RESPONSE_REQUEST,
  payload: data
})

export const offerLetterResponseSuccess = (data) => ({
  type: ONBOARDING_TYPES.OFFER_LETTER_RESPONSE_SUCCESS,
  payload: data
})

export const offerLetterResponseFailure = (data) => ({
  type: ONBOARDING_TYPES.OFFER_LETTER_RESPONSE_FAIL,
  payload: data
})

// Path: src/store/common/onBoarding/reducer.js
