import { applyMiddleware, compose, createStore } from "redux"

import appReducer from "./coreReducers"
import coreSaga from "./coreSaga"
import createSagaMiddleware from "redux-saga"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(coreSaga)

export default store
