export const locationtype = [
  { value: "on-site", label: "On-Site" },
  { value: "remote", label: "Remote" },
  { value: "hybrid", label: "Hybrid" }
]

export const jobStatusOptions = [
  { value: "active", label: "Active" },
  { value: "unactive", label: "Inactive" }
]

// ["accepted", "pending", "approved", "invited" ]
export const ApplicantStatusOptions = [
  { value: "in_review", label: "In-Review", color: "light-warning" },
  { value: "accepted", label: "Accepted", color: "light-success" },
  { value: "invited", label: "Invited", color: "light-primary" },
  { value: "approved", label: "Approved", color: "light-success" },
  { value: "rejected", label: "Rejected", color: "light-danger" },
  { value: "short_listed", label: "Shortlisted", color: "light-primary" },
  {
    value: "applicant_accepted_offer",
    label: "Offer Accepted",
    color: "light-success"
  },
  {
    value: "applicant_rejected_offer",
    label: "Offer Rejected",
    color: "light-danger"
  }

  // applicant_rejected_offer
]

export const jobShiftOptions = [
  { value: "morning", label: "Morning" },
  { value: "evening", label: "Evening" },
  { value: "night", label: "Night" }
]

export const jobTypeOptions = [
  { value: "full-time", label: "Full-Time" },
  { value: "part-time", label: "Part-Time" },
  { value: "contract", label: "Contract" },
  { value: "temporary", label: "Temporary" },
  { value: "freelancer", label: "Freelancer" },
  { value: "intern", label: "Intern" },
  { value: "on-call", label: "On Call" },
  { value: "visiting", label: "Visiting" }
]

// experience: { enum: ["0-1year", "1-3year", "<5year", "<10year"], },
export const experienceRequiredOptions = [
  // RANGES
  { value: "0-1year", label: "0-1 Year" },
  { value: "1-3year", label: "1-3 Year" },
  { value: "<5year", label: "5+ Years" },
  { value: "<10year", label: "10+ Years" }
]

export const location = [
  { value: "lahore", label: "Lahore" },
  { value: "karachi", label: "Karachi" },
  { value: "islamabad", label: "Islamabad" }
]

export const ProficiencyOptions = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "expert", label: "Expert" }
]

export const rowsItemsOptionsFor3 = [
  { value: 18, label: "18" },
  { value: 36, label: "36" },
  { value: 54, label: "54" },
  { value: 72, label: "72" }
]

export const rowsItemsOptions = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 70, label: "70" }
]

export const FeatureStatusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" }
]

export const typeOptions = [
  {
    value: "internal",
    label: "Internal",
    color: "danger",
    className: "form-check-danger mb-1"
  },
  {
    value: "business",
    label: "Business",
    color: "primary",
    className: "form-check-primary mb-1"
  },
  {
    value: "performance-evaluation",
    label: "Performance Evaluation",
    color: "warning",
    className: "form-check-warning mb-1"
  },
  {
    value: "onboarding",
    label: "Onboarding",
    color: "success",
    className: "form-check-success mb-1"
  },
  {
    value: "technical",
    label: "Technical",
    color: "info",
    className: "form-check-info"
  }
]

export const languageOptions = [
  { value: "english", label: "English" },
  { value: "arabic", label: "Arabic" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
  { value: "portuguese", label: "Portuguese" }
]

export const questionTypeOptions = [
  { value: "multiple_choice", label: "Multiple Choice" },
  { value: "single_choice", label: "Single Choice" }
]

export const assessmentTypeOptions = [
  { value: "profile", label: "Profile" },
  { value: "technical", label: "Interview" },
  { value: "both", label: "Both" }
]

export const questionDifficultyOptions = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "hard", label: "Hard" },
  { value: "expert", label: "Expert" }
]
