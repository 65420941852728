// ** Auth Endpoints
export default {
  loginEndpoint: "/auth/signin",
  registerEndpoint: "/jwt/register",
  refreshEndpoint: "/jwt/refresh-token",
  logoutEndpoint: "/jwt/logout",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
  applicantId: "applicantId",
  accessControl: "roleAccess",
  aiGenLimit: "aiGenLimit",
  currAiGenLimit: "currAiGenLimit",
  aiCategorizeLimit: "aiCategorizeLimit",
  currAiCategorizeLimit: "currAiCategorizeLimit",
  aiSkillTestLimit: "aiSkillTestLimit",
  currAiSkillTestLimit: "currAiSkillTestLimit",
  createJobPostLimit: "createJobPostLimit",
  currCreateJobPostLimit: "currCreateJobPostLimit",
  currCreateJobCategoryLimit: "currCreateJobCategoryLimit",
  createJobCategoryLimit: "createJobCategoryLimit",
  currCreateSkillSetLimit: "currCreateSkillSetLimit",

  createUserLimit: "createUserLimit",
  currCreateUserLimit: "currCreateUserLimit",

  genAIAssessmentLimit: "genAIAssessmentLimit",
  currGenAIAssessmentLimit: "currGenAIAssessmentLimit",

  getAllApplicantLimit: "getAllApplicantLimit",
  currGetAllApplicantLimit: "currGetAllApplicantLimit",

  createSkillSetLimit: "createSkillSetLimit",
  companyName: "company_name",
  currentPlan: "current_plan",
  companyLogo: "company_logo",
  language: "language",
  defaultLanguage: "en"
}
