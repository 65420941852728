import { createActionTypes } from "@src/utility"

export const PROFILE_TYPES = createActionTypes("PROFILE_TYPES", [

  // GET EXPERIENCE
  "GET_PROFILE_EXPERIENCE_REQUEST",
  "GET_PROFILE_EXPERIENCE_SUCCESS",
  "GET_PROFILE_EXPERIENCE_FAILURE",

  // GET EDUCATION
  "GET_PROFILE_EDUCATION_REQUEST",
  "GET_PROFILE_EDUCATION_SUCCESS",
  "GET_PROFILE_EDUCATION_FAILURE",

  // GET SKILLS
  "GET_PROFILE_SKILLS_REQUEST",
  "GET_PROFILE_SKILLS_SUCCESS",
  "GET_PROFILE_SKILLS_FAILURE",

  // GET LANGUAGES
  "GET_PROFILE_LANGUAGES_REQUEST",
  "GET_PROFILE_LANGUAGES_SUCCESS",
  "GET_PROFILE_LANGUAGES_FAILURE",

  // POST EXPERIENCE
  "POST_PROFILE_EXPERIENCE_REQUEST",
  "POST_PROFILE_EXPERIENCE_SUCCESS",
  "POST_PROFILE_EXPERIENCE_FAILURE",

  // POST EDUCATION
  "POST_PROFILE_EDUCATION_REQUEST",
  "POST_PROFILE_EDUCATION_SUCCESS",
  "POST_PROFILE_EDUCATION_FAILURE",

  // POST LANGUAGES
  "POST_PROFILE_LANGUAGES_REQUEST",
  "POST_PROFILE_LANGUAGES_SUCCESS",
  "POST_PROFILE_LANGUAGES_FAILURE"
  
])
