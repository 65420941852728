import { LOGIN_TYPES } from "../common/login/types"
import ProfileReducer from "../common/workExperience/reducer"
import assessmentReducer from "../common/assessment/reducer"
import authReducer from "./../common/auth/reducer"
import { combineReducers } from "redux"
import companyProfileReducer from "./../common/companyProfile/reducer"
import dashboardReducer from "../common/dashboard/reducer"
import evaluationReducer from "../common/evaluationForm/reducer"
import groupManagementReducer from "../common/groupManagement/reducer"
import jobCategoryReducer from "./../common/jobcategory/reducer"
import jobsReducer from "./../common/jobs/reducer"
import layoutReducer from "../common/layout/reducers"
import loginReducer from "./../common/login/reducer"
import meetingReducer from "../common/meeting/reducer"
import navbarReducer from "./../common/navbar/reducers"
import oAuthKeysReducer from "../common/oAuthKeys/reducer"
import onBoardingReducer from "../common/onBoarding/reducer"
import planReducer from "../common/plan/reducer"
import registerReducer from "../common/register/reducer"
import resumeFormReducer from "./../common/resumeform/reducer"
import securityReducer from "./../common/security/reducer"
import seekerProfileReducer from "./../common/seekerProfile/reducer"
import skillReducer from "../common/skills/reducer"
import usersReducer from "../common/users/reducer"

const coreReducer = combineReducers({
  loginReducer,
  layoutReducer,
  navbarReducer,
  registerReducer,
  authReducer,
  jobsReducer,
  resumeFormReducer,
  seekerProfileReducer,
  companyProfileReducer,
  jobCategoryReducer,
  ProfileReducer,
  skillReducer,
  oAuthKeysReducer,
  securityReducer,
  groupManagementReducer,
  usersReducer,
  planReducer,
  meetingReducer,
  dashboardReducer,
  evaluationReducer,
  onBoardingReducer,
  assessmentReducer
})

const appReducer = (state, action) => {
  if (action.type === LOGIN_TYPES.LOGOUT) {
    state = undefined
  }
  return coreReducer(state, action)
}

export default appReducer

//export default coreReducer
