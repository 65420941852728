import { DASHBOARD } from "./types"

const initialState = {
  stats: null,
  applicantsStatus: null,
  applicantsReport: null,
  loading: false,
  statsLoading: false,
  applicantsStatusLoading: false,
  applicantsReportLoading: false,
  error: null
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD.GET_STATS_REQUEST:
      return { ...state, statsLoading: true }
    case DASHBOARD.GET_APPLICANTS_STATUS_REQUEST:
      return { ...state, applicantsStatusLoading: true }
    case DASHBOARD.GET_APPLICANTS_REPORT_REQUEST:
      return { ...state, applicantsReportLoading: true }
    case DASHBOARD.GET_STATS_SUCCESS:
      return { ...state, statsLoading: false, stats: action.payload }
    case DASHBOARD.GET_APPLICANTS_STATUS_SUCCESS:
      return {
        ...state,
        applicantsStatusLoading: false,
        applicantsStatus: action.payload
      }
    case DASHBOARD.GET_APPLICANTS_REPORT_SUCCESS:
      return {
        ...state,
        applicantsReportLoading: false,
        applicantsReport: action.payload
      }
    case DASHBOARD.GET_STATS_FAIL:
      return { ...state, statsLoading: false, error: action.payload }
    case DASHBOARD.GET_APPLICANTS_STATUS_FAIL:
      return { ...state, applicantsStatusLoading: false, error: action.payload }
    case DASHBOARD.GET_APPLICANTS_REPORT_FAIL:
      return { ...state, applicantsReportLoading: false, error: action.payload }
    default:
      return state
  }
}

export default dashboardReducer
