import { createActionTypes } from "@src/utility"

export const MEETINGS_TYPES = createActionTypes("meetings", [
  "GET_ALL_MEETINGS_REQUEST",
  "GET_ALL_MEETINGS_SUCCESS",
  "GET_ALL_MEETINGS_FAIL",

  "CREATE_ZOOM_MEETING_REQUEST",
  "CREATE_ZOOM_MEETING_SUCCESS",
  "CREATE_ZOOM_MEETING_FAIL",

  "UPDATE_MEETING_REQUEST",
  "UPDATE_MEETING_SUCCESS",
  "UPDATE_MEETING_FAIL",

  "DELETE_MEETING_REQUEST",
  "DELETE_MEETING_SUCCESS",
  "DELETE_MEETING_FAIL",

  "SELECT_EVENT",
  "REMOVE_EVENT",
  "FILTER_MEETINGS",
  "RESET_ERROR",
  "TEAMS_CLIENT_CONNECT_REQUEST",
  "TEAMS_CLIENT_CONNECT_SUCCESS",
  "TEAMS_CLIENT_CONNECT_FAIL",

  "CREATE_TEAMS_MEETING_REQUEST",
  "CREATE_TEAMS_MEETING_SUCCESS",
  "CREATE_TEAMS_MEETING_FAIL"
])
