import { PLAN_TYPES } from "./types"

export const getListOfPlansRequest = (data) => ({
  type: PLAN_TYPES.GET_LIST_OF_PLANS_REQUEST,
  payload: data
})

export const getListOfPlansSuccess = (data) => ({
  type: PLAN_TYPES.GET_LIST_OF_PLANS_SUCCESS,
  payload: data
})

export const getListOfPlansFailure = (data) => ({
  type: PLAN_TYPES.GET_LIST_OF_PLANS_FAILURE,
  payload: data
})
