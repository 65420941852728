import { GROUP_MANAGEMENT_TYPES } from "./types"
import toast from "react-hot-toast"

const initialState = {
  groups: [], // modified and updated always
  error: null,
  updated: {
    status: false,
    id: ""
  },
  moduleLoading: {
    status: false,
    id: ""
  },
  featureLoading: {
    status: false,
    id: ""
  },
  groupOptions: []
}

const groupManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_REQUEST:
      return {
        ...state,
        error: null
      }

    case GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload
      }
    case GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GROUP_MANAGEMENT_TYPES.UPDATE_MODULE_STATUS_REQUEST:
      return {
        ...state,
        moduleLoading: {
          status: true,
          id: action.payload.module
        },
        error: null,
        updated: {
          status: false,
          id: action.payload.module
        }
      }

    case GROUP_MANAGEMENT_TYPES.UPDATE_MODULE_STATUS_SUCCESS:
      return {
        ...state,
        moduleLoading: {
          status: false,
          id: action.payload.module
        },
        updated: {
          status: true,
          id: action.payload.module
        },
        groups: state.groups.map((group) => {
          if (group._id === action.payload.group) {
            return {
              ...group,
              modules: action.payload.modules
            }
          } else {
            return group
          }
        })
      }

    case GROUP_MANAGEMENT_TYPES.UPDATE_MODULE_STATUS_FAIL:
      toast.error("Failed to update module status")
      return {
        ...state,
        moduleLoading: {
          ...state.moduleLoading,
          status: false
        },
        error: action.payload
      }

    case GROUP_MANAGEMENT_TYPES.UPDATE_FEATURE_STATUS_REQUEST:
      return {
        ...state,
        featureLoading: {
          status: true,
          id: action.payload.feature
        },
        error: null,
        updated: {
          status: false,
          id: action.payload.feature
        }
      }

    case GROUP_MANAGEMENT_TYPES.UPDATE_FEATURE_STATUS_SUCCESS:
      return {
        ...state,
        featureLoading: {
          status: false,
          id: action.payload.feature
        },
        updated: {
          status: true,
          id: action.payload.feature
        },
        groups: state.groups.map((group) => {
          if (group._id === action.payload.group) {
            return {
              ...group,
              modules: action.payload.modules
            }
          } else {
            return group
          }
        })
      }

    case GROUP_MANAGEMENT_TYPES.UPDATE_FEATURE_STATUS_FAIL:
      toast.error("Failed to update feature status")
      return {
        ...state,
        featureLoading: {
          ...state.featureLoading,
          status: false
        },
        error: action.payload
      }

    case GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_NAME_SUCCESS:
      return {
        ...state,
        groupOptions: action.payload
      }

    case GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_NAME_FAIL:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default groupManagementReducer
