import { createActionTypes } from "@src/utility"

export const EVALUATION_TYPES = createActionTypes("EVALUATION", [
  "CREATE_EVALUATION_REQUEST",
  "CREATE_EVALUATION_SUCCESS",
  "CREATE_EVALUATION_FAIL",
  "RESET_ADDED_STATUS",
  "GET_EVALUATION_REQUEST",
  "GET_EVALUATION_SUCCESS",
  "GET_EVALUATION_FAIL",
  "UPDATE_EVALUATION_REQUEST",
  "UPDATE_EVALUATION_SUCCESS",
  "UPDATE_EVALUATION_FAIL",
  "DELETE_EVALUATION_REQUEST",
  "DELETE_EVALUATION_SUCCESS",
  "DELETE_EVALUATION_FAIL",
  "GET_ALL_EVALUATION_REQUEST",
  "GET_ALL_EVALUATION_SUCCESS",
  "GET_ALL_EVALUATION_FAIL"
])
