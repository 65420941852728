import * as planActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"

import { PLAN_TYPES } from "./types"
import { getPlans } from "@src/services/apis"

function* getPlansRequest(action) {
  try {
    const response = yield call(getPlans, action.payload)

    if (response?.status === 200) {
      yield put(planActions.getListOfPlansSuccess(response.data))
    }
  } catch (err) {
    yield put(planActions.getListOfPlansFailure(err))
  }
}

function* planSaga() {
  yield all([takeEvery(PLAN_TYPES.GET_LIST_OF_PLANS_REQUEST, getPlansRequest)])
}

export default planSaga
