import { createActionTypes } from "@src/utility"

export const OAUTH_KEYS_TYPES = createActionTypes("OAUTH_KEYS", [
  "GET_ALL_OAUTH_KEYS_REQUEST",
  "GET_ALL_OAUTH_KEYS_SUCCESS",
  "GET_ALL_OAUTH_KEYS_FAIL",
  "CREATE_OAUTH_KEYS_REQUEST",
  "CREATE_OAUTH_KEYS_SUCCESS",
  "CREATE_OAUTH_KEYS_FAIL",
  "CHANGE_OAUTH_KEY_STATUS_REQUEST",
  "CHANGE_OAUTH_KEY_STATUS_SUCCESS",
  "CHANGE_OAUTH_KEY_STATUS_FAIL",
  "GET_AUTHORIZE_URIS_REQUEST",
  "GET_AUTHORIZE_URIS_SUCCESS",
  "GET_AUTHORIZE_URIS_FAIL",
  // "POST"
  "POST_AUTHORIZE_URIS_REQUEST",
  "POST_AUTHORIZE_URIS_SUCCESS",
  "POST_AUTHORIZE_URIS_FAIL",
  // "PUT"
  "UPDATE_AUTHORIZE_URI_REQUEST",
  "UPDATE_AUTHORIZE_URI_SUCCESS",
  "UPDATE_AUTHORIZE_URI_FAIL",
  // "DELETE"
  "DELETE_AUTHORIZE_URI_REQUEST",
  "DELETE_AUTHORIZE_URI_SUCCESS",
  "DELETE_AUTHORIZE_URI_FAIL"
])
