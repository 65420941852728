import * as dashboardActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  getApplicantsReport,
  getApplicantsStatus,
  getStats
} from "@src/services/apis"

import { DASHBOARD } from "./types"

function* getStatsRequest(action) {
  try {
    const response = yield call(getStats, action.payload)
    yield put(dashboardActions.getStatsSuccess(response.data))
  } catch (err) {
    yield put(dashboardActions.getStatsFailure(err))
  }
}

function* getApplicantsStatusRequest(action) {
  try {
    const response = yield call(getApplicantsStatus, action.payload)
    yield put(dashboardActions.getApplicantsStatusSuccess(response.data))
  } catch (err) {
    yield put(dashboardActions.getApplicantsStatusFailure(err))
  }
}

function* getApplicantsReportRequest(action) {
  try {
    const response = yield call(getApplicantsReport, action.payload)
    if (response.status === 200) {
      yield put(dashboardActions.getApplicantsReportSuccess(response.data))
    } else {
      yield put(dashboardActions.getApplicantsReportFailure(response.data))
    }
  } catch (err) {
    yield put(dashboardActions.getApplicantsReportFailure(err))
  }
}

export default function* dashboardSaga() {
  yield all([
    takeEvery(DASHBOARD.GET_STATS_REQUEST, getStatsRequest),
    takeEvery(
      DASHBOARD.GET_APPLICANTS_STATUS_REQUEST,
      getApplicantsStatusRequest
    ),
    takeEvery(
      DASHBOARD.GET_APPLICANTS_REPORT_REQUEST,
      getApplicantsReportRequest
    )
  ])
}
