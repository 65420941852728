import { SKILL_TYPES } from "./types"
import useJwt from "@src/auth/jwt/useJwt"

const config = useJwt.jwtConfig

const initialState = {
  skills: {},
  error: null,
  deleted: false,
  updated: false,
  added: false,
  createdUpdatedLoading: false
}

const skillReducer = (state = initialState, action) => {
  switch (action.type) {
    case SKILL_TYPES.GET_ALL_SKILLS_REQUEST:
      return {
        ...state,
        updated: false,
        deleted: false,
        added: false
      }
    case SKILL_TYPES.GET_ALL_SKILLS_SUCCESS:
      return {
        ...state,
        skills: action.payload
      }
    case SKILL_TYPES.GET_ALL_SKILLS_FAIL:
      return {
        ...state,
        error: action.payload
      }
    case SKILL_TYPES.CREATE_SKILL_REQUEST:
      return {
        ...state,
        added: false,
        createdUpdatedLoading: true
      }
    case SKILL_TYPES.CREATE_SKILL_SUCCESS:
      if (localStorage.getItem(config.currentPlan) !== "advance") {
        localStorage.setItem(
          "currCreateSkillSetLimit",
          action.payload?.current
            ? action.payload?.current
            : localStorage.getItem("currCreateSkillSetLimit") > 0
            ? localStorage.getItem("currCreateSkillSetLimit") - 1
            : 0
        )
      }

      return {
        ...state,
        added: true,
        createdUpdatedLoading: false
      }
    case SKILL_TYPES.CREATE_SKILL_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false
      }
    case SKILL_TYPES.UPDATE_SKILL_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        updated: false
      }
    case SKILL_TYPES.UPDATE_SKILL_SUCCESS:
      return {
        ...state,
        updated: true,
        createdUpdatedLoading: false
      }
    case SKILL_TYPES.UPDATE_SKILL_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false
      }

    case SKILL_TYPES.DELETE_SKILL_REQUEST:
      return {
        ...state,
        deleted: false
      }

    case SKILL_TYPES.DELETE_SKILL_SUCCESS:
      if (localStorage.getItem(config.currentPlan) !== "advance") {
        localStorage.setItem(
          "currCreateSkillSetLimit",
          action.payload?.current
            ? action.payload?.current
            : localStorage.getItem("currCreateSkillSetLimit") <
              localStorage.getItem("createSkillSetLimit")
            ? localStorage.getItem("currCreateSkillSetLimit") + 1
            : 0
        )
      }

      return {
        ...state,
        deleted: true
      }

    case SKILL_TYPES.DELETE_SKILL_FAIL:
      return {
        ...state,
        error: action.payload,
        deleted: false
      }

    default:
      return state
  }
}

export default skillReducer
