import * as jobActions from "./actions"

import {
  ChangeJobVisibilityStatus,
  LinkedInJobPosting,
  applyForJob,
  fetchLinkedInAccessToken,
  generateSkillTest,
  generatejobDescription,
  getAllJobs,
  getApplicantsInfo,
  getAppliedJobs,
  getAppliedJobsByApplicants,
  getProfile,
  getSpecificApplicantSpecificJob,
  getSpecificAppliedJob,
  getSpecificAppliedJobsByApplicants,
  getSpecificJob,
  postJob,
  submitAssessment,
  translateJob
} from "@src/services/apis"
import { all, call, put, takeEvery } from "redux-saga/effects"

import { JOBS_ACTION_TYPES } from "./types"
import toast from "react-hot-toast"

function* getAllJobsRequest(action) {
  try {
    const response = yield call(getAllJobs, action.payload)

    yield put(jobActions.getAllJobsSuccess(response.data))
  } catch (err) {
    yield put(jobActions.getAllJobsFailure(err))
  }
}

function* getSpecificJobRequest(action) {
  try {
    const response = yield call(getSpecificJob, action.payload)
    yield put(jobActions.getSpecificJobSuccess(response.data))
  } catch (err) {
    yield put(jobActions.getSpecificJobFailure(err))
  }
}

function* applyForJobRequest(action) {
  try {
    const response = yield call(applyForJob, action.payload)
    if (response.status === 200) {
      yield put(jobActions.applyForJobSuccess(response.data))
    } else if (response.status === 201) {
      yield put(jobActions.applyForJobSuccess(response.message))
    }
  } catch (err) {
    yield put(jobActions.applyForJobFailure(err))
  }
}

function* allAppliedJobsRequest(action) {
  try {
    const response = yield call(getAppliedJobs, action.payload)
    if (response?.status === 200) {
      yield put(jobActions.getAllAppliedJobsSuccess(response.data))
    } else if (response?.response?.status === 403) {
      toast.error(response.response.data.data)
    }
  } catch (err) {
    yield put(jobActions.getAllAppliedJobsFailure(err))
  }
}

function* allAppliedJobsApplicationRequest(action) {
  try {
    const response = yield call(getAppliedJobsByApplicants, action.payload)
    yield put(jobActions.getAllAppliedJobsApplicationSuccess(response.data))
  } catch (err) {
    yield put(jobActions.getAllAppliedJobsApplicationFailure(err))
  }
}

function* specificAppliedJobsApplicationRequest(action) {
  try {
    const response = yield call(
      getSpecificAppliedJobsByApplicants,
      action.payload
    )
    yield put(jobActions.getSpecificAppliedJobApplicationSuccess(response.data))
  } catch (err) {
    yield put(jobActions.getSpecificAppliedJobApplicationFailure(err))
  }
}

function* getSpecificAppliedJobRequest(action) {
  try {
    const response = yield call(getProfile, action.payload)
    yield put(jobActions.getSpecificAppliedJobSuccess(response.data))
  } catch (err) {
    yield put(jobActions.getSpecificAppliedJobFailure(err))
  }
}

// GET SPECIFIC APPLICANT ALL APPLIED JOBS
function* getSpecificApplicantAllAppliedJobsRequest(action) {
  try {
    const response = yield call(getSpecificAppliedJob, action.payload)
    yield put(
      jobActions.getSpecificApplicantAllAppliedJobsSuccess(response.data)
    )
  } catch (err) {
    yield put(jobActions.getSpecificApplicantAllAppliedJobsFailure(err))
  }
}

function* postJobRequest(action) {
  try {
    const response = yield call(postJob, action.payload)
    if (response?.status === 201) {
      yield put(jobActions.createJobSuccess(response?.remaining_calls))
      toast.success("Job posted successfully")
    } else {
      yield put(jobActions.createJobFailure(response.data))
      toast.error("Job posting failed")
    }
  } catch (err) {
    yield put(jobActions.createJobFailure(err))
  }
}

//  AUTO_GENERATE_JOB_DESCRIPTION_REQUEST,

function* autoGenerateJobDescriptionRequest(action) {
  try {
    const response = yield call(generatejobDescription, action.payload)
    if (response?.status === 200) {
      yield put(jobActions.autoGenerateJobDescriptionSuccess(response.data))
    } else if (response?.response?.status === 401) {
      yield put(
        jobActions.autoGenerateJobDescriptionFailure({
          status: 401,
          message: response?.response?.data?.message
        })
      )
    }
  } catch (err) {
    yield put(jobActions.autoGenerateJobDescriptionFailure(err))
  }
}

function* fetchApplicantsInfo(action) {
  try {
    const response = yield call(getApplicantsInfo)
    yield put(jobActions.applicantsInfoSuccess(response.data))
  } catch (err) {
    yield put(jobActions.applicantsInfoFailure(err))
  }
}

function* changeJobStatusRequest(action) {
  try {
    const response = yield call(ChangeJobVisibilityStatus, action.payload)
    yield put(
      jobActions.changeJobStatusSuccess({
        job_id: action.payload,
        remaining_calls: response.remaining_calls
      })
    )
  } catch (err) {
    yield put(jobActions.changeJobStatusFailure(err))
  }
}

function* exchangeAccessTokenRequest(action) {
  try {
    const postedJobId = action.payload.posted_job_id
    const response = yield call(fetchLinkedInAccessToken, action.payload.code)
    if (response?.status === 200) {
      const body = {
        credentials: {
          access_token: response.data.access_token,
          id_token: response.data.id_token
        },
        posted_job_id: postedJobId
      }
      const linkedInResponse = yield call(LinkedInJobPosting, body)
      yield put(jobActions.linkedInJobPostSuccess(postedJobId))
    } else {
      yield put(jobActions.linkedInJobPostFailure(response.data))
    }
  } catch (err) {
    yield put(jobActions.linkedInJobPostFailure(err))
  }
}

function* translateJobRequest(action) {
  try {
    const response = yield call(translateJob, action.payload)
    yield put(jobActions.translateJobDescriptionSuccess(response.data))
  } catch (err) {
    yield put(jobActions.translateJobDescriptionFailure(err))
  }
}

function* generateSkillTestRequest(action) {
  try {
    const response = yield call(generateSkillTest, action.payload)
    yield put(jobActions.generateSkillTestSuccess(response.data))
  } catch (err) {
    yield put(jobActions.generateSkillTestFailure(err))
  }
}

function* submitSkillTestRequest(action) {
  try {
    const response = yield call(submitAssessment, action.payload)
    if (response.status === 200) {
      yield put(jobActions.submitSkillAssessmentSuccess(response.data))
    } else {
      yield put(jobActions.submitSkillAssessmentFailure(response))
    }
  } catch (err) {
    yield put(jobActions.submitSkillAssessmentFailure(err))
  }
}

function* getAllExportJobsRequest(action) {
  try {
    const response = yield call(getAllJobs, action.payload)
    yield put(jobActions.allJobsExportSuccess(response.data))
  } catch (err) {
    yield put(jobActions.allJobsExportFailure(err))
  }
}

// function* GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_REQUEST
function* getSpecificApplicantSpecificAppliedJobRequest(action) {
  try {
    const response = yield call(getSpecificApplicantSpecificJob, action.payload)
    yield put(
      jobActions.getSpecificApplicantSpecificAppliedJobSuccess(response.data)
    )
  } catch (err) {
    yield put(jobActions.getSpecificApplicantSpecificAppliedJobFailure(err))
  }
}

function* JobsWatcherSaga() {
  yield all([
    takeEvery(JOBS_ACTION_TYPES.GET_ALL_JOBS_REQUEST, getAllJobsRequest),
    takeEvery(
      JOBS_ACTION_TYPES.GET_SPECIFIC_JOB_REQUEST,
      getSpecificJobRequest
    ),
    takeEvery(JOBS_ACTION_TYPES.APPLY_FOR_JOB_REQUEST, applyForJobRequest),
    takeEvery(
      JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_REQUEST,
      allAppliedJobsRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_APPLICATION_REQUEST,
      allAppliedJobsApplicationRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_APPLICATION_REQUEST,
      specificAppliedJobsApplicationRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_REQUEST,
      getSpecificAppliedJobRequest
    ),
    takeEvery(JOBS_ACTION_TYPES.CREATE_JOB_REQUEST, postJobRequest),
    takeEvery(
      JOBS_ACTION_TYPES.AUTO_GENERATE_JOB_DESCRIPTION_REQUEST,
      autoGenerateJobDescriptionRequest
    ),
    takeEvery(JOBS_ACTION_TYPES.APPLICANTS_INFO_REQUEST, fetchApplicantsInfo),
    takeEvery(
      JOBS_ACTION_TYPES.CHANGE_JOB_STATUS_REQUEST,
      changeJobStatusRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.EXCHANGE_ACCESS_TOKEN_REQUEST,
      exchangeAccessTokenRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.TRANSLATE_JOB_DESCRIPTION_REQUEST,
      translateJobRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.GENERATE_SKILL_TEST_REQUEST,
      generateSkillTestRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.SUBMIT_SKILL_ASSESSMENT_REQUEST,
      submitSkillTestRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.ALL_JOBS_EXPORT_REQUEST,
      getAllExportJobsRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_REQUEST,
      getSpecificApplicantAllAppliedJobsRequest
    ),
    takeEvery(
      JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_REQUEST,
      getSpecificApplicantSpecificAppliedJobRequest
    )
  ])
}

export default JobsWatcherSaga
