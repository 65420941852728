import { createActionTypes } from "@src/utility"

export const ONBOARDING_TYPES = createActionTypes("ONBOARDING", [
  "GET_ALL_ONBOARDINGS_REQUEST",
  "GET_ALL_ONBOARDINGS_SUCCESS",
  "GET_ALL_ONBOARDINGS_FAIL",
  "CREATE_ONBOARDING_REQUEST",
  "CREATE_ONBOARDING_SUCCESS",
  "CREATE_ONBOARDING_FAIL",
  "UPDATE_ONBOARDING_REQUEST",
  "UPDATE_ONBOARDING_SUCCESS",
  "UPDATE_ONBOARDING_FAIL",
  "DELETE_ONBOARDING_REQUEST",
  "DELETE_ONBOARDING_SUCCESS",
  "DELETE_ONBOARDING_FAIL",

  // ----------------------------
  "SCHEDULE_ONBOARDING_REQUEST",
  "SCHEDULE_ONBOARDING_SUCCESS",
  "SCHEDULE_ONBOARDING_FAIL",
  "RESET_ONBOARDING",

  //---------------------------
  "SUBMIT_EVALUATION_FORM_REQUEST",
  "SUBMIT_EVALUATION_FORM_SUCCESS",
  "SUBMIT_EVALUATION_FORM_FAIL",
  "GET_INTERVIEWER_REQUEST",
  "GET_INTERVIEWER_SUCCESS",
  "GET_INTERVIEWER_FAIL",

  // get all assigned interview
  "GET_ALL_ASSIGNED_INTERVIEW_REQUEST",
  "GET_ALL_ASSIGNED_INTERVIEW_SUCCESS",
  "GET_ALL_ASSIGNED_INTERVIEW_FAIL",

  // get specific assigned interview
  "GET_SPECIFIC_ASSIGNED_INTERVIEW_REQUEST",
  "GET_SPECIFIC_ASSIGNED_INTERVIEW_SUCCESS",
  "GET_SPECIFIC_ASSIGNED_INTERVIEW_FAIL",

  // CANCEL_INTERVIEW_REQUEST
  "CANCEL_INTERVIEW_REQUEST",
  "CANCEL_INTERVIEW_SUCCESS",
  "CANCEL_INTERVIEW_FAIL",

  // "REJECT APPLICANT"
  "REJECT_APPLICANT_REQUEST",
  "REJECT_APPLICANT_SUCCESS",
  "REJECT_APPLICANT_FAIL",

  "UPLOAD_FILE_REQUEST",
  "UPLOAD_FILE_SUCCESS",
  "UPLOAD_FILE_FAIL",

  "SEND_OFFER_LETTER_REQUEST",
  "SEND_OFFER_LETTER_SUCCESS",
  "SEND_OFFER_LETTER_FAIL",

  "RESET_ACCEPT_REJECT_STATE",

  "OFFER_LETTER_RESPONSE_REQUEST",
  "OFFER_LETTER_RESPONSE_SUCCESS",
  "OFFER_LETTER_RESPONSE_FAIL"
])
