import { MEETINGS_TYPES } from "./types"

export const getAllMeetingsRequest = (data) => ({
  type: MEETINGS_TYPES.GET_ALL_MEETINGS_REQUEST,
  payload: data
})

export const getAllMeetingsSuccess = (data) => ({
  type: MEETINGS_TYPES.GET_ALL_MEETINGS_SUCCESS,
  payload: data
})

export const getAllMeetingsFailure = (data) => ({
  type: MEETINGS_TYPES.GET_ALL_MEETINGS_FAIL,
  payload: data
})

export const createZoomMeetingRequest = (data) => ({
  type: MEETINGS_TYPES.CREATE_ZOOM_MEETING_REQUEST,
  payload: data
})

export const createZoomMeetingSuccess = (data) => ({
  type: MEETINGS_TYPES.CREATE_ZOOM_MEETING_SUCCESS,
  payload: data
})

export const createZoomMeetingFailure = (data) => ({
  type: MEETINGS_TYPES.CREATE_ZOOM_MEETING_FAIL,
  payload: data
})

export const updateMeetingRequest = (data) => ({
  type: MEETINGS_TYPES.UPDATE_MEETING_REQUEST,
  payload: data
})

export const updateMeetingSuccess = (data) => ({
  type: MEETINGS_TYPES.UPDATE_MEETING_SUCCESS,
  payload: data
})

export const updateMeetingFailure = (data) => ({
  type: MEETINGS_TYPES.UPDATE_MEETING_FAIL,
  payload: data
})

export const deleteMeetingRequest = (data) => ({
  type: MEETINGS_TYPES.DELETE_MEETING_REQUEST,
  payload: data
})

export const deleteMeetingSuccess = (data) => ({
  type: MEETINGS_TYPES.DELETE_MEETING_SUCCESS,
  payload: data
})

export const deleteMeetingFailure = (data) => ({
  type: MEETINGS_TYPES.DELETE_MEETING_FAIL,
  payload: data
})

export const selectEvent = (data) => ({
  type: MEETINGS_TYPES.SELECT_EVENT,
  payload: data
})

export const removeEvent = (data) => ({
  type: MEETINGS_TYPES.REMOVE_EVENT,
  payload: data
})

export const filterMeetings = (data) => ({
  type: MEETINGS_TYPES.FILTER_MEETINGS,
  payload: data
})

export const resetError = (data) => ({
  type: MEETINGS_TYPES.RESET_ERROR,
  payload: data
})

export const teamsClientConnectRequest = (data) => ({
  type: MEETINGS_TYPES.TEAMS_CLIENT_CONNECT_REQUEST,
  payload: data
})

export const teamsClientConnectSuccess = (data) => ({
  type: MEETINGS_TYPES.TEAMS_CLIENT_CONNECT_SUCCESS,
  payload: data
})

export const teamsClientConnectFailure = (data) => ({
  type: MEETINGS_TYPES.TEAMS_CLIENT_CONNECT_FAIL,
  payload: data
})

export const createTeamsMeetingRequest = (data) => ({
  type: MEETINGS_TYPES.CREATE_TEAMS_MEETING_REQUEST,
  payload: data
})

export const createTeamsMeetingSuccess = (data) => ({
  type: MEETINGS_TYPES.CREATE_TEAMS_MEETING_SUCCESS,
  payload: data
})

export const createTeamsMeetingFailure = (data) => ({
  type: MEETINGS_TYPES.CREATE_TEAMS_MEETING_FAIL,
  payload: data
})
