import { GROUP_MANAGEMENT_TYPES } from "./types"

export const getAllGroupsRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_REQUEST,
  payload: data
})

export const getAllGroupsSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_SUCCESS,
  payload: data
})

export const getAllGroupsFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_FAIL,
  payload: data
})

// GET SPECIFIC GROUP
export const getGroupRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_GROUP_REQUEST,
  payload: data
})

export const getGroupSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_GROUP_SUCCESS,
  payload: data
})

export const getGroupFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_GROUP_FAIL,
  payload: data
})

// GET SPECIFIC MODULE INSIDE GROUP
export const getModuleRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_MODULE_REQUEST,
  payload: data
})

export const getModuleSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_MODULE_SUCCESS,
  payload: data
})

export const getModuleFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_MODULE_FAIL,
  payload: data
})

// UPDATE SPECIFIC MODULE STATUS INSIDE GROUP
export const updateModuleStatusRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.UPDATE_MODULE_STATUS_REQUEST,
  payload: data
})

export const updateModuleStatusSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.UPDATE_MODULE_STATUS_SUCCESS,

  payload: data
})

export const updateModuleStatusFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.UPDATE_MODULE_STATUS_FAIL,
  payload: data
})

// GET SPECIFIC FEATURE INSIDE MODULE INSIDE GROUP
export const getFeatureRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_FEATURE_REQUEST,
  payload: data
})

export const getFeatureSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_FEATURE_SUCCESS,
  payload: data
})

export const getFeatureFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_FEATURE_FAIL,
  payload: data
})

// UPDATE SPECIFIC FEATURE STATUS INSIDE MODULE INSIDE GROUP
export const updateFeatureStatusRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.UPDATE_FEATURE_STATUS_REQUEST,
  payload: data
})

export const updateFeatureStatusSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.UPDATE_FEATURE_STATUS_SUCCESS,
  payload: data
})

export const updateFeatureStatusFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.UPDATE_FEATURE_STATUS_FAIL,
  payload: data
})

export const getAllGroupsNameRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_NAME_REQUEST,
  payload: data
})

export const getAllGroupsNameSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_NAME_SUCCESS,
  payload: data
})

export const getAllGroupsNameFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_NAME_FAIL,
  payload: data
})

export const createUserRequest = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.CREATE_USER_REQUEST,
  payload: data
})

export const createUserSuccess = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.CREATE_USER_SUCCESS,
  payload: data
})

export const createUserFailure = (data) => ({
  type: GROUP_MANAGEMENT_TYPES.CREATE_USER_FAIL,
  payload: data
})
