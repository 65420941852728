import { JOBS_ACTION_TYPES } from "./types"
import { toast } from "react-hot-toast"

const initialState = {
  jobs: [],
  job: {},
  appliedJobs: {},
  appliedJob: {},
  apply: "",
  error: null,
  loading: false,
  loadingforSpecificJob: false,
  loadingforApply: false,
  loadingforJobPost: false,
  result: null,
  generatedDescription: null,
  loadingforGenerating: false,
  applicantsInfo: null,
  loadingForStatusChange: false,
  exchangeAccessToken: null,
  translateLoading: false,
  genSkillTestLoading: false,
  assessmentSubmitted: false,
  assessmentLoading: false,
  exportLoading: false,
  exportData: {},
  applicantsAllAppliedJobs: [],
  applicantsSpecificAppliedJob: {},
  specificAppliedJobLoading: false
}

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOBS_ACTION_TYPES.GET_ALL_JOBS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case JOBS_ACTION_TYPES.GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
        loading: false
      }
    case JOBS_ACTION_TYPES.GET_ALL_JOBS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case JOBS_ACTION_TYPES.CLEAR_SPECIFIC_JOB:
      return {
        ...state,
        job: {}
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_JOB_REQUEST:
      return {
        ...state,
        loadingforSpecificJob: true
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_JOB_SUCCESS:
      return {
        ...state,
        job: action.payload,
        loadingforSpecificJob: false
      }
    case JOBS_ACTION_TYPES.GET_SPECIFIC_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingforSpecificJob: false
      }

    case JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_SUCCESS:
      return {
        ...state,
        appliedJobs: action.payload,
        loading: false
      }
    case JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_REQUEST:
      return {
        ...state,
        appliedJob: {},
        loadingforSpecificJob: true
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_SUCCESS:
      return {
        ...state,
        appliedJob: action.payload,
        loadingforSpecificJob: false
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingforSpecificJob: false
      }

    case JOBS_ACTION_TYPES.APPLY_FOR_JOB_REQUEST:
      return {
        ...state,
        loadingforApply: true
      }

    case JOBS_ACTION_TYPES.APPLY_FOR_JOB_SUCCESS:
      return {
        ...state,
        apply: action.payload,
        loadingforApply: false
      }

    case JOBS_ACTION_TYPES.APPLY_FOR_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingforApply: false
      }

    case JOBS_ACTION_TYPES.RESET_APPLY_FOR_JOB:
      return {
        ...state,
        apply: action.payload,
        loadingforApply: false
      }

    case JOBS_ACTION_TYPES.CLEAR_SPECIFIC_APPLIED_JOB:
      return {
        ...state,
        appliedJob: {},
        loadingforSpecificJob: false,
        error: null
      }
    case JOBS_ACTION_TYPES.CREATE_JOB_REQUEST:
      return {
        ...state,
        loadingforJobPost: true,
        result: null
      }
    case JOBS_ACTION_TYPES.CREATE_JOB_SUCCESS:
      localStorage.setItem(
        "currCreateJobPostLimit",
        action.payload?.current
          ? action.payload?.current
          : localStorage.getItem("currCreateJobPostLimit") > 0
          ? localStorage.getItem("currCreateJobPostLimit") - 1
          : 0
      )

      return {
        ...state,
        result: 1,
        generatedDescription: null,
        loadingforJobPost: false
      }

    case JOBS_ACTION_TYPES.CREATE_JOB_FAIL:
      return {
        ...state,
        loadingforJobPost: false,
        result: 2
      }

    case JOBS_ACTION_TYPES.RESET_CREATE_JOB:
      return {
        ...state,
        loadingforJobPost: false,
        result: null
      }

    case JOBS_ACTION_TYPES.RESET_GET_ALL_JOBS:
      return {
        ...state,
        jobs: [],
        loading: false,
        error: null
      }

    case JOBS_ACTION_TYPES.AUTO_GENERATE_JOB_DESCRIPTION_REQUEST:
      return {
        ...state,
        loadingforGenerating: true,
        generatedDescription: null
      }
    case JOBS_ACTION_TYPES.AUTO_GENERATE_JOB_DESCRIPTION_SUCCESS:
      localStorage.setItem(
        "currAiGenLimit",
        action.payload?.apiCallLimit?.current
          ? action.payload?.apiCallLimit?.current
          : localStorage.getItem("currAiGenLimit") > 0
          ? localStorage.getItem("currAiGenLimit") - 1
          : 0
      )

      return {
        ...state,
        generatedDescription: action.payload,
        loadingforGenerating: false
      }
    case JOBS_ACTION_TYPES.AUTO_GENERATE_JOB_DESCRIPTION_FAIL:
      if (
        action.payload?.message ===
        "Unauthorized. Access token is missing, invalid, audience is incorrect (https://cognitiveservices.azure.com), or have expired."
      ) {
        toast.error("Something went wrong. Please try again later")
      } else {
        toast.error("Your AI limit has been exceeded")
      }
      return {
        ...state,
        loadingforGenerating: false,
        generatedDescription: null
      }

    case JOBS_ACTION_TYPES.RESET_AUTO_GENERATE_DESCRIPTION:
      return {
        ...state,
        loadingforGenerating: false,
        generatedDescription: null
      }

    case JOBS_ACTION_TYPES.APPLICANTS_INFO_SUCCESS:
      return {
        ...state,
        applicantsInfo: action.payload,
        loading: false
      }
    case JOBS_ACTION_TYPES.APPLICANTS_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case JOBS_ACTION_TYPES.APPLICANTS_INFO_REQUEST:
      return {
        ...state,
        loading: true
      }

    case JOBS_ACTION_TYPES.CLEAR_JOB_DESCRIPTION:
      return {
        ...state,
        generatedDescription: null
      }

    case JOBS_ACTION_TYPES.CHANGE_JOB_STATUS_SUCCESS:
      const updatedJobs = state.jobs.docs.map((job) => {
        if (job._id === action.payload?.job_id?.params) {
          return { ...job, job_status: action.payload?.job_id?.body?.status }
        }
        return job
      })
      const updatedJob = { ...state.job }
      if (updatedJob._id === action.payload?.job_id?.params) {
        updatedJob.job_status = action.payload?.job_id?.body?.status
      }

      toast.success("Job status changed successfully")

      localStorage.setItem(
        "currCreateJobPostLimit",
        action.payload?.remaining_calls?.current
          ? action.payload?.remaining_calls?.current
          : localStorage.getItem("currCreateJobPostLimit") > 0
          ? localStorage.getItem("currCreateJobPostLimit") - 1
          : 0
      )

      return {
        ...state,
        loadingForStatusChange: false,
        jobs: { ...state.jobs, docs: updatedJobs },
        job: updatedJob
      }

    case JOBS_ACTION_TYPES.CHANGE_JOB_STATUS_FAIL:
      toast.error("Job status change failed")
      return {
        ...state,
        loadingForStatusChange: false
      }

    case JOBS_ACTION_TYPES.CHANGE_JOB_STATUS_REQUEST:
      return {
        ...state,
        loadingForStatusChange: true
      }

    case JOBS_ACTION_TYPES.LINKEDIN_JOB_POST_SUCCESS:
      toast.success("Job Successfully Posted on LinkedIn")

      // CHANGE FIELD in Job
      return {
        ...state,
        loadingforJobPost: false,
        job: {
          ...state.job,
          posted_on_linkedin: true
        }
      }

    case JOBS_ACTION_TYPES.LINKEDIN_JOB_POST_FAIL:
      toast.error("Job posting failed")
      return {
        ...state,
        loadingforJobPost: false
      }

    case JOBS_ACTION_TYPES.LINKEDIN_JOB_POST_REQUEST:
      return {
        ...state,
        loadingforJobPost: true
      }

    case JOBS_ACTION_TYPES.TRANSLATE_JOB_DESCRIPTION_SUCCESS:
      toast.success("Job Description Translated Successfully")
      return {
        ...state,
        translateLoading: false,
        job: {
          ...state.job,
          job_description: action.payload
        }
      }

    case JOBS_ACTION_TYPES.TRANSLATE_JOB_DESCRIPTION_FAIL:
      toast.error("Job Description Translation Failed")
      return {
        ...state,
        translateLoading: false
      }

    case JOBS_ACTION_TYPES.TRANSLATE_JOB_DESCRIPTION_REQUEST:
      return {
        ...state,
        translateLoading: true
      }

    case JOBS_ACTION_TYPES.GENERATE_SKILL_TEST_SUCCESS:
      toast.success("Skill Test Generated Successfully")
      return {
        ...state,
        genSkillTestLoading: false,
        applicantsSpecificAppliedJob: {
          ...state.applicantsSpecificAppliedJob,
          is_assessment_taken:
            Array.isArray(action.payload) && action.payload.length !== 0
              ? "not_taken"
              : state.applicantsSpecificAppliedJob.is_assessment_taken
        }
      }

    case JOBS_ACTION_TYPES.GENERATE_SKILL_TEST_FAIL:
      toast.error("Skill Test Generation Failed")
      return {
        ...state,
        genSkillTestLoading: false
      }

    case JOBS_ACTION_TYPES.GENERATE_SKILL_TEST_REQUEST:
      return {
        ...state,
        genSkillTestLoading: true
      }

    case JOBS_ACTION_TYPES.SUBMIT_SKILL_ASSESSMENT_SUCCESS:
      toast.success("Skill Assessment Submitted Successfully")
      return {
        ...state,
        assessmentSubmitted: true,
        assessmentLoading: false
      }

    case JOBS_ACTION_TYPES.SUBMIT_SKILL_ASSESSMENT_FAIL:
      toast.error("Skill Assessment Submission Failed")
      return {
        ...state,
        assessmentSubmitted: false,
        assessmentLoading: false
      }

    case JOBS_ACTION_TYPES.SUBMIT_SKILL_ASSESSMENT_REQUEST:
      return {
        ...state,
        assessmentSubmitted: false,
        assessmentLoading: true
      }

    case JOBS_ACTION_TYPES.ALL_JOBS_EXPORT_REQUEST:
      return {
        ...state,
        exportLoading: true
      }

    case JOBS_ACTION_TYPES.ALL_JOBS_EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        exportData: action.payload
      }

    case JOBS_ACTION_TYPES.ALL_JOBS_EXPORT_FAIL:
      return {
        ...state,
        exportLoading: false
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_REQUEST:
      return {
        ...state,
        applicantsAllAppliedJobs: []
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_SUCCESS:
      return {
        ...state,
        applicantsAllAppliedJobs: action.payload
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_REQUEST:
      if (action.payload?.params === state.applicantsSpecificAppliedJob?._id) {
        return {
          ...state,
          specificAppliedJobLoading: true
        }
      } else {
        return {
          ...state,
          applicantsSpecificAppliedJob: {},
          specificAppliedJobLoading: true
        }
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_SUCCESS:
      return {
        ...state,
        applicantsSpecificAppliedJob: action.payload,
        specificAppliedJobLoading: false
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_FAIL:
      return {
        ...state,
        error: action.payload,
        specificAppliedJobLoading: false
      }

    case JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true
      }

    case JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_APPLICATION_SUCCESS:
      return {
        ...state,
        appliedJobs: action.payload,
        loading: false
      }

    case JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_APPLICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // "GET_SPECIFIC_APPLIED_JOB_APPLICATION_REQUEST",
    // "GET_SPECIFIC_APPLIED_JOB_APPLICATION_SUCCESS",
    // "GET_SPECIFIC_APPLIED_JOB_APPLICATION_FAIL",

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_APPLICATION_REQUEST:
      return {
        ...state,
        loadingforSpecificJob: true,
        offerletterResponseLoading: false,
        offerletterResponseSuccess: false
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_APPLICATION_SUCCESS:
      return {
        ...state,
        appliedJob: action.payload,
        loadingforSpecificJob: false
      }

    case JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_APPLICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingforSpecificJob: false
      }

    //  "GET_ALL_APPLIED_JOBS_APPLICATION_REQUEST",
    // "GET_ALL_APPLIED_JOBS_APPLICATION_SUCCESS",
    // "GET_ALL_APPLIED_JOBS_APPLICATION_FAIL"

    default:
      return state
  }
}

export default jobsReducer
