import * as skillActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createSkill,
  deleteSkill,
  getAllSkills,
  updateSkill
} from "@src/services/apis"

import { SKILL_TYPES } from "./types"

function* getAllSkillsRequest(action) {
  try {
    const response = yield call(getAllSkills, action.payload)
    yield put(skillActions.getAllSkillsSuccess(response.data))
  } catch (err) {
    yield put(skillActions.getAllSkillsFailure(err))
  }
}

function* createSkillRequest(action) {
  try {
    const response = yield call(createSkill, action.payload)
    yield put(skillActions.createSkillSuccess(response?.remaining_calls))
  } catch (err) {
    yield put(skillActions.createSkillFailure(err))
  }
}

function* updateSkillRequest(action) {
  try {
    const response = yield call(updateSkill, action.payload)
    yield put(skillActions.updateSkillSuccess(response.data))
  } catch (err) {
    yield put(skillActions.updateSkillFailure(err))
  }
}

function* deleteSkillRequest(action) {
  try {
    const response = yield call(deleteSkill, action.payload)
    yield put(skillActions.deleteSkillSuccess(response?.remaining_calls))
  } catch (err) {
    yield put(skillActions.deleteSkillFailure(err))
  }
}

export default function* skillSaga() {
  yield all([
    takeEvery(SKILL_TYPES.GET_ALL_SKILLS_REQUEST, getAllSkillsRequest),
    takeEvery(SKILL_TYPES.CREATE_SKILL_REQUEST, createSkillRequest),
    takeEvery(SKILL_TYPES.UPDATE_SKILL_REQUEST, updateSkillRequest),
    takeEvery(SKILL_TYPES.DELETE_SKILL_REQUEST, deleteSkillRequest)
  ])
}
