import { createActionTypes } from "@src/utility"

export const SEEKER_PROFILE_TYPES = createActionTypes("SEEKER_PROFILE_TYPES", [
  // GET
  "GET_PROFILE_REQUEST",
  "GET_PROFILE_SUCCESS",
  "GET_PROFILE_FAILURE",

  // PATCH
  "PATCH_PROFILE_REQUEST",
  "PATCH_PROFILE_SUCCESS",
  "PATCH_PROFILE_FAILURE",

  "GET_EDUCATION_REQUEST",
  "GET_EDUCATION_SUCCESS",
  "GET_EDUCATION_FAILURE",

  "GET_EXPERIENCE_REQUEST",
  "GET_EXPERIENCE_SUCCESS",
  "GET_EXPERIENCE_FAILURE",

  "GET_SKILLS_REQUEST",
  "GET_SKILLS_SUCCESS",
  "GET_SKILLS_FAILURE",

  "GET_LANGUAGES_REQUEST",
  "GET_LANGUAGES_SUCCESS",
  "GET_LANGUAGES_FAILURE",

  // POST
  "POST_EDUCATION_REQUEST",
  "POST_EDUCATION_SUCCESS",
  "POST_EDUCATION_FAILURE",

  "POST_EXPERIENCE_REQUEST",
  "POST_EXPERIENCE_SUCCESS",
  "POST_EXPERIENCE_FAILURE",

  "POST_SKILLS_REQUEST",
  "POST_SKILLS_SUCCESS",
  "POST_SKILLS_FAILURE",

  "POST_LANGUAGES_REQUEST",
  "POST_LANGUAGES_SUCCESS",
  "POST_LANGUAGES_FAILURE",

  // PATCH
  "PATCH_EDUCATION_REQUEST",
  "PATCH_EDUCATION_SUCCESS",
  "PATCH_EDUCATION_FAILURE",

  "PATCH_EXPERIENCE_REQUEST",
  "PATCH_EXPERIENCE_SUCCESS",
  "PATCH_EXPERIENCE_FAILURE",

  "PATCH_SKILLS_REQUEST",
  "PATCH_SKILLS_SUCCESS",
  "PATCH_SKILLS_FAILURE",

  "PATCH_LANGUAGES_REQUEST",
  "PATCH_LANGUAGES_SUCCESS",
  "PATCH_LANGUAGES_FAILURE",

  // DELETE
  "DELETE_EDUCATION_REQUEST",
  "DELETE_EDUCATION_SUCCESS",
  "DELETE_EDUCATION_FAILURE",

  "DELETE_EXPERIENCE_REQUEST",
  "DELETE_EXPERIENCE_SUCCESS",
  "DELETE_EXPERIENCE_FAILURE",

  "DELETE_PROFILE_SKILLS_REQUEST",
  "DELETE_PROFILE_SKILLS_SUCCESS",
  "DELETE_PROFILE_SKILLS_FAILURE",

  "DELETE_PROFILE_LANGUAGES_REQUEST",
  "DELETE_PROFILE_LANGUAGES_SUCCESS",
  "DELETE_PROFILE_LANGUAGES_FAILURE",

  // clear
  "CLEAR_PROFILE",
  "CLEAR_RESPONSE",

  // DELETE SKILLS
  "DELETE_SKILLS_REQUEST",
  "DELETE_SKILLS_SUCCESS",
  "DELETE_SKILLS_FAILURE"
])
