import { ASSESSMENT_TYPES } from "./types"

const initialState = {
  assessment: [],
  error: null,
  deleted: false,
  updated: false,
  added: false,
  createdUpdatedLoading: false,
  assessmentLoading: false,
  generatedQuestions: [],
  testCreated: false,
  testLoading: false,
  testAvailable: {},
  profileAssessmentQuestions: {},
  profileAssessmentLoading: false,
  profileAssessmentFetched: false,
  assessmentSubmitted: false,
  assessmentSubmitLoading: false,
  viewInterviewTestLoading: false,
  interviewTest: {},
  interviewTestFetched: false
}

const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSESSMENT_TYPES.GET_ALL_ASSESSMENT_REQUEST:
      return {
        ...state,
        updated: false,
        deleted: false,
        added: false,
        assessmentLoading: true
      }
    case ASSESSMENT_TYPES.GET_ALL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessment: action.payload,
        assessmentLoading: false
      }
    case ASSESSMENT_TYPES.GET_ALL_ASSESSMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        assessmentLoading: false
      }
    case ASSESSMENT_TYPES.CREATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        added: false,
        createdUpdatedLoading: true
      }
    case ASSESSMENT_TYPES.CREATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        added: true,
        createdUpdatedLoading: false
      }
    case ASSESSMENT_TYPES.CREATE_ASSESSMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false
      }
    case ASSESSMENT_TYPES.UPDATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        updated: false
      }
    case ASSESSMENT_TYPES.UPDATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        updated: true,
        createdUpdatedLoading: false
      }
    case ASSESSMENT_TYPES.UPDATE_ASSESSMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false
      }
    case ASSESSMENT_TYPES.RESET_STATUS:
      return {
        ...state,
        updated: false,
        deleted: false,
        added: false,
        createdUpdatedLoading: false,
        genLoading: false,
        generated: false,
        testCreated: false,
        testLoading: false,
        generatedQuestions: [],
        profileAssessmentLoading: false,
        profileAssessmentFetched: false,
        assessmentSubmitted: false,
        assessmentSubmitLoading: false
      }
    case ASSESSMENT_TYPES.CREATE_ASSESSMENT_WITH_AI_REQUEST:
      return {
        ...state,
        generated: false,
        genLoading: true,
        generatedQuestions: []
      }
    case ASSESSMENT_TYPES.CREATE_ASSESSMENT_WITH_AI_SUCCESS:
      localStorage.setItem(
        "currGenAIAssessmentLimit",
        action.payload?.remaining_calls?.current
          ? action.payload?.remaining_calls?.current
          : localStorage.getItem("currGenAIAssessmentLimit") > 0
          ? localStorage.getItem("currGenAIAssessmentLimit") - 1
          : 0
      )

      return {
        ...state,
        generated: true,
        genLoading: false,
        generatedQuestions: action.payload?.result
      }
    case ASSESSMENT_TYPES.CREATE_ASSESSMENT_WITH_AI_FAIL:
      return {
        ...state,
        error: action.payload,
        generated: false,
        genLoading: false,
        generatedQuestions: []
      }
    case ASSESSMENT_TYPES.DELETE_ASSESSMENT_REQUEST:
      return {
        ...state,
        deleted: false
      }
    case ASSESSMENT_TYPES.DELETE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        deleted: true
      }
    case ASSESSMENT_TYPES.DELETE_ASSESSMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        deleted: false
      }
    case ASSESSMENT_TYPES.CREATE_TECHNICAL_TEST_REQUEST:
      return {
        ...state,
        testLoading: true,
        testCreated: false
      }
    case ASSESSMENT_TYPES.CREATE_TECHNICAL_TEST_SUCCESS:
      return {
        ...state,
        testLoading: false,
        testCreated: true,
        testAvailable: {
          ...state.testAvailable,
          assessmentButtonVisibility: true
        }
        // testAvailable\?\.assessmentButtonVisibility
      }
    case ASSESSMENT_TYPES.CREATE_TECHNICAL_TEST_FAILURE:
      return {
        ...state,
        testLoading: false,
        testCreated: false
      }
    case ASSESSMENT_TYPES.IS_INTERVIEW_TEST_AVAILABLE_REQUEST:
      return {
        ...state,
        testAvailable: {}
      }
    case ASSESSMENT_TYPES.IS_INTERVIEW_TEST_AVAILABLE_SUCCESS:
      return {
        ...state,
        testAvailable: action.payload
      }
    case ASSESSMENT_TYPES.IS_INTERVIEW_TEST_AVAILABLE_FAILURE:
      return {
        ...state,
        testAvailable: {}
      }
    case ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_REQUEST:
      return {
        ...state,
        profileAssessmentLoading: true,
        profileAssessmentQuestions: {},
        profileAssessmentFetched: false
      }
    case ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        profileAssessmentQuestions: action.payload,
        profileAssessmentLoading: false,
        profileAssessmentFetched: true
      }
    case ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_FAILURE:
      return {
        ...state,
        profileAssessmentQuestions: {},
        profileAssessmentLoading: false,
        profileAssessmentFetched: false
      }

    case ASSESSMENT_TYPES.POST_PROFILE_ASSESSMENT_REQUEST:
      return {
        ...state,
        assessmentSubmitted: false,
        assessmentSubmitLoading: true
      }

    case ASSESSMENT_TYPES.POST_PROFILE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessmentSubmitted: true,
        assessmentSubmitLoading: false
      }

    case ASSESSMENT_TYPES.POST_PROFILE_ASSESSMENT_FAILURE:
      return {
        ...state,
        assessmentSubmitted: false,
        assessmentSubmitLoading: false
      }

    case ASSESSMENT_TYPES.VIEW_INTERVIEW_TEST_REQUEST:
      return {
        ...state,
        profileAssessmentLoading: true,
        profileAssessmentQuestions: {},
        profileAssessmentFetched: false
      }

    case ASSESSMENT_TYPES.VIEW_INTERVIEW_TEST_SUCCESS:
      return {
        ...state,
        profileAssessmentQuestions: action.payload,
        profileAssessmentLoading: false,
        profileAssessmentFetched: true
      }

    case ASSESSMENT_TYPES.VIEW_INTERVIEW_TEST_FAILURE:
      return {
        ...state,
        profileAssessmentQuestions: {},
        profileAssessmentLoading: false,
        profileAssessmentFetched: false
      }

    case ASSESSMENT_TYPES.SUBMIT_INTERVIEW_TEST_REQUEST:
      return {
        ...state,
        assessmentSubmitted: false,
        assessmentSubmitLoading: true
      }

    case ASSESSMENT_TYPES.SUBMIT_INTERVIEW_TEST_SUCCESS:
      return {
        ...state,
        assessmentSubmitted: true,
        assessmentSubmitLoading: false
      }

    case ASSESSMENT_TYPES.SUBMIT_INTERVIEW_TEST_FAILURE:
      return {
        ...state,
        assessmentSubmitted: false,
        assessmentSubmitLoading: false
      }

    default:
      return state
  }
}

export default assessmentReducer
