import { JOBS_ACTION_TYPES } from "./types"

export const getAllJobsRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_JOBS_REQUEST,
  payload: data
})

export const getAllJobsSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_JOBS_SUCCESS,
  payload: data
})

export const getAllJobsFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_JOBS_FAIL,
  payload: data
})

export const clearSpecificJob = () => ({
  type: JOBS_ACTION_TYPES.CLEAR_SPECIFIC_JOB
})

export const getSpecificJobRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_JOB_REQUEST,
  payload: data
})

export const getSpecificJobSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_JOB_SUCCESS,
  payload: data
})

export const getSpecificJobFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_JOB_FAIL,
  payload: data
})

export const applyForJobRequest = (data) => ({
  type: JOBS_ACTION_TYPES.APPLY_FOR_JOB_REQUEST,
  payload: data
})

export const applyForJobSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.APPLY_FOR_JOB_SUCCESS,
  payload: data
})

export const applyForJobFailure = (data) => ({
  type: JOBS_ACTION_TYPES.APPLY_FOR_JOB_FAIL,
  payload: data
})

export const saveJobAsDraftRequest = (data) => ({
  type: JOBS_ACTION_TYPES.SAVE_JOB_AS_DRAFT_REQUEST,
  payload: data
})

export const saveJobAsDraftSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.SAVE_JOB_AS_DRAFT_SUCCESS,
  payload: data
})

export const saveJobAsDraftFailure = (data) => ({
  type: JOBS_ACTION_TYPES.SAVE_JOB_AS_DRAFT_FAIL,
  payload: data
})

export const getAllAppliedJobsRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_REQUEST,
  payload: data
})

export const getAllAppliedJobsSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_SUCCESS,
  payload: data
})

export const getAllAppliedJobsFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_FAIL,
  payload: data
})

export const getSpecificAppliedJobRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_REQUEST,
  payload: data
})

export const getSpecificAppliedJobSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_SUCCESS,
  payload: data
})

export const getSpecificAppliedJobFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_FAIL,
  payload: data
})

export const resetApplyForJob = () => ({
  type: JOBS_ACTION_TYPES.RESET_APPLY_FOR_JOB
})

export const clearSpecificAppliedJob = () => ({
  type: JOBS_ACTION_TYPES.CLEAR_SPECIFIC_APPLIED_JOB
})

export const createJobRequest = (data) => ({
  type: JOBS_ACTION_TYPES.CREATE_JOB_REQUEST,
  payload: data
})

export const createJobSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.CREATE_JOB_SUCCESS,
  payload: data
})

export const createJobFailure = (data) => ({
  type: JOBS_ACTION_TYPES.CREATE_JOB_FAIL,
  payload: data
})

export const resetCreateJob = () => ({
  type: JOBS_ACTION_TYPES.RESET_CREATE_JOB
})

export const resetGetAllJobs = () => ({
  type: JOBS_ACTION_TYPES.RESET_GET_ALL_JOBS
})

export const autoGenerateJobDescriptionRequest = (data) => ({
  type: JOBS_ACTION_TYPES.AUTO_GENERATE_JOB_DESCRIPTION_REQUEST,
  payload: data
})

export const autoGenerateJobDescriptionSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.AUTO_GENERATE_JOB_DESCRIPTION_SUCCESS,
  payload: data
})

export const autoGenerateJobDescriptionFailure = (data) => ({
  type: JOBS_ACTION_TYPES.AUTO_GENERATE_JOB_DESCRIPTION_FAIL,
  payload: data
})

export const resetAutoGenerateJobDescription = () => ({
  type: JOBS_ACTION_TYPES.RESET_AUTO_GENERATE_JOB_DESCRIPTION
})

export const applicantsInfoRequest = () => ({
  type: JOBS_ACTION_TYPES.APPLICANTS_INFO_REQUEST
})

export const applicantsInfoSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.APPLICANTS_INFO_SUCCESS,
  payload: data
})

export const applicantsInfoFailure = (data) => ({
  type: JOBS_ACTION_TYPES.APPLICANTS_INFO_FAIL,
  payload: data
})

export const clearJobDescription = () => ({
  type: JOBS_ACTION_TYPES.CLEAR_JOB_DESCRIPTION
})

// "CHANGE_JOB_STATUS_REQUEST",
// "CHANGE_JOB_STATUS_SUCCESS",
// "CHANGE_JOB_STATUS_FAIL",

export const changeJobStatusRequest = (data) => ({
  type: JOBS_ACTION_TYPES.CHANGE_JOB_STATUS_REQUEST,
  payload: data
})

export const changeJobStatusSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.CHANGE_JOB_STATUS_SUCCESS,
  payload: data
})

export const changeJobStatusFailure = (data) => ({
  type: JOBS_ACTION_TYPES.CHANGE_JOB_STATUS_FAIL,
  payload: data
})

export const exchangeAccessTokenRequest = (data) => ({
  type: JOBS_ACTION_TYPES.EXCHANGE_ACCESS_TOKEN_REQUEST,
  payload: data
})

export const exchangeAccessTokenSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.EXCHANGE_ACCESS_TOKEN_SUCCESS,
  payload: data
})

export const exchangeAccessTokenFailure = (data) => ({
  type: JOBS_ACTION_TYPES.EXCHANGE_ACCESS_TOKEN_FAIL,
  payload: data
})

export const linkedInJobPostRequest = (data) => ({
  type: JOBS_ACTION_TYPES.LINKEDIN_JOB_POST_REQUEST,
  payload: data
})

export const linkedInJobPostSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.LINKEDIN_JOB_POST_SUCCESS,
  payload: data
})

export const linkedInJobPostFailure = (data) => ({
  type: JOBS_ACTION_TYPES.LINKEDIN_JOB_POST_FAIL,
  payload: data
})

export const translateJobDescriptionRequest = (data) => ({
  type: JOBS_ACTION_TYPES.TRANSLATE_JOB_DESCRIPTION_REQUEST,
  payload: data
})

export const translateJobDescriptionSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.TRANSLATE_JOB_DESCRIPTION_SUCCESS,
  payload: data
})

export const translateJobDescriptionFailure = (data) => ({
  type: JOBS_ACTION_TYPES.TRANSLATE_JOB_DESCRIPTION_FAIL,
  payload: data
})

export const generateSkillTestRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GENERATE_SKILL_TEST_REQUEST,
  payload: data
})

export const generateSkillTestSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GENERATE_SKILL_TEST_SUCCESS,
  payload: data
})

export const generateSkillTestFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GENERATE_SKILL_TEST_FAIL,
  payload: data
})

// "SUBMIT_SKILL_ASSESSMENT_REQUEST",
//   "SUBMIT_SKILL_ASSESSMENT_SUCCESS",
//   "SUBMIT_SKILL_ASSESSMENT_FAIL",

export const submitSkillAssessmentRequest = (data) => ({
  type: JOBS_ACTION_TYPES.SUBMIT_SKILL_ASSESSMENT_REQUEST,
  payload: data
})

export const submitSkillAssessmentSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.SUBMIT_SKILL_ASSESSMENT_SUCCESS,
  payload: data
})

export const submitSkillAssessmentFailure = (data) => ({
  type: JOBS_ACTION_TYPES.SUBMIT_SKILL_ASSESSMENT_FAIL,
  payload: data
})

export const allJobsExportRequest = (data) => ({
  type: JOBS_ACTION_TYPES.ALL_JOBS_EXPORT_REQUEST,
  payload: data
})

export const allJobsExportSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.ALL_JOBS_EXPORT_SUCCESS,
  payload: data
})

export const allJobsExportFailure = (data) => ({
  type: JOBS_ACTION_TYPES.ALL_JOBS_EXPORT_FAIL,
  payload: data
})

// GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_REQUEST

export const getSpecificApplicantAllAppliedJobsRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_REQUEST,
  payload: data
})

export const getSpecificApplicantAllAppliedJobsSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_SUCCESS,
  payload: data
})

export const getSpecificApplicantAllAppliedJobsFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_ALL_APPLIED_JOBS_FAIL,
  payload: data
})

export const getSpecificApplicantSpecificAppliedJobRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_REQUEST,
  payload: data
})

export const getSpecificApplicantSpecificAppliedJobSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_SUCCESS,
  payload: data
})

export const getSpecificApplicantSpecificAppliedJobFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLICANT_SPECIFIC_APPLIED_JOB_FAIL,
  payload: data
})

// FOR APPLICANTS
// "GET_ALL_APPLIED_JOBS_APPLICATION_REQUEST",
// "GET_ALL_APPLIED_JOBS_APPLICATION_SUCCESS",
// "GET_ALL_APPLIED_JOBS_APPLICATION_FAIL"

export const getAllAppliedJobsApplicationRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_APPLICATION_REQUEST,
  payload: data
})

export const getAllAppliedJobsApplicationSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_APPLICATION_SUCCESS,
  payload: data
})

export const getAllAppliedJobsApplicationFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_ALL_APPLIED_JOBS_APPLICATION_FAIL,
  payload: data
})

// "GET_SPECIFIC_APPLIED_JOB_APPLICATION_REQUEST",
// "GET_SPECIFIC_APPLIED_JOB_APPLICATION_SUCCESS",
// "GET_SPECIFIC_APPLIED_JOB_APPLICATION_FAIL",

export const getSpecificAppliedJobApplicationRequest = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_APPLICATION_REQUEST,
  payload: data
})

export const getSpecificAppliedJobApplicationSuccess = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_APPLICATION_SUCCESS,
  payload: data
})

export const getSpecificAppliedJobApplicationFailure = (data) => ({
  type: JOBS_ACTION_TYPES.GET_SPECIFIC_APPLIED_JOB_APPLICATION_FAIL,
  payload: data
})
