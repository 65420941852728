import { COMPANY_PROFILE_TYPES } from "./types"

export const getCompanyProfileRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_COMPANY_PROFILE_REQUEST,
  payload: data
})

export const getCompanyProfileSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_COMPANY_PROFILE_SUCCESS,
  payload: data
})

export const getCompanyProfileFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_COMPANY_PROFILE_FAILED,
  payload: data
})

export const updateCompanyProfileRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_COMPANY_PROFILE_REQUEST,
  payload: data
})

export const updateCompanyProfileSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_COMPANY_PROFILE_SUCCESS,
  payload: data
})

export const updateCompanyProfileFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_COMPANY_PROFILE_FAILED,
  payload: data
})

export const postCompanyProfileRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.POST_COMPANY_PROFILE_REQUEST,
  payload: data
})

export const postCompanyProfileSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.POST_COMPANY_PROFILE_SUCCESS,
  payload: data
})

export const postCompanyProfileFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.POST_COMPANY_PROFILE_FAILED,
  payload: data
})

export const getCompanyLocationsRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_COMPANY_LOCATIONS_REQUEST,
  payload: data
})

export const getCompanyLocationsSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_COMPANY_LOCATIONS_SUCCESS,
  payload: data
})

export const getCompanyLocationsFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_COMPANY_LOCATIONS_FAILED,
  payload: data
})

export const postCompanyLocationsRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.POST_COMPANY_LOCATIONS_REQUEST,
  payload: data
})

export const postCompanyLocationsSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.POST_COMPANY_LOCATIONS_SUCCESS,
  payload: data
})

export const postCompanyLocationsFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.POST_COMPANY_LOCATIONS_FAILED,
  payload: data
})

export const updateCompanyLocationsRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_COMPANY_LOCATIONS_REQUEST,
  payload: data
})

export const updateCompanyLocationsSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_COMPANY_LOCATIONS_SUCCESS,
  payload: data
})

export const updateCompanyLocationsFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_COMPANY_LOCATIONS_FAILED,
  payload: data
})

export const deleteCompanyLocationsRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_COMPANY_LOCATIONS_REQUEST,
  payload: data
})

export const deleteCompanyLocationsSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_COMPANY_LOCATIONS_SUCCESS,
  payload: data
})

export const deleteCompanyLocationsFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_COMPANY_LOCATIONS_FAILED,
  payload: data
})

export const clearLocationsResponse = () => ({
  type: COMPANY_PROFILE_TYPES.CLEAR_LOCATIONS_RESPONSE
})

// CLEAR_PROFILE_RESPONSE
export const clearProfileResponse = () => ({
  type: COMPANY_PROFILE_TYPES.CLEAR_PROFILE_RESPONSE
})

export const uploadCompanyLogoRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPLOAD_COMPANY_LOGO_REQUEST,
  payload: data
})

export const uploadCompanyLogoSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPLOAD_COMPANY_LOGO_SUCCESS,
  payload: data
})

export const uploadCompanyLogoFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPLOAD_COMPANY_LOGO_FAILED,
  payload: data
})

export const apiLimitRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.API_LIMIT_REQUEST,
  payload: data
})

export const apiLimitSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.API_LIMIT_SUCCESS,
  payload: data
})

export const apiLimitFailure = (data) => ({
  type: COMPANY_PROFILE_TYPES.API_LIMIT_FAILED,
  payload: data
})

export const zoomIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.ZOOM_INTEGRATION_REQUEST,
  payload: data
})

export const zoomIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.ZOOM_INTEGRATION_SUCCESS,
  payload: data
})

export const zoomIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.ZOOM_INTEGRATION_FAILED,
  payload: data
})

export const getZoomIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_ZOOM_INTEGRATION_REQUEST,
  payload: data
})

export const getZoomIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_ZOOM_INTEGRATION_SUCCESS,
  payload: data
})

export const getZoomIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_ZOOM_INTEGRATION_FAILED,
  payload: data
})

export const deleteZoomIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_ZOOM_INTEGRATION_REQUEST,
  payload: data
})

export const deleteZoomIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_ZOOM_INTEGRATION_SUCCESS,
  payload: data
})

export const deleteZoomIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_ZOOM_INTEGRATION_FAILED,
  payload: data
})

export const updateZoomIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_ZOOM_INTEGRATION_REQUEST,
  payload: data
})

export const updateZoomIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_ZOOM_INTEGRATION_SUCCESS,
  payload: data
})

export const updateZoomIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_ZOOM_INTEGRATION_FAILED,
  payload: data
})

export const linkedInIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.LINKEDIN_INTEGRATION_REQUEST,
  payload: data
})

export const linkedInIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.LINKEDIN_INTEGRATION_SUCCESS,
  payload: data
})

export const linkedInIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.LINKEDIN_INTEGRATION_FAILED,
  payload: data
})

export const getLinkedInIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_LINKEDIN_INTEGRATION_REQUEST,
  payload: data
})

export const getLinkedInIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_LINKEDIN_INTEGRATION_SUCCESS,
  payload: data
})

export const getLinkedInIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_LINKEDIN_INTEGRATION_FAILED,
  payload: data
})

export const updateLinkedInIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_LINKEDIN_INTEGRATION_REQUEST,
  payload: data
})

export const updateLinkedInIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_LINKEDIN_INTEGRATION_SUCCESS,
  payload: data
})

export const updateLinkedInIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_LINKEDIN_INTEGRATION_FAILED,
  payload: data
})

export const deleteLinkedInIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_LINKEDIN_INTEGRATION_REQUEST,
  payload: data
})

export const deleteLinkedInIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_LINKEDIN_INTEGRATION_SUCCESS,
  payload: data
})

export const deleteLinkedInIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_LINKEDIN_INTEGRATION_FAILED,
  payload: data
})

export const teamsIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.TEAMS_INTEGRATION_REQUEST,
  payload: data
})

export const teamsIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.TEAMS_INTEGRATION_SUCCESS,
  payload: data
})

export const teamsIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.TEAMS_INTEGRATION_FAILED,
  payload: data
})

export const getTeamsIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_TEAMS_INTEGRATION_REQUEST,
  payload: data
})

export const getTeamsIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_TEAMS_INTEGRATION_SUCCESS,
  payload: data
})

export const getTeamsIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.GET_TEAMS_INTEGRATION_FAILED,
  payload: data
})

export const deleteTeamsIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_TEAMS_INTEGRATION_REQUEST,
  payload: data
})

export const deleteTeamsIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_TEAMS_INTEGRATION_SUCCESS,
  payload: data
})

export const deleteTeamsIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.DELETE_TEAMS_INTEGRATION_FAILED,
  payload: data
})

export const updateTeamsIntegrationRequest = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_TEAMS_INTEGRATION_REQUEST,
  payload: data
})

export const updateTeamsIntegrationSuccess = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_TEAMS_INTEGRATION_SUCCESS,
  payload: data
})

export const updateTeamsIntegrationFailed = (data) => ({
  type: COMPANY_PROFILE_TYPES.UPDATE_TEAMS_INTEGRATION_FAILED,
  payload: data
})
