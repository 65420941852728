import { createActionTypes } from "@src/utility"

export const DASHBOARD = createActionTypes("DASHBOARD", [
  "GET_STATS_REQUEST",
  "GET_STATS_SUCCESS",
  "GET_STATS_FAIL",
  "GET_APPLICANTS_STATUS_REQUEST",
  "GET_APPLICANTS_STATUS_SUCCESS",
  "GET_APPLICANTS_STATUS_FAIL",
  "GET_APPLICANTS_REPORT_REQUEST",
  "GET_APPLICANTS_REPORT_SUCCESS",
  "GET_APPLICANTS_REPORT_FAIL"
])
