import { EVALUATION_TYPES } from "./types"

export const createEvaluationRequest = (data) => ({
  type: EVALUATION_TYPES.CREATE_EVALUATION_REQUEST,
  payload: data
})

export const createEvaluationSuccess = (data) => ({
  type: EVALUATION_TYPES.CREATE_EVALUATION_SUCCESS,
  payload: data
})

export const createEvaluationFailure = (data) => ({
  type: EVALUATION_TYPES.CREATE_EVALUATION_FAIL,
  payload: data
})

export const resetAddedStatus = () => ({
  type: EVALUATION_TYPES.RESET_ADDED_STATUS
})

export const getEvaluationRequest = (data) => ({
  type: EVALUATION_TYPES.GET_EVALUATION_REQUEST,
  payload: data
})

export const getEvaluationSuccess = (data) => ({
  type: EVALUATION_TYPES.GET_EVALUATION_SUCCESS,
  payload: data
})

export const getEvaluationFailure = (data) => ({
  type: EVALUATION_TYPES.GET_EVALUATION_FAIL,
  payload: data
})

export const updateEvaluationRequest = (data) => ({
  type: EVALUATION_TYPES.UPDATE_EVALUATION_REQUEST,
  payload: data
})

export const updateEvaluationSuccess = (data) => ({
  type: EVALUATION_TYPES.UPDATE_EVALUATION_SUCCESS,
  payload: data
})

export const updateEvaluationFailure = (data) => ({
  type: EVALUATION_TYPES.UPDATE_EVALUATION_FAIL,
  payload: data
})

export const deleteEvaluationRequest = (data) => ({
  type: EVALUATION_TYPES.DELETE_EVALUATION_REQUEST,
  payload: data
})

export const deleteEvaluationSuccess = (data) => ({
  type: EVALUATION_TYPES.DELETE_EVALUATION_SUCCESS,
  payload: data
})

export const deleteEvaluationFailure = (data) => ({
  type: EVALUATION_TYPES.DELETE_EVALUATION_FAIL,
  payload: data
})

export const getAllEvaluationRequest = (data) => ({
  type: EVALUATION_TYPES.GET_ALL_EVALUATION_REQUEST,
  payload: data
})

export const getAllEvaluationSuccess = (data) => ({
  type: EVALUATION_TYPES.GET_ALL_EVALUATION_SUCCESS,
  payload: data
})

export const getAllEvaluationFailure = (data) => ({
  type: EVALUATION_TYPES.GET_ALL_EVALUATION_FAIL,
  payload: data
})
