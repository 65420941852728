import { USERS_TYPES } from "./types"

export const getAllUsersRequest = (data) => ({
  type: USERS_TYPES.GET_ALL_USERS_REQUEST,
  payload: data
})

export const getAllUsersSuccess = (data) => ({
  type: USERS_TYPES.GET_ALL_USERS_SUCCESS,
  payload: data
})

export const getAllUsersFailure = (data) => ({
  type: USERS_TYPES.GET_ALL_USERS_FAIL,
  payload: data
})

export const createUserRequest = (data) => ({
  type: USERS_TYPES.CREATE_USER_REQUEST,
  payload: data
})

export const createUserSuccess = (data) => ({
  type: USERS_TYPES.CREATE_USER_SUCCESS,
  payload: data
})

export const createUserFailure = (data) => ({
  type: USERS_TYPES.CREATE_USER_FAIL,
  payload: data
})

export const updateUserRequest = (data) => ({
  type: USERS_TYPES.UPDATE_USER_REQUEST,
  payload: data
})

export const updateUserSuccess = (data) => ({
  type: USERS_TYPES.UPDATE_USER_SUCCESS,
  payload: data
})

export const updateUserFailure = (data) => ({
  type: USERS_TYPES.UPDATE_USER_FAIL,
  payload: data
})

export const deleteUserRequest = (data) => ({
  type: USERS_TYPES.DELETE_USER_REQUEST,
  payload: data
})

export const deleteUserSuccess = (data) => ({
  type: USERS_TYPES.DELETE_USER_SUCCESS,
  payload: data
})

export const deleteUserFailure = (data) => ({
  type: USERS_TYPES.DELETE_USER_FAIL,
  payload: data
})
