import * as resumeformActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createProfile,
  getAllCities,
  getAllCountries,
  getAllStates,
  uploadResume
} from "@src/services/apis"

import { RESUME_FORM_ACTION_TYPES } from "./types"
import { toast } from "react-hot-toast"

function* getAllCountriesRequest() {
  try {
    const response = yield call(getAllCountries)
    yield put(resumeformActions.getAllCountriesSuccess(response))
  } catch (error) {
    yield put(resumeformActions.getAllCountriesFailure(error))
  }
}

// get states of chosen country
function* getStatesRequest(action) {
  try {
    const response = yield call(getAllStates, action.payload)
    yield put(resumeformActions.getAllStatesSuccess(response))
  } catch (error) {
    yield put(resumeformActions.getAllStatesFailure(error))
  }
}

// get cities of chosen state
function* getCitiesRequest(action) {
  try {
    const response = yield call(getAllCities, action.payload)
    yield put(resumeformActions.getAllCitiesSuccess(response))
  } catch (error) {
    yield put(resumeformActions.getAllCitiesFailure(error))
  }
}

function* postResumeFormRequest(action) {
  try {
    const response = yield call(createProfile, action.payload)
    if (response?.response?.data?.status === 409) {
      yield put(
        resumeformActions.postResumeFormFailure({
          status: 409,
          message: response.response.data.message
        })
      )
    } else if (response?.response?.data?.status === 401) {
      yield put(
        resumeformActions.postResumeFormFailure({
          status: 401,
          message: response.response.data.message
        })
      )
    } else if (response?.status === 200 || response?.status === 201) {
      yield put(resumeformActions.postResumeFormSuccess(response.data.data))
    } else if (response?.status === 204) {
      yield put(
        resumeformActions.postResumeFormFailure({
          status: 204,
          message: "Invalid Credentials"
        })
      )
    } else if (response?.status === 500) {
      yield put(
        resumeformActions.postResumeFormFailure({
          status: 500,
          message: "Internal Server Error"
        })
      )
    } else if (response?.status === 404) {
      yield put(
        resumeformActions.postResumeFormFailure({
          status: 404,
          message: "Not Found"
        })
      )
    } else {
      yield put(
        resumeformActions.postResumeFormFailure({
          message: "Something went wrong"
        })
      )
    }
    //   yield put(resumeformActions.postResumeFormSuccess(response.data.data))
  } catch (error) {
    yield put(resumeformActions.postResumeFormFailure(error))
  }
}

function* uploadResumeRequest(action) {
  try {
    const response = yield call(uploadResume, action.payload)

    // yield put(resumeformActions.uploadResumeSuccess(response.data))

    if (response?.response?.data?.status === 401) {
      yield put(
        resumeformActions.uploadResumeFailure({
          status: response.response.data.status,
          message: response.response.data.message
        })
      )
    } else if (response?.status === 200) {
      yield put(resumeformActions.uploadResumeSuccess(response.data))
      toast.success("Resume Uploaded Successfully")
    } else if (response?.status === 204) {
      yield put(
        resumeformActions.uploadResumeFailure({
          message: "Invalid Credentials",
          status: 204
        })
      )
    } else if (response?.status === 500) {
      yield put(
        resumeformActions.uploadResumeFailure({
          message: "Server Error",
          status: 500
        })
      )
    } else if (response?.status === 404) {
      yield put(
        resumeformActions.uploadResumeFailure({
          message: "Error Uploading Resume",
          status: 404
        })
      )
    } else {
      yield put(
        resumeformActions.uploadResumeFailure({
          message: "Error Uploading Resume",
          status: 500
        })
      )
    }
    // yield put(resumeformActions.uploadResumeSuccess(response.data))
  } catch (error) {
    yield put(resumeformActions.uploadResumeFailure(error))
    toast.error("Resume Upload Failed")
  }
}

function* ResumeForWatcherSaga() {
  yield all([
    takeEvery(
      RESUME_FORM_ACTION_TYPES.GET_ALL_COUNTRIES_REQUEST,
      getAllCountriesRequest
    ),
    takeEvery(
      RESUME_FORM_ACTION_TYPES.GET_ALL_STATES_REQUEST,
      getStatesRequest
    ),
    takeEvery(
      RESUME_FORM_ACTION_TYPES.GET_ALL_CITIES_REQUEST,
      getCitiesRequest
    ),
    takeEvery(
      RESUME_FORM_ACTION_TYPES.POST_RESUME_FORM_REQUEST,
      postResumeFormRequest
    ),
    takeEvery(
      RESUME_FORM_ACTION_TYPES.UPLOAD_RESUME_REQUEST,
      uploadResumeRequest
    )
  ])
}

export default ResumeForWatcherSaga
