import { createActionTypes } from "@src/utility"

export const USERS_TYPES = createActionTypes("users", [
  "GET_ALL_USERS_REQUEST",
  "GET_ALL_USERS_SUCCESS",
  "GET_ALL_USERS_FAIL",

  "CREATE_USER_REQUEST",
  "CREATE_USER_SUCCESS",
  "CREATE_USER_FAIL",

  "UPDATE_USER_REQUEST",
  "UPDATE_USER_SUCCESS",
  "UPDATE_USER_FAIL",

  "DELETE_USER_REQUEST",
  "DELETE_USER_SUCCESS",
  "DELETE_USER_FAIL"
])
