import * as usersActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createUser,
  deleteJobCategory,
  deleteUser,
  getAllUsers,
  updateUser
} from "@src/services/apis"

import { USERS_TYPES } from "./types"

function* getAllUsersRequest(action) {
  try {
    const response = yield call(getAllUsers, action.payload)
    yield put(usersActions.getAllUsersSuccess(response.data))
  } catch (err) {
    yield put(usersActions.getAllUsersFailure(err))
  }
}

function* createUserRequest(action) {
  try {
    const response = yield call(createUser, action.payload)
    if (response?.response?.status === 409) {
      yield put(
        usersActions.createUserFailure(response?.response?.data?.message)
      )
    } else if (response?.status === 201) {
      yield put(usersActions.createUserSuccess(response?.remaining_calls))
    }
  } catch (err) {
    yield put(usersActions.createUserFailure(err))
  }
}

function* updateUserRequest(action) {
  try {
    const response = yield call(updateUser, action.payload)
    yield put(usersActions.updateUserSuccess(response.data))
  } catch (err) {
    yield put(usersActions.updateUserFailure(err))
  }
}

function* deleteUserRequest(action) {
  try {
    const response = yield call(deleteUser, action.payload)
    yield put(usersActions.deleteUserSuccess(response?.remaining_calls))
  } catch (err) {
    yield put(usersActions.deleteUserFailure(err))
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(USERS_TYPES.GET_ALL_USERS_REQUEST, getAllUsersRequest),
    takeEvery(USERS_TYPES.CREATE_USER_REQUEST, createUserRequest),
    takeEvery(USERS_TYPES.UPDATE_USER_REQUEST, updateUserRequest),
    takeEvery(USERS_TYPES.DELETE_USER_REQUEST, deleteUserRequest)
  ])
}
