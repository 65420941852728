import * as securityAction from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"

import { SECURITY_TYPES } from "./types"
import { changePassword } from "@src/services/apis"

function* changePasswordRequest(action) {
  try {
    const response = yield call(changePassword, action.payload)
    if (response?.response?.status === 401) {
      yield put(
        securityAction.changePasswordFailure(response?.response?.data?.message)
      )
    } else if (response?.response?.status === 400) {
      yield put(
        securityAction.changePasswordFailure(response?.response?.data?.message)
      )
    } else {
      yield put(securityAction.changePasswordSuccess(response.data))
    }
  } catch (err) {
    yield put(securityAction.changePasswordFailure(err))
  }
}

export default function* securitySaga() {
  yield all([
    takeEvery(SECURITY_TYPES.CHANGE_PASSWORD_REQUEST, changePasswordRequest)
  ])
}
