import { createActionTypes } from "@src/utility"

export const GROUP_MANAGEMENT_TYPES = createActionTypes("group_management", [
  "GET_ALL_GROUPS_REQUEST",
  "GET_ALL_GROUPS_SUCCESS",
  "GET_ALL_GROUPS_FAIL",
  // GET SPECIFIC GROUP
  "GET_GROUP_REQUEST",
  "GET_GROUP_SUCCESS",
  "GET_GROUP_FAIL",
  // GET SPECIFIC MODULE INSIDE GROUP
  "GET_MODULE_REQUEST",
  "GET_MODULE_SUCCESS",
  "GET_MODULE_FAIL",
  // UPDATE SPECIFIC MODULE STATUS INSIDE GROUP
  "UPDATE_MODULE_STATUS_REQUEST",
  "UPDATE_MODULE_STATUS_SUCCESS",
  "UPDATE_MODULE_STATUS_FAIL",
  // GET SPECIFIC FEATURE INSIDE MODULE INSIDE GROUP
  "GET_FEATURE_REQUEST",
  "GET_FEATURE_SUCCESS",
  "GET_FEATURE_FAIL",
  // UPDATE SPECIFIC FEATURE STATUS INSIDE MODULE INSIDE GROUP
  "UPDATE_FEATURE_STATUS_REQUEST",
  "UPDATE_FEATURE_STATUS_SUCCESS",
  "UPDATE_FEATURE_STATUS_FAIL",

  // GET ALL GROUPS NAME
  "GET_ALL_GROUPS_NAME_REQUEST",
  "GET_ALL_GROUPS_NAME_SUCCESS",
  "GET_ALL_GROUPS_NAME_FAIL",

  "CREATE_USER_REQUEST",
  "CREATE_USER_SUCCESS",
  "CREATE_USER_FAIL"
])
