import { OAUTH_KEYS_TYPES } from "./types"
import toast from "react-hot-toast"

const initialState = {
  oAuthKeys: [],
  created: false,
  loading: false,
  error: null,
  updated: false,
  clientId: "",
  authorizedUris: [""],
  loadingAuthorizedUris: false,
  updatedAuthorizedUris: false,
  openSave: true
}

const oAuthKeysReducer = (state = initialState, action) => {
  switch (action.type) {
    case OAUTH_KEYS_TYPES.GET_ALL_OAUTH_KEYS_REQUEST:
      return { ...state }
    case OAUTH_KEYS_TYPES.GET_ALL_OAUTH_KEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        created: false,
        updated: false,
        oAuthKeys: action.payload.secretKeys,
        clientId: action.payload.clientId
      }
    case OAUTH_KEYS_TYPES.GET_ALL_OAUTH_KEYS_FAIL:
      return { ...state, loading: false, error: action.payload, clientId: "" }
    case OAUTH_KEYS_TYPES.CREATE_OAUTH_KEYS_REQUEST:
      return { ...state, loading: true }
    case OAUTH_KEYS_TYPES.CREATE_OAUTH_KEYS_SUCCESS:
      return { ...state, loading: false, created: true }
    case OAUTH_KEYS_TYPES.CREATE_OAUTH_KEYS_FAIL:
      return { ...state, loading: false, error: action.payload }
    case OAUTH_KEYS_TYPES.CHANGE_OAUTH_KEY_STATUS_REQUEST:
      return { ...state }
    case OAUTH_KEYS_TYPES.CHANGE_OAUTH_KEY_STATUS_SUCCESS:
      return { ...state, loading: false, updated: true }
    case OAUTH_KEYS_TYPES.CHANGE_OAUTH_KEY_STATUS_FAIL:
      return { ...state, loading: false, error: action.payload }
    case OAUTH_KEYS_TYPES.GET_AUTHORIZE_URIS_REQUEST:
      return { ...state }
    case OAUTH_KEYS_TYPES.GET_AUTHORIZE_URIS_SUCCESS:
      return { ...state, authorizedUris: action.payload }
    case OAUTH_KEYS_TYPES.GET_AUTHORIZE_URIS_FAIL:
      return { ...state, error: action.payload }
    case OAUTH_KEYS_TYPES.POST_AUTHORIZE_URIS_REQUEST:
      return { ...state }
    case OAUTH_KEYS_TYPES.POST_AUTHORIZE_URIS_SUCCESS:
      toast.success("Authorized Urls Saved Successfully")
      return {
        ...state,
        authorizedUris: action.payload.authorizedUrls
      }

    case OAUTH_KEYS_TYPES.POST_AUTHORIZE_URIS_FAIL:
      toast.error("Authorized Urls Not Saved")
      return { ...state, error: action.payload, updatedAuthorizedUris: false }

    case OAUTH_KEYS_TYPES.DELETE_AUTHORIZE_URI_REQUEST:
      return { ...state, updatedAuthorizedUris: false }
    case OAUTH_KEYS_TYPES.DELETE_AUTHORIZE_URI_SUCCESS:
      return {
        ...state,
        updatedAuthorizedUris: true,
        authorizedUris: state.authorizedUris.filter(
          (uri) => uri !== action.payload.uri
        )
      }
    case OAUTH_KEYS_TYPES.DELETE_AUTHORIZE_URI_FAIL:
      return { ...state, error: action.payload, updatedAuthorizedUris: false }
    case OAUTH_KEYS_TYPES.UPDATE_AUTHORIZE_URI_REQUEST:
      return { ...state }
    case OAUTH_KEYS_TYPES.UPDATE_AUTHORIZE_URI_SUCCESS:
      return { ...state }
    case OAUTH_KEYS_TYPES.UPDATE_AUTHORIZE_URI_FAIL:
      return { ...state, error: action.payload }

    default:
      return state
  }
}

export default oAuthKeysReducer
