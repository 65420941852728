import * as onBoardingActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  cancelInterview,
  getAssignedEvaluation,
  getInterviewersCall,
  getSpecificAssignedEvaluation,
  offerLetterResponse,
  rejectInterview,
  scheduleOnBoarding,
  sendOfferLetter,
  submitEvaluationFormCall,
  uploadFile
} from "@src/services/apis"

import { ONBOARDING_TYPES } from "./types"
import toast from "react-hot-toast"

// function* getAllOnBoardingsRequest(action) {
//   try {
//     const response = yield call(getAllOnBoardings, action.payload)
//     yield put(onBoardingActions.getAllOnBoardingsSuccess(response.data))
//   } catch (err) {
//     yield put(onBoardingActions.getAllOnBoardingsFailure(err))
//   }
// }

// function* createOnBoardingRequest(action) {
//   try {
//     const response = yield call(createOnBoarding, action.payload)
//     yield put(
//       onBoardingActions.createOnBoardingSuccess(response?.remaining_calls)
//     )
//   } catch (err) {
//     yield put(onBoardingActions.createOnBoardingFailure(err))
//   }
// }

// function* updateOnBoardingRequest(action) {
//   try {
//     const response = yield call(updateOnBoarding, action.payload)
//     yield put(onBoardingActions.updateOnBoardingSuccess(response.data))
//   } catch (err) {
//     yield put(onBoardingActions.updateOnBoardingFailure(err))
//   }
// }

// function* deleteOnBoardingRequest(action) {
//   try {
//     const response = yield call(deleteOnBoarding, action.payload)
//     yield put(
//       onBoardingActions.deleteOnBoardingSuccess(response?.remaining_calls)
//     )
//   } catch (err) {
//     yield put(onBoardingActions.deleteOnBoardingFailure(err))
//   }
// }

function* scheduleOnBoardingRequest(action) {
  try {
    const response = yield call(scheduleOnBoarding, action.payload)

    if (response?.response?.data?.status === 409) {
      yield put(
        onBoardingActions.scheduleOnBoardingFailure(
          response?.response?.data?.message
        )
      )
    } else if (response?.response?.data?.statusCode === 500) {
      yield put(
        onBoardingActions.scheduleOnBoardingFailure(
          "Use onmicrosoft.com email to schedule interview"
        )
      )
    } else if (response?.status === 200) {
      yield put(onBoardingActions.scheduleOnBoardingSuccess(response.data))
    }
  } catch (err) {
    yield put(onBoardingActions.scheduleOnBoardingFailure(err))
  }
}

function* getInterviewerRequest(action) {
  try {
    const response = yield call(getInterviewersCall, action.payload)
    yield put(onBoardingActions.getInterviewerSuccess(response.data))
  } catch (err) {
    yield put(onBoardingActions.getInterviewerFailure(err))
  }
}

function* getAssignedEvaluationRequest(action) {
  try {
    const response = yield call(getAssignedEvaluation, action.payload)

    yield put(onBoardingActions.getAllAssignedInterviewSuccess(response.data))
  } catch (err) {
    yield put(onBoardingActions.getAllAssignedInterviewFailure(err))
  }
}

function* submitEvaluationFormRequest(action) {
  try {
    const response = yield call(submitEvaluationFormCall, action.payload)
    // {"status":200,"data":"Evaluation form filled successfully"}
    if (
      response.status === 200 &&
      response.data === "Evaluation form filled successfully"
    ) {
      yield put(onBoardingActions.submitEvaluationFormSuccess(response.data))
    }
    // {"status":200,"data":"This form is already submitted."}
    else if (
      response.status === 200 &&
      response.data === "This form is already submitted."
    ) {
      toast.error("This form is already submitted.")
      yield put(onBoardingActions.submitEvaluationFormFailure(response.data))
    }

    // yield put(onBoardingActions.submitEvaluationFormSuccess(response.data))
  } catch (err) {
    yield put(onBoardingActions.submitEvaluationFormFailure(err))
  }
}

function* getSpecificAssignedEvaluationRequest(action) {
  try {
    const response = yield call(getSpecificAssignedEvaluation, action.payload)
    yield put(
      onBoardingActions.getSpecificAssignedInterviewSuccess(response.data)
    )
  } catch (err) {
    yield put(onBoardingActions.getSpecificAssignedInterviewFailure(err))
  }
}

function* cancelInterviewRequest(action) {
  try {
    const response = yield call(cancelInterview, action.payload)
    if (response.status === 200) {
      yield put(onBoardingActions.cancelInterviewSuccess(response.data))

      toast.success("Interview cancelled")
    }
  } catch (err) {
    yield put(onBoardingActions.cancelInterviewFailure(err))
  }
}

function* rejectInterviewRequest(action) {
  try {
    const response = yield call(rejectInterview, action.payload)
    yield put(onBoardingActions.rejectApplicantSuccess(response.data))
  } catch (err) {
    yield put(onBoardingActions.rejectApplicantFailure(err))
  }
}

function* uploadFileRequest(action) {
  try {
    const response = yield call(uploadFile, action.payload)
    if (response.status === 200) {
      toast.success("File uploaded successfully")
      yield put(onBoardingActions.uploadFileSuccess(response.data))
    }
  } catch (err) {
    yield put(onBoardingActions.uploadFileFailure(err))
  }
}

function* sendOfferLetterRequest(action) {
  try {
    const response = yield call(sendOfferLetter, action.payload)
    // {"status":200,"data":"Offer letter sent"}
    if (response.status === 200 && response.data === "Offer letter sent") {
      toast.success("Offer Letter sent successfully")
      yield put(onBoardingActions.sendOfferLetterSuccess(response.data))
    }
  } catch (err) {
    yield put(onBoardingActions.sendOfferLetterFailure(err))
  }
}

function* offerLetterResponseRequest(action) {
  try {
    const response = yield call(offerLetterResponse, action.payload)
    yield put(onBoardingActions.offerLetterResponseSuccess(response.data))
  } catch (err) {
    yield put(onBoardingActions.offerLetterResponseFailure(err))
  }
}

export default function* onBoardingSaga() {
  yield all([
    // takeEvery(
    //   ONBOARDING_TYPES.GET_ALL_ONBOARDINGS_REQUEST,
    //   getAllOnBoardingsRequest
    // ),
    // takeEvery(
    //   ONBOARDING_TYPES.CREATE_ONBOARDING_REQUEST,
    //   createOnBoardingRequest
    // ),
    // takeEvery(
    //   ONBOARDING_TYPES.UPDATE_ONBOARDING_REQUEST,
    //   updateOnBoardingRequest
    // ),
    // takeEvery(
    //   ONBOARDING_TYPES.DELETE_ONBOARDING_REQUEST,
    //   deleteOnBoardingRequest
    // )
    takeEvery(
      ONBOARDING_TYPES.SCHEDULE_ONBOARDING_REQUEST,
      scheduleOnBoardingRequest
    ),
    takeEvery(ONBOARDING_TYPES.GET_INTERVIEWER_REQUEST, getInterviewerRequest),
    takeEvery(
      ONBOARDING_TYPES.GET_ALL_ASSIGNED_INTERVIEW_REQUEST,
      getAssignedEvaluationRequest
    ),
    takeEvery(
      ONBOARDING_TYPES.SUBMIT_EVALUATION_FORM_REQUEST,
      submitEvaluationFormRequest
    ),
    takeEvery(
      ONBOARDING_TYPES.GET_SPECIFIC_ASSIGNED_INTERVIEW_REQUEST,
      getSpecificAssignedEvaluationRequest
    ),
    takeEvery(
      ONBOARDING_TYPES.CANCEL_INTERVIEW_REQUEST,
      cancelInterviewRequest
    ),
    takeEvery(
      ONBOARDING_TYPES.REJECT_APPLICANT_REQUEST,
      rejectInterviewRequest
    ),
    takeEvery(ONBOARDING_TYPES.UPLOAD_FILE_REQUEST, uploadFileRequest),
    takeEvery(
      ONBOARDING_TYPES.SEND_OFFER_LETTER_REQUEST,
      sendOfferLetterRequest
    ),
    takeEvery(
      ONBOARDING_TYPES.OFFER_LETTER_RESPONSE_REQUEST,
      offerLetterResponseRequest
    )
  ])
}
