import { createActionTypes } from "@src/utility"

export const ASSESSMENT_TYPES = createActionTypes("ASSESSMENT", [
  // Get all assessments
  //   View all questions:

  // Endpoint: /forms/assessment_question
  "GET_ALL_ASSESSMENT_REQUEST",
  "GET_ALL_ASSESSMENT_SUCCESS",
  "GET_ALL_ASSESSMENT_FAIL",

  // Get assessment of a profile
  "GET_PROFILE_ASSESSMENT_REQUEST",
  "GET_PROFILE_ASSESSMENT_SUCCESS",
  "GET_PROFILE_ASSESSMENT_FAILURE",

  // create assessment for all
  "CREATE_ASSESSMENT_REQUEST",
  "CREATE_ASSESSMENT_SUCCESS",
  "CREATE_ASSESSMENT_FAIL",

  // create assessment for all with ai
  "CREATE_ASSESSMENT_WITH_AI_REQUEST",
  "CREATE_ASSESSMENT_WITH_AI_SUCCESS",
  "CREATE_ASSESSMENT_WITH_AI_FAIL",

  // post assessment from a profile
  "POST_PROFILE_ASSESSMENT_REQUEST",
  "POST_PROFILE_ASSESSMENT_SUCCESS",
  "POST_PROFILE_ASSESSMENT_FAILURE",

  // view profile assessment/submission
  "GET_PROFILE_ASSESSMENT_SUBMISSION_REQUEST",
  "GET_PROFILE_ASSESSMENT_SUBMISSION_SUCCESS",
  "GET_PROFILE_ASSESSMENT_SUBMISSION_FAILURE",

  // view INTERVIEW TEST
  "VIEW_INTERVIEW_TEST_REQUEST",
  "VIEW_INTERVIEW_TEST_SUCCESS",
  "VIEW_INTERVIEW_TEST_FAILURE",

  // is interview test available (use polling)
  "IS_INTERVIEW_TEST_AVAILABLE_REQUEST",
  "IS_INTERVIEW_TEST_AVAILABLE_SUCCESS",
  "IS_INTERVIEW_TEST_AVAILABLE_FAILURE",

  // submit interview test
  "SUBMIT_INTERVIEW_TEST_REQUEST",
  "SUBMIT_INTERVIEW_TEST_SUCCESS",
  "SUBMIT_INTERVIEW_TEST_FAILURE",

  // create technical test
  "CREATE_TECHNICAL_TEST_REQUEST",
  "CREATE_TECHNICAL_TEST_SUCCESS",
  "CREATE_TECHNICAL_TEST_FAILURE",

  // update assessment for all
  "UPDATE_ASSESSMENT_REQUEST",
  "UPDATE_ASSESSMENT_SUCCESS",
  "UPDATE_ASSESSMENT_FAIL",

  // delete assessment for all
  "DELETE_ASSESSMENT_REQUEST",
  "DELETE_ASSESSMENT_SUCCESS",
  "DELETE_ASSESSMENT_FAIL",

  "RESET_STATUS"
])
