import { PLAN_TYPES } from "./types"

const initialState = {
  listOfPlans: [],
  error: null
}

export default function planReducer(state = initialState, action) {
  switch (action.type) {
    case PLAN_TYPES.GET_LIST_OF_PLANS_REQUEST:
      return {
        ...state,
        listOfPlans: []
      }
    case PLAN_TYPES.GET_LIST_OF_PLANS_SUCCESS:
      return {
        ...state,
        listOfPlans: action.payload,
        error: null
      }
    case PLAN_TYPES.GET_LIST_OF_PLANS_FAILURE:
      return {
        ...state,
        error: action.payload,
        listOfPlans: []
      }

    default:
      return state
  }
}
