export default {
  company: "company",
  user: "user",
  job: "job",
  access: "access",
  seekerProfile: "seeker_profile",
  onboarding: "onboarding",
  forms: "forms",
  integration: "integration",
  onboarding: "onboarding",
  plansAndPricing: "plans_and_pricing",
  dashboard: "dashboard",
  documentation: "documentation",
  chatbot: "chatbot"
}
