import axios from "axios"
import jwtDefaultConfig from "./jwtDefaultConfig"

export default class JwtService {
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  currentPath = document.location.pathname.split("/")[1]

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = this.jwtConfig.baseURL + this.currentPath

        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    axios.interceptors.response.use(
      (response) => {
        if (response.status === 201) {
        } else if (response.status === 200) {
        } else if (response.status === 204) {
        }
        return response
      },
      (error) => {
        if (error.response) {
          if (error.response.status === 403) {
            if (
              error.response.data.description === "Token expired or invalid"
            ) {
              const companyName = localStorage.getItem(
                this.jwtConfig.companyName
              )
              localStorage.clear()
              window.location.pathname = `/${companyName}/login`
            }
          } else if (error.response.status === 401) {
          } else if (error.response.status === 404) {
          } else {
          }
        } else if (error.request) {
          if (error?.code === "ERR_NETWORK") {
          }
        } else {
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => {
      callback(accessToken)
    })
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }
  post(...args) {
    return axios.post(...args)
  }
  get(...args) {
    return axios.get(...args)
  }
  put(...args) {
    return axios.put(...args)
  }
  // PATCH
  patch(...args) {
    return axios.patch(...args)
  }
  delete(...args) {
    return axios.delete(...args)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
