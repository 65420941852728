import { SECURITY_TYPES } from "./types"

export const changePasswordRequest = (data) => ({
  type: SECURITY_TYPES.CHANGE_PASSWORD_REQUEST,
  payload: data
})

export const changePasswordSuccess = (data) => ({
  type: SECURITY_TYPES.CHANGE_PASSWORD_SUCCESS,
  payload: data
})

export const changePasswordFailure = (data) => ({
  type: SECURITY_TYPES.CHANGE_PASSWORD_FAIL,
  payload: data
})

export const clearChangePasswordResponse = () => ({
  type: SECURITY_TYPES.CLEAR_CHANGE_PASSWORD_RESPONSE
})
