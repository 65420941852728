import { EVALUATION_TYPES } from "./types"

const initialState = {
  evaluation: null,
  evaluationLoading: false,
  evaluations: [],
  evaluationsLoading: false,
  error: null,
  deleted: false,
  updated: false,
  added: false,
  createdUpdatedLoading: false
}

const evaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVALUATION_TYPES.CREATE_EVALUATION_REQUEST:
      return {
        ...state,
        added: false,
        updated: false,
        createdUpdatedLoading: true
      }
    case EVALUATION_TYPES.CREATE_EVALUATION_SUCCESS:
      return {
        ...state,
        added: true,
        createdUpdatedLoading: false
      }
    case EVALUATION_TYPES.CREATE_EVALUATION_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false
      }

    case EVALUATION_TYPES.RESET_ADDED_STATUS:
      return {
        ...state,
        added: false,
        updated: false
      }

    case EVALUATION_TYPES.GET_EVALUATION_REQUEST:
      return {
        ...state,
        evaluationLoading: true
      }

    case EVALUATION_TYPES.GET_EVALUATION_SUCCESS:
      return {
        ...state,
        evaluation: action.payload,
        evaluationLoading: false
      }

    case EVALUATION_TYPES.GET_EVALUATION_FAIL:
      return {
        ...state,
        error: action.payload,
        evaluationLoading: false
      }

    case EVALUATION_TYPES.UPDATE_EVALUATION_REQUEST:
      return {
        ...state,
        updated: false,
        createdUpdatedLoading: true
      }

    case EVALUATION_TYPES.UPDATE_EVALUATION_SUCCESS:
      return {
        ...state,
        updated: true,
        createdUpdatedLoading: false
      }

    case EVALUATION_TYPES.UPDATE_EVALUATION_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false
      }

    case EVALUATION_TYPES.DELETE_EVALUATION_REQUEST:
      return {
        ...state,
        deleted: false
      }

    case EVALUATION_TYPES.DELETE_EVALUATION_SUCCESS:
      return {
        ...state,
        deleted: true
      }

    case EVALUATION_TYPES.DELETE_EVALUATION_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case EVALUATION_TYPES.GET_ALL_EVALUATION_REQUEST:
      return {
        ...state,
        added: false,
        updated: false,

        evaluationsLoading: true
      }

    case EVALUATION_TYPES.GET_ALL_EVALUATION_SUCCESS:
      return {
        ...state,
        evaluations: action.payload,
        evaluationsLoading: false
      }

    case EVALUATION_TYPES.GET_ALL_EVALUATION_FAIL:
      return {
        ...state,
        error: action.payload,
        evaluationsLoading: false
      }

    default:
      return state
  }
}

export default evaluationReducer
