import { COMPANY_PROFILE_TYPES } from "./types"
import useJwt from "@src/auth/jwt/useJwt"

// ** UseJWT import to get config

const config = useJwt.jwtConfig

const initialState = {
  Profile: {},
  error: null,
  Locations: [],
  LocationsError: null,
  fetched: false,
  updated: false,
  created: false,
  deleted: false,
  profileUpdated: false,
  ProfileLoading: false,
  logoUrl: null,
  logoError: null,
  logoLoading: false,
  zoomIntegration: {},
  zoomIntegrationLoading: false,
  zoomIntegrationUpdated: false,
  zoomIntegrationDeleted: false,
  linkedInIntegration: {},
  linkedInIntegrationLoading: false,
  linkedInIntegrationUpdated: false,
  linkedInIntegrationDeleted: false,
  teamsIntegration: {},
  teamsIntegrationLoading: false,
  teamsIntegrationUpdated: false,
  teamsIntegrationDeleted: false

  // added zoomIntegration field
}

export default function companyProfileReducer(state = initialState, action) {
  switch (action.type) {
    case COMPANY_PROFILE_TYPES.GET_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        Profile: action.payload
      }

    case COMPANY_PROFILE_TYPES.GET_COMPANY_PROFILE_FAILED:
      return {
        ...state,
        error: action.payload
      }

    case COMPANY_PROFILE_TYPES.POST_COMPANY_PROFILE_SUCCESS:
      return {
        ...state
      }

    case COMPANY_PROFILE_TYPES.POST_COMPANY_PROFILE_FAILED:
      return {
        ...state,
        error: action.payload
      }

    case COMPANY_PROFILE_TYPES.UPDATE_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        profileUpdated: false,
        ProfileLoading: true
      }

    case COMPANY_PROFILE_TYPES.UPDATE_COMPANY_PROFILE_SUCCESS:
      localStorage.setItem(
        config.companyLogo,
        action.payload?.body?.company_logo ?? ""
      )
      return {
        ...state,
        profileUpdated: true,
        ProfileLoading: false
      }

    case COMPANY_PROFILE_TYPES.UPDATE_COMPANY_PROFILE_FAILED:
      return {
        ...state,
        ProfileLoading: false,
        profileUpdated: false,
        error: action.payload
      }

    case COMPANY_PROFILE_TYPES.GET_COMPANY_LOCATIONS_REQUEST:
      return {
        ...state,
        fetched: false
      }

    case COMPANY_PROFILE_TYPES.GET_COMPANY_LOCATIONS_SUCCESS:
      return {
        ...state,
        Locations: action.payload,
        fetched: true
      }

    case COMPANY_PROFILE_TYPES.GET_COMPANY_LOCATIONS_FAILED:
      return {
        ...state,
        LocationsError: action.payload,
        fetched: false
      }

    case COMPANY_PROFILE_TYPES.POST_COMPANY_LOCATIONS_REQUEST:
      return {
        ...state,
        created: false
      }

    case COMPANY_PROFILE_TYPES.POST_COMPANY_LOCATIONS_SUCCESS:
      return {
        ...state,
        created: true
      }

    case COMPANY_PROFILE_TYPES.POST_COMPANY_LOCATIONS_FAILED:
      return {
        ...state,
        LocationsError: action.payload,
        created: false
      }

    case COMPANY_PROFILE_TYPES.CLEAR_LOCATIONS_RESPONSE:
      return {
        ...state,
        created: false,
        updated: false,
        deleted: false
      }

    case COMPANY_PROFILE_TYPES.UPDATE_COMPANY_LOCATIONS_REQUEST:
      return {
        ...state,
        updated: false
      }

    case COMPANY_PROFILE_TYPES.UPDATE_COMPANY_LOCATIONS_SUCCESS:
      return {
        ...state,
        updated: true
      }

    case COMPANY_PROFILE_TYPES.UPDATE_COMPANY_LOCATIONS_FAILED:
      return {
        ...state,
        LocationsError: action.payload,
        updated: false
      }

    case COMPANY_PROFILE_TYPES.DELETE_COMPANY_LOCATIONS_REQUEST:
      return {
        ...state,
        deleted: false
      }

    case COMPANY_PROFILE_TYPES.DELETE_COMPANY_LOCATIONS_SUCCESS:
      return {
        ...state,
        deleted: true
      }

    case COMPANY_PROFILE_TYPES.DELETE_COMPANY_LOCATIONS_FAILED:
      return {
        ...state,
        LocationsError: action.payload,
        deleted: false
      }

    case COMPANY_PROFILE_TYPES.CLEAR_PROFILE_RESPONSE:
      return {
        ...state,
        profileUpdated: false,
        ProfileLoading: false,
        error: null
      }

    case COMPANY_PROFILE_TYPES.UPLOAD_COMPANY_LOGO_REQUEST:
      return {
        ...state,
        logoLoading: true,
        logoUrl: null
      }

    case COMPANY_PROFILE_TYPES.UPLOAD_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        logoLoading: false,
        logoUrl: action.payload.url
      }

    case COMPANY_PROFILE_TYPES.UPLOAD_COMPANY_LOGO_FAILED:
      return {
        ...state,
        logoLoading: false,
        logoError: action.payload
      }

    case COMPANY_PROFILE_TYPES.API_LIMIT_REQUEST:
      return {
        ...state
        // apiLimit: true
      }

    case COMPANY_PROFILE_TYPES.API_LIMIT_SUCCESS:
      // // rewrite the api limits in local storage
      // localStorage.setItem(
      //   config.aiGenLimit,
      //   action.payload?.apiCallLimit[1]?.total || 0
      // )

      // localStorage.setItem(
      //   config.currAiGenLimit,
      //   action.payload?.apiCallLimit[1]?.current || 0
      // )

      // localStorage.setItem(
      //   config.aiCategorizeLimit,
      //   action.payload?.apiCallLimit[2]?.total || 0
      // )

      // localStorage.setItem(
      //   config.currAiCategorizeLimit,
      //   action.payload?.apiCallLimit[2]?.current || 0
      // )

      // localStorage.setItem(
      //   config.aiSkillTestLimit,
      //   action.payload?.apiCallLimit[0]?.total || 0
      // )

      // localStorage.setItem(
      //   config.currAiSkillTestLimit,
      //   action.payload?.apiCallLimit[0]?.current || 0
      // )

      // // for job post
      // localStorage.setItem(
      //   config.createJobPostLimit,
      //   action.payload?.apiCallLimit[3]?.total || 0
      // )

      // localStorage.setItem(
      //   config.currCreateJobPostLimit,
      //   action.payload?.apiCallLimit[3]?.current || 0
      // )

      // // for job category
      // localStorage.setItem(
      //   config.createJobCategoryLimit,
      //   action.payload?.apiCallLimit[7]?.total || 0
      // )

      // localStorage.setItem(
      //   config.currCreateJobCategoryLimit,
      //   action.payload?.apiCallLimit[7]?.current || 0
      // )

      // // for skill set
      // localStorage.setItem(
      //   config.createSkillSetLimit,
      //   action.payload?.apiCallLimit[5]?.total || 0
      // )

      // localStorage.setItem(
      //   config.currCreateSkillSetLimit,
      //   action.payload?.apiCallLimit[5]?.current || 0
      // )

      // rewrite the api limits in local storage
      const findApiLimitByName = (name) =>
        action.payload?.apiCallLimit.find((limit) => limit.name === name)

      const aiGenLimit = findApiLimitByName("generateJobDescViaAi")
      localStorage.setItem(config.aiGenLimit, aiGenLimit?.total || 0)

      localStorage.setItem(config.currAiGenLimit, aiGenLimit?.current || 0)

      const aiCategorizeLimit = findApiLimitByName("categorizeCVWithAI")
      localStorage.setItem(
        config.aiCategorizeLimit,
        aiCategorizeLimit?.total || 0
      )

      localStorage.setItem(
        config.currAiCategorizeLimit,
        aiCategorizeLimit?.current || 0
      )

      const aiSkillTestLimit = findApiLimitByName("generateOnlineSkillTest")
      localStorage.setItem(
        config.aiSkillTestLimit,
        aiSkillTestLimit?.total || 0
      )

      localStorage.setItem(
        config.currAiSkillTestLimit,
        aiSkillTestLimit?.current || 0
      )

      // for job post
      const createJobPostLimit = findApiLimitByName("createJobPost")
      localStorage.setItem(
        config.createJobPostLimit,
        createJobPostLimit?.total || 0
      )

      localStorage.setItem(
        config.currCreateJobPostLimit,
        createJobPostLimit?.current || 0
      )

      // for job category
      const createJobCategoryLimit = findApiLimitByName("createJobCategory")
      localStorage.setItem(
        config.createJobCategoryLimit,
        createJobCategoryLimit?.total || 0
      )

      localStorage.setItem(
        config.currCreateJobCategoryLimit,
        createJobCategoryLimit?.current || 0
      )

      // for skill set
      const createSkillSetLimit = findApiLimitByName("createSkillSet")
      localStorage.setItem(
        config.createSkillSetLimit,
        createSkillSetLimit?.total || 0
      )

      localStorage.setItem(
        config.currCreateSkillSetLimit,
        createSkillSetLimit?.current || 0
      )

      // for user
      const createUserLimit = findApiLimitByName("companyUser")
      localStorage.setItem(config.createUserLimit, createUserLimit?.total || 0)

      localStorage.setItem(
        config.currCreateUserLimit,
        createUserLimit?.current || 0
      )

      // for gen AI limit
      const genAIAssessmentLimit = findApiLimitByName(
        "generateAssessmentQuestionsViaAi"
      )
      localStorage.setItem(
        config.genAIAssessmentLimit,
        genAIAssessmentLimit?.total || 0
      )

      localStorage.setItem(
        config.currGenAIAssessmentLimit,
        genAIAssessmentLimit?.current || 0
      )

      const getAllApplicantLimit = findApiLimitByName("getAllAppliedApplicants")

      localStorage.setItem(
        config.getAllApplicantLimit,
        getAllApplicantLimit?.total || 0
      )

      localStorage.setItem(
        config.getAllApplicantLimit,
        getAllApplicantLimit?.current || 0
      )

      return {
        ...state
      }

    case COMPANY_PROFILE_TYPES.API_LIMIT_FAILED:
      // localStorage has no values for api limits then set them to 0
      if (!localStorage.getItem(config.aiGenLimit)) {
        localStorage.setItem(config.aiGenLimit, 0)
      }
      if (!localStorage.getItem(config.currAiGenLimit)) {
        localStorage.setItem(config.currAiGenLimit, 0)
      }
      if (!localStorage.getItem(config.aiCategorizeLimit)) {
        localStorage.setItem(config.aiCategorizeLimit, 0)
      }
      if (!localStorage.getItem(config.currAiCategorizeLimit)) {
        localStorage.setItem(config.currAiCategorizeLimit, 0)
      }
      if (!localStorage.getItem(config.aiSkillTestLimit)) {
        localStorage.setItem(config.aiSkillTestLimit, 0)
      }
      if (!localStorage.getItem(config.currAiSkillTestLimit)) {
        localStorage.setItem(config.currAiSkillTestLimit, 0)
      }
      if (!localStorage.getItem(config.createJobPostLimit)) {
        localStorage.setItem(config.createJobPostLimit, 0)
      }

      return {
        ...state
        //  apiLimit: false
      }
    case COMPANY_PROFILE_TYPES.ZOOM_INTEGRATION_REQUEST:
      return {
        ...state,
        zoomIntegrationLoading: true
      }
    case COMPANY_PROFILE_TYPES.ZOOM_INTEGRATION_SUCCESS:
      return {
        ...state,
        zoomIntegrationLoading: false,
        zoomIntegrationUpdated: true
      }
    case COMPANY_PROFILE_TYPES.ZOOM_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        zoomIntegrationLoading: false,
        zoomIntegrationUpdated: false
      }
    case COMPANY_PROFILE_TYPES.GET_ZOOM_INTEGRATION_REQUEST:
      return {
        ...state,
        zoomIntegrationDeleted: false,
        zoomIntegrationUpdated: false
      }
    case COMPANY_PROFILE_TYPES.GET_ZOOM_INTEGRATION_SUCCESS:
      return {
        ...state,
        zoomIntegration: action.payload
      }
    case COMPANY_PROFILE_TYPES.GET_ZOOM_INTEGRATION_FAILED:
      return {
        ...state,
        zoomIntegration: {},
        error: action.payload
      }
    case COMPANY_PROFILE_TYPES.DELETE_ZOOM_INTEGRATION_REQUEST:
      return {
        ...state,
        zoomIntegrationDeleted: false
      }
    case COMPANY_PROFILE_TYPES.DELETE_ZOOM_INTEGRATION_SUCCESS:
      return {
        ...state,
        zoomIntegration: {},
        zoomIntegrationDeleted: true
      }
    case COMPANY_PROFILE_TYPES.DELETE_ZOOM_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        zoomIntegrationDeleted: false
      }
    case COMPANY_PROFILE_TYPES.UPDATE_ZOOM_INTEGRATION_REQUEST:
      return {
        ...state,
        zoomIntegrationLoading: true,
        zoomIntegrationUpdated: false
      }
    case COMPANY_PROFILE_TYPES.UPDATE_ZOOM_INTEGRATION_SUCCESS:
      return {
        ...state,
        zoomIntegrationLoading: false,
        zoomIntegrationUpdated: true
      }
    case COMPANY_PROFILE_TYPES.UPDATE_ZOOM_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        zoomIntegrationLoading: false,
        zoomIntegrationUpdated: true
      }

    case COMPANY_PROFILE_TYPES.LINKEDIN_INTEGRATION_REQUEST:
      return {
        ...state,
        linkedInIntegrationLoading: true
      }

    case COMPANY_PROFILE_TYPES.LINKEDIN_INTEGRATION_SUCCESS:
      return {
        ...state,
        linkedInIntegrationLoading: false,
        linkedInIntegrationUpdated: true
      }

    case COMPANY_PROFILE_TYPES.LINKEDIN_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        linkedInIntegrationLoading: false,
        linkedInIntegrationUpdated: false
      }

    case COMPANY_PROFILE_TYPES.GET_LINKEDIN_INTEGRATION_REQUEST:
      return {
        ...state,
        linkedInIntegrationDeleted: false,
        linkedInIntegrationUpdated: false
      }

    case COMPANY_PROFILE_TYPES.GET_LINKEDIN_INTEGRATION_SUCCESS:
      return {
        ...state,
        linkedInIntegration: action.payload
      }

    case COMPANY_PROFILE_TYPES.GET_LINKEDIN_INTEGRATION_FAILED:
      return {
        ...state,
        linkedInIntegration: {},
        error: action.payload
      }

    case COMPANY_PROFILE_TYPES.DELETE_LINKEDIN_INTEGRATION_REQUEST:
      return {
        ...state,
        linkedInIntegrationDeleted: false
      }

    case COMPANY_PROFILE_TYPES.DELETE_LINKEDIN_INTEGRATION_SUCCESS:
      return {
        ...state,
        linkedInIntegration: {},
        linkedInIntegrationDeleted: true
      }

    case COMPANY_PROFILE_TYPES.DELETE_LINKEDIN_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        linkedInIntegrationDeleted: false
      }

    case COMPANY_PROFILE_TYPES.UPDATE_LINKEDIN_INTEGRATION_REQUEST:
      return {
        ...state,
        linkedInIntegrationLoading: true,
        linkedInIntegrationUpdated: false
      }

    case COMPANY_PROFILE_TYPES.UPDATE_LINKEDIN_INTEGRATION_SUCCESS:
      return {
        ...state,
        linkedInIntegrationLoading: false,
        linkedInIntegrationUpdated: true
      }

    case COMPANY_PROFILE_TYPES.UPDATE_LINKEDIN_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        linkedInIntegrationLoading: false,
        linkedInIntegrationUpdated: true
      }

    case COMPANY_PROFILE_TYPES.TEAMS_INTEGRATION_REQUEST:
      return {
        ...state,
        teamsIntegrationLoading: true
      }
    case COMPANY_PROFILE_TYPES.TEAMS_INTEGRATION_SUCCESS:
      return {
        ...state,
        teamsIntegrationLoading: false,
        teamsIntegrationUpdated: true
      }
    case COMPANY_PROFILE_TYPES.TEAMS_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        teamsIntegrationLoading: false,
        teamsIntegrationUpdated: false
      }
    case COMPANY_PROFILE_TYPES.GET_TEAMS_INTEGRATION_REQUEST:
      return {
        ...state,
        teamsIntegrationDeleted: false,
        teamsIntegrationUpdated: false
      }
    case COMPANY_PROFILE_TYPES.GET_TEAMS_INTEGRATION_SUCCESS:
      return {
        ...state,
        teamsIntegration: action.payload
      }
    case COMPANY_PROFILE_TYPES.GET_TEAMS_INTEGRATION_FAILED:
      return {
        ...state,
        teamsIntegration: {},
        error: action.payload
      }
    case COMPANY_PROFILE_TYPES.DELETE_TEAMS_INTEGRATION_REQUEST:
      return {
        ...state,
        teamsIntegrationDeleted: false
      }
    case COMPANY_PROFILE_TYPES.DELETE_TEAMS_INTEGRATION_SUCCESS:
      return {
        ...state,
        teamsIntegration: {},
        teamsIntegrationDeleted: true
      }
    case COMPANY_PROFILE_TYPES.DELETE_TEAMS_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        teamsIntegrationDeleted: false
      }
    case COMPANY_PROFILE_TYPES.UPDATE_TEAMS_INTEGRATION_REQUEST:
      return {
        ...state,
        teamsIntegrationLoading: true,
        teamsIntegrationUpdated: false
      }
    case COMPANY_PROFILE_TYPES.UPDATE_TEAMS_INTEGRATION_SUCCESS:
      return {
        ...state,
        teamsIntegrationLoading: false,
        teamsIntegrationUpdated: true
      }
    case COMPANY_PROFILE_TYPES.UPDATE_TEAMS_INTEGRATION_FAILED:
      return {
        ...state,
        error: action.payload,
        teamsIntegrationLoading: false,
        teamsIntegrationUpdated: true
      }

    default:
      return state
  }
}
