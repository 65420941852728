import { DASHBOARD } from "./types"

export const getStatsRequest = (data) => ({
  type: DASHBOARD.GET_STATS_REQUEST,
  payload: data
})

export const getStatsSuccess = (data) => ({
  type: DASHBOARD.GET_STATS_SUCCESS,
  payload: data
})

export const getStatsFailure = (error) => ({
  type: DASHBOARD.GET_STATS_FAIL,
  payload: error
})

export const getApplicantsStatusRequest = (data) => ({
  type: DASHBOARD.GET_APPLICANTS_STATUS_REQUEST,
  payload: data
})

export const getApplicantsStatusSuccess = (data) => ({
  type: DASHBOARD.GET_APPLICANTS_STATUS_SUCCESS,
  payload: data
})

export const getApplicantsStatusFailure = (error) => ({
  type: DASHBOARD.GET_APPLICANTS_STATUS_FAIL,
  payload: error
})

export const getApplicantsReportRequest = (data) => ({
  type: DASHBOARD.GET_APPLICANTS_REPORT_REQUEST,
  payload: data
})

export const getApplicantsReportSuccess = (data) => ({
  type: DASHBOARD.GET_APPLICANTS_REPORT_SUCCESS,
  payload: data
})

export const getApplicantsReportFailure = (error) => ({
  type: DASHBOARD.GET_APPLICANTS_REPORT_FAIL,
  payload: error
})
