import * as assessmentActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  createAssessmentForms,
  createAssessmentFormsWithAI,
  createTechnicalTest,
  deleteAssessment,
  getAllAssessment,
  getProfileAssessment,
  getProfileAssessmentSubmission,
  isInterviewTestAvailable,
  postProfileAssessment,
  submitInterviewTest,
  updateAssessment,
  viewInterviewTest
} from "@src/services/apis"

import { ASSESSMENT_TYPES } from "./types"
import toast from "react-hot-toast"

function* getAllAssessmentRequest(action) {
  try {
    const response = yield call(getAllAssessment, action.payload)

    if (response.status === 200) {
      yield put(assessmentActions.getAllAssessmentSuccess(response.data))
    }
  } catch (err) {
    yield put(assessmentActions.getAllAssessmentFailure(err))
  }
}

function* getProfileAssessmentRequest(action) {
  try {
    const response = yield call(getProfileAssessment, action.payload)
    yield put(assessmentActions.getProfileAssessmentSuccess(response.data))
  } catch (err) {
    yield put(assessmentActions.getProfileAssessmentFailure(err))
  }
}

function* postProfileAssessmentRequest(action) {
  try {
    const response = yield call(postProfileAssessment, action.payload)
    if (response?.status === 200) {
      yield put(assessmentActions.postProfileAssessmentSuccess(response.data))
    } else {
      toast.error("Error in submitting assessment")
      yield put(assessmentActions.postProfileAssessmentFailure(response))
    }
  } catch (err) {
    yield put(assessmentActions.postProfileAssessmentFailure(err))
  }
}

function* getProfileAssessmentSubmissionRequest(action) {
  try {
    const response = yield call(getProfileAssessmentSubmission, action.payload)
    yield put(
      assessmentActions.getProfileAssessmentSubmissionSuccess(response.data)
    )
  } catch (err) {
    yield put(assessmentActions.getProfileAssessmentSubmissionFailure(err))
  }
}

function* viewInterviewTestRequest(action) {
  try {
    const response = yield call(viewInterviewTest, action.payload)
    if (response?.status === 200) {
      yield put(assessmentActions.viewInterviewTestSuccess(response.data))
    } else {
      yield put(assessmentActions.viewInterviewTestFailure(response))
    }
    // yield put(assessmentActions.viewInterviewTestSuccess(response.data))
  } catch (err) {
    yield put(assessmentActions.viewInterviewTestFailure(err))
  }
}

function* isInterviewTestAvailableRequest(action) {
  try {
    const response = yield call(isInterviewTestAvailable, action.payload)
    if (response?.status === 200) {
      yield put(
        assessmentActions.isInterviewTestAvailableSuccess(response.data)
      )
    } else {
      // toast.error("Error in getting test")
      yield put(assessmentActions.isInterviewTestAvailableFailure(response))
    }
  } catch (err) {
    yield put(assessmentActions.isInterviewTestAvailableFailure(err))
  }
}

function* submitInterviewTestRequest(action) {
  try {
    const response = yield call(submitInterviewTest, action.payload)
    yield put(assessmentActions.submitInterviewTestSuccess(response.data))
  } catch (err) {
    yield put(assessmentActions.submitInterviewTestFailure(err))
  }
}

function* createTechnicalTestRequest(action) {
  try {
    const response = yield call(createTechnicalTest, action.payload)
    if (response?.status === 200) {
      yield put(assessmentActions.createTechnicalTestSuccess(response.data))
    } else if (response?.status === 403) {
      toast.error("Test Already Created")
      yield put(assessmentActions.createTechnicalTestFailure(response))
    } else {
      toast.error("Error in creating Test")
      yield put(assessmentActions.createTechnicalTestFailure(err))
    }
  } catch (err) {
    yield put(assessmentActions.createTechnicalTestFailure(err))
  }
}

function* createAssessmentRequest(action) {
  try {
    const response = yield call(createAssessmentForms, action.payload)
    if (response?.status === 201 && response?.message === "Created") {
      yield put(assessmentActions.createAssessmentSuccess(response))
    } else {
      yield put(assessmentActions.createAssessmentFailure(response))
    }
  } catch (err) {
    yield put(assessmentActions.createAssessmentFailure(err))
  }
}

function* createAssessmentWithAIRequest(action) {
  try {
    const response = yield call(createAssessmentFormsWithAI, action.payload)

    if (response?.status === 200) {
      yield put(assessmentActions.createAssessmentWithAISuccess(response.data))
    } else {
      toast.error("Error in creating assessment")
      yield put(assessmentActions.createAssessmentWithAIFailure(response))
    }
  } catch (err) {
    yield put(assessmentActions.createAssessmentWithAIFailure(err))
  }
}

function* updateAssessmentRequest(action) {
  try {
    const response = yield call(updateAssessment, action.payload)
    if (response?.status === 200) {
      yield put(assessmentActions.updateAssessmentSuccess(response.data))
    } else {
      toast.error("Error in updating assessment")
      yield put(assessmentActions.updateAssessmentFailure(response))
    }
  } catch (err) {
    yield put(assessmentActions.updateAssessmentFailure(err))
  }
}

function* deleteAssessmentRequest(action) {
  try {
    const response = yield call(deleteAssessment, action.payload)
    if (response?.status === 200) {
      yield put(assessmentActions.deleteAssessmentSuccess(response?.data))
    } else {
      toast.error("Error in deleting assessment")
      yield put(assessmentActions.deleteAssessmentFailure(response))
    }
  } catch (err) {
    yield put(assessmentActions.deleteAssessmentFailure(err))
  }
}

export default function* assessmentSaga() {
  yield all([
    takeEvery(
      ASSESSMENT_TYPES.GET_ALL_ASSESSMENT_REQUEST,
      getAllAssessmentRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.CREATE_ASSESSMENT_REQUEST,
      createAssessmentRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.UPDATE_ASSESSMENT_REQUEST,
      updateAssessmentRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.DELETE_ASSESSMENT_REQUEST,
      deleteAssessmentRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_REQUEST,
      getProfileAssessmentRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.GET_PROFILE_ASSESSMENT_SUBMISSION_REQUEST,
      getProfileAssessmentSubmissionRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.VIEW_INTERVIEW_TEST_REQUEST,
      viewInterviewTestRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.IS_INTERVIEW_TEST_AVAILABLE_REQUEST,
      isInterviewTestAvailableRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.SUBMIT_INTERVIEW_TEST_REQUEST,
      submitInterviewTestRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.CREATE_TECHNICAL_TEST_REQUEST,
      createTechnicalTestRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.POST_PROFILE_ASSESSMENT_REQUEST,
      postProfileAssessmentRequest
    ),
    takeEvery(
      ASSESSMENT_TYPES.CREATE_ASSESSMENT_WITH_AI_REQUEST,
      createAssessmentWithAIRequest
    )
  ])
}
