import * as groupManagementActions from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  getAllGroups,
  getFeature,
  getGroup,
  getModule,
  listOfGroups,
  updateFeatureStatus,
  updateModuleStatus
} from "@src/services/apis"

import { GROUP_MANAGEMENT_TYPES } from "./types"

function* getAllGroupsRequest(action) {
  try {
    const response = yield call(getAllGroups, action.payload)
    yield put(groupManagementActions.getAllGroupsSuccess(response.data))
  } catch (err) {
    yield put(groupManagementActions.getAllGroupsFailure(err))
  }
}

function* getGroupRequest(action) {
  try {
    const response = yield call(getGroup, action.payload)
    yield put(groupManagementActions.getGroupSuccess(response.data))
  } catch (err) {
    yield put(groupManagementActions.getGroupFailure(err))
  }
}

function* getModuleRequest(action) {
  try {
    const response = yield call(getModule, action.payload)
    yield put(groupManagementActions.getModuleSuccess(response.data))
  } catch (err) {
    yield put(groupManagementActions.getModuleFailure(err))
  }
}

function* updateModuleStatusRequest(action) {
  try {
    const response = yield call(updateModuleStatus, action.payload)
    if (response.status === 200) {
      const custom = {
        group: action.payload.group,
        module: action.payload.module,
        modules: response.data.moduleData
      }
      yield put(groupManagementActions.updateModuleStatusSuccess(custom))
    } else {
      yield put(groupManagementActions.updateModuleStatusFailure(err))
    }
  } catch (err) {
    yield put(groupManagementActions.updateModuleStatusFailure(err))
  }
}

function* getFeatureRequest(action) {
  try {
    const response = yield call(getFeature, action.payload)
    yield put(groupManagementActions.getFeatureSuccess(response.data))
  } catch (err) {
    yield put(groupManagementActions.getFeatureFailure(err))
  }
}

function* updateFeatureStatusRequest(action) {
  try {
    const response = yield call(updateFeatureStatus, action.payload)

    if (response.status === 200) {
      const custom = {
        group: action.payload.group,
        module: action.payload.module,
        modules: response.data.moduleData
      }
      yield put(groupManagementActions.updateFeatureStatusSuccess(custom))
    } else {
      yield put(groupManagementActions.updateFeatureStatusFailure(err))
    }
  } catch (err) {
    yield put(groupManagementActions.updateFeatureStatusFailure(err))
  }
}

function* getGroupsNameRequest() {
  try {
    const response = yield call(listOfGroups)
    yield put(groupManagementActions.getAllGroupsNameSuccess(response.data))
  } catch (err) {
    yield put(groupManagementActions.getAllGroupsNameFailure(err))
  }
}

// function* createUserRequest(action) {
//   try {
//     const response = yield call(createEmployee, action.payload)
//     yield put(groupManagementActions.createUserSuccess(response.data))
//   } catch (err) {
//     yield put(groupManagementActions.createUserFailure(err))
//   }
// }

export default function* groupManagementSaga() {
  yield all([
    takeEvery(
      GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_REQUEST,
      getAllGroupsRequest
    ),
    takeEvery(GROUP_MANAGEMENT_TYPES.GET_GROUP_REQUEST, getGroupRequest),
    takeEvery(GROUP_MANAGEMENT_TYPES.GET_MODULE_REQUEST, getModuleRequest),
    takeEvery(
      GROUP_MANAGEMENT_TYPES.UPDATE_MODULE_STATUS_REQUEST,
      updateModuleStatusRequest
    ),
    takeEvery(GROUP_MANAGEMENT_TYPES.GET_FEATURE_REQUEST, getFeatureRequest),
    takeEvery(
      GROUP_MANAGEMENT_TYPES.UPDATE_FEATURE_STATUS_REQUEST,
      updateFeatureStatusRequest
    ),
    takeEvery(
      GROUP_MANAGEMENT_TYPES.GET_ALL_GROUPS_NAME_REQUEST,
      getGroupsNameRequest
    )
    // takeEvery(GROUP_MANAGEMENT_TYPES.CREATE_USER_REQUEST, createUserRequest)
  ])
}
