import * as oAuthKeysAction from "./actions"

import { all, call, put, takeEvery } from "redux-saga/effects"
import {
  changeOAuthKeyStatus,
  createOAuthKey,
  deleteAuthorizedUris,
  getAllOAuthKeys,
  getAuthorizedUris,
  postAuthorizedUris,
  updateAuthorizedUris
} from "@src/services/apis"

import { OAUTH_KEYS_TYPES } from "./types"

function* getAllOAuthKeysRequest(action) {
  try {
    const response = yield call(getAllOAuthKeys, action.payload)
    yield put(oAuthKeysAction.getAllOAuthKeysSuccess(response))
  } catch (err) {
    yield put(oAuthKeysAction.getAllOAuthKeysFailure(err))
  }
}

function* createOAuthKeysRequest(action) {
  try {
    const response = yield call(createOAuthKey, action.payload)
    yield put(oAuthKeysAction.createOAuthKeysSuccess(response.data))
  } catch (err) {
    yield put(oAuthKeysAction.createOAuthKeysFailure(err))
  }
}

function* changeOAuthKeyStatusRequest(action) {
  try {
    const response = yield call(changeOAuthKeyStatus, action.payload)
    yield put(oAuthKeysAction.changeOAuthKeyStatusSuccess(response.data))
  } catch (err) {
    yield put(oAuthKeysAction.changeOAuthKeyStatusFailure(err))
  }
}

function* getAuthorizedUrisRequest(action) {
  try {
    const response = yield call(getAuthorizedUris)
    yield put(oAuthKeysAction.getAuthorizedUrisSuccess(response.data))
  } catch (err) {
    yield put(oAuthKeysAction.getAuthorizedUrisFailure(err))
  }
}
function* postAuthorizedUrisRequest(action) {
  try {
    const response = yield call(postAuthorizedUris, action.payload)
    if (response.status === 200 && response.data === "Authorized uri added") {
      yield put(oAuthKeysAction.postAuthorizedUrisSuccess(action.payload))
    } else {
      yield put(oAuthKeysAction.postAuthorizedUrisFailure(response))
    }
  } catch (err) {
    yield put(oAuthKeysAction.postAuthorizedUrisFailure(err))
  }
}

function* deleteAuthorizedUrisRequest(action) {
  try {
    const response = yield call(deleteAuthorizedUris, action.payload.uri)
    yield put(oAuthKeysAction.deleteAuthorizedUrisSuccess(response.data))
  } catch (err) {
    yield put(oAuthKeysAction.deleteAuthorizedUrisFailure(err))
  }
}

function* updateAuthorizedUrisRequest(action) {
  try {
    // const response = yield call(updateAuthorizedUris, action.payload)
    yield put(oAuthKeysAction.updateAuthorizedUrisSuccess(response.data))
  } catch (err) {
    yield put(oAuthKeysAction.updateAuthorizedUrisFailure(err))
  }
}

export default function* oAuthKeysSaga() {
  yield all([
    takeEvery(
      OAUTH_KEYS_TYPES.GET_ALL_OAUTH_KEYS_REQUEST,
      getAllOAuthKeysRequest
    ),
    takeEvery(
      OAUTH_KEYS_TYPES.CREATE_OAUTH_KEYS_REQUEST,
      createOAuthKeysRequest
    ),
    takeEvery(
      OAUTH_KEYS_TYPES.CHANGE_OAUTH_KEY_STATUS_REQUEST,
      changeOAuthKeyStatusRequest
    ),
    takeEvery(
      OAUTH_KEYS_TYPES.GET_AUTHORIZE_URIS_REQUEST,
      getAuthorizedUrisRequest
    ),
    takeEvery(
      OAUTH_KEYS_TYPES.POST_AUTHORIZE_URIS_REQUEST,
      postAuthorizedUrisRequest
    ),
    takeEvery(
      OAUTH_KEYS_TYPES.UPDATE_AUTHORIZE_URI_REQUEST,
      updateAuthorizedUrisRequest
    ),
    takeEvery(
      OAUTH_KEYS_TYPES.DELETE_AUTHORIZE_URI_REQUEST,
      deleteAuthorizedUrisRequest
    )
  ])
}
