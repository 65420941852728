import { USERS_TYPES } from "./types"
import { toast } from "react-hot-toast"

const initialState = {
  users: {},
  error: null,
  created: false,
  deleted: false,
  updated: false,
  createdUpdatedLoading: false,
  deletedLoading: false
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_TYPES.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        error: null,
        created: false,
        deleted: false,
        updated: false
      }

    case USERS_TYPES.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        createdUpdatedLoading: false,
        deletedLoading: false
      }
    case USERS_TYPES.GET_ALL_USERS_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case USERS_TYPES.CREATE_USER_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        error: null,
        created: false
      }

    case USERS_TYPES.CREATE_USER_SUCCESS:
      localStorage.setItem(
        "currCreateUserLimit",
        action.payload?.current
          ? action.payload?.current
          : localStorage.getItem("currCreateUserLimit") > 0
          ? localStorage.getItem("currCreateUserLimit") - 1
          : 0
      )

      return {
        ...state,
        createdUpdatedLoading: false,
        created: true
      }
    case USERS_TYPES.CREATE_USER_FAIL:
      // if action.payload is string, it means that the email already exists
      if (typeof action.payload === "string") {
        toast.error(action.payload)
      }

      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false,
        created: false
      }

    case USERS_TYPES.UPDATE_USER_REQUEST:
      return {
        ...state,
        createdUpdatedLoading: true,
        error: null,
        updated: false
      }

    case USERS_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updated: true,
        createdUpdatedLoading: false
      }

    case USERS_TYPES.UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        createdUpdatedLoading: false,
        updated: false
      }

    case USERS_TYPES.DELETE_USER_REQUEST:
      return {
        ...state,
        deletedLoading: true,
        error: null,
        deleted: false
      }

    case USERS_TYPES.DELETE_USER_SUCCESS:
      localStorage.setItem(
        "currCreateUserLimit",
        action.payload?.current
          ? action.payload?.current
          : localStorage.getItem("currCreateUserLimit") <
            localStorage.getItem("currCreateUserLimit")
          ? localStorage.getItem("currCreateUserLimit") + 1
          : 0
      )

      return {
        ...state,
        deleted: true,
        deletedLoading: false
      }

    case USERS_TYPES.DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        deletedLoading: false,
        deleted: false
      }

    default:
      return state
  }
}

export default usersReducer
